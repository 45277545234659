import module from '@finances/partidas/module'

export class FinancesPartidasService {
  constructor($filter, $http) {
    this.$filterDate = $filter('date')
    this.$http = $http
    this.schema = {
      fecha: 'date',
      gasto: {
        factura: 'number',
      },
      importe: 'number',
      id_empresa: 'number',
      id_leyenda: 'number',
      id_tipo_beneficiario: 'number',
      numero_cheque: 'number',
      autorizacion: {
        estatus: 'boolean',
      },
      estatus: {
        fecha: 'date',
      },
    }
  }

  get(idEmpresa, idCuenta, estatus, fechaCorte) {
    return this.$http.get('api/partidas', {
      params: {
        id_empresa: idEmpresa,
        id_cuenta: idCuenta,
        estatus,
        fecha_corte: fechaCorte && this.$filterDate(fechaCorte, 'yyyy-MM-dd'),
      },
      schema: this.schema,
    })
  }

  post(idEmpresa, idCuenta, data) {
    return this.$http.post('api/partidas', data, {
      params: {
        id_empresa: idEmpresa,
        id_cuenta: idCuenta,
      },
      schema: this.schema,
    })
  }
}

module.service('FinancesPartidasService', FinancesPartidasService)
