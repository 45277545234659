import module from '@finances/partidas/module'

export class FinancesPartidasProteccionChequesLayoutService {
  constructor($http) {
    this.$http = $http
  }

  get({ id }) {
    return this.$http.get(`api/partidas/proteccion-cheques/layout/${id}`, {
      download: true,
    })
  }

  delete({ id }) {
    return this.$http.delete(`api/partidas/proteccion-cheques/layout/${id}`)
  }
}

module.service('FinancesPartidasProteccionChequesLayoutService', FinancesPartidasProteccionChequesLayoutService)
