import module from '@finances/empresas/module'
import template from './template.html'

export class FinancesPosicionbancariaEstadoComponent {
  constructor($filter, $q, $state, FinancesCatalogosMonedasService, FinancesCatalogosCategoriasCuentaService) {
    this.$state = $state
    this.dateFormat = $filter('date')
    this.catalogos = {}
    this.promise = $q.all([
      FinancesCatalogosMonedasService.get().then((response) => {
        this.catalogos.agrupaciones = [
          { id: 'm', descripcion: 'Movimiento' },
          { id: 'g', descripcion: 'Gasto' },
        ]

        this.monedas = [{ id: 0, descripcion: 'Todas' }].concat(response.data)
      }),
      FinancesCatalogosCategoriasCuentaService.get().then((response) => {
        this.catalogos.categoriasCuenta = [{ id: 0, descripcion: 'Todas' }].concat(response.data)
      }),
    ])
  }

  $onChanges(changes) {
    if (changes.fechaCorte) this.fechaCorte = this.fechaCorte || new Date()
    if (changes.idCategoria) this.idCategoria = this.idCategoria || 0
    if (changes.idMoneda) this.idMoneda = this.idMoneda || 0
    if (changes.agrupacion) this.agrupacion = this.agrupacion || 'm'
  }

  actualizar(data) {
    this.saldo = data.reduce((total, item) => total + item.total * (item.id_naturaleza === 'I' ? 1 : -1), 0)
  }

  buscar() {
    if (!this.fechaCorte) return

    this.saldo = undefined

    this.$state.go(
      this.$state.current,
      {
        fecha_corte: this.dateFormat(this.fechaCorte, 'yyyy-MM-dd'),
        id_categoria: this.idCategoria,
        id_moneda: this.idMoneda ? this.idMoneda : undefined,
        agrupacion: this.agrupacion,
      },
      { location: 'replace' },
    )
  }
}

module.component('financesPosicionbancariaEstado', {
  controller: FinancesPosicionbancariaEstadoComponent,
  template,
  bindings: {
    fechaCorte: '<',
    idCategoria: '<',
    idMoneda: '<',
    agrupacion: '<',
  },
})
