import module from '@finances/empresa/module'

module.config(($stateProvider) => {
  $stateProvider.state('empresas.agregar', {
    url: '/agregar',
    data: { title: 'Agregar' },
    views: {
      '@': 'financesEmpresasAgregar',
    },
  })
})
