import module from '@finances/divisas/module'

export class FinancesDivisasFechaService {
  constructor($filter, $http) {
    this.$filterDate = $filter('date')
    this.$http = $http
    this.schema = {
      fecha: 'date',
      tipo_cambio: 'number',
    }
  }

  get(idMoneda, fecha) {
    return this.$http.get(`api/divisas/${idMoneda}/${this.$filterDate(fecha, 'yyyy-MM-dd')}`, {
      schema: this.schema,
    })
  }

  put(idMoneda, fecha, data) {
    return this.$http.put(`api/divisas/${idMoneda}/${this.$filterDate(fecha, 'yyyy-MM-dd')}`, data, {
      schema: this.schema,
    })
  }
}

module.service('FinancesDivisasFechaService', FinancesDivisasFechaService)
