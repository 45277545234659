import angular from 'angular'
import FinancesCatalogos from './catalogos/index.js'
import FinancesClasificarmovimientos from './clasificarmovimientos/index.js'
import FinancesConfiguracion from './configuracion/index.js'
import FinancesDashboard from './dashboard/index.js'
import FinancesDivisas from './divisas/index.js'
import FinancesEmpresa from './empresa/index.js'
import FinancesEmpresas from './empresas/index.js'
import FinancesImportarmovimientos from './importarmovimientos/index.js'
import FinancesImportarpartidas from './importarpartidas/index.js'
import FinancesPartida from './partida/index.js'
import FinancesPartidas from './partidas/index.js'
import FinancesPosicionbancaria from './posicionbancaria/index.js'
import KdWorker from '@kendu/worker/index.js'

export default angular.module('finances', [
  FinancesCatalogos,
  FinancesClasificarmovimientos,
  FinancesConfiguracion,
  FinancesDashboard,
  FinancesDivisas,
  FinancesEmpresa,
  FinancesEmpresas,
  FinancesImportarmovimientos,
  FinancesImportarpartidas,
  FinancesPartida,
  FinancesPartidas,
  FinancesPosicionbancaria,
  KdWorker,
])
