import module from '@finances/empresas/module'
import template from './template.html'

export class FinancesPosicionbancariaSaldosListaItemComponent {
  constructor() {
    this.data = undefined
    this.expandido = false
  }

  actualizar(data) {
    Object.assign(this.data, data)

    this.onChange({ $event: { data: this.data } })
  }

  onChange() {}
}

module.component('financesPosicionbancariaSaldosListaItem', {
  controller: FinancesPosicionbancariaSaldosListaItemComponent,
  template,
  bindings: {
    data: '<',
    fechaCorte: '<',
    onChange: '&',
  },
})
