import module from '@finances/empresa/module'

export const FinancesEmpresaCuentaSchema = {
  categoria: {
    id: 'number',
  },
  empresa: {
    id: 'number',
  },
  moneda: {
    id: 'number',
  },
  tipo_cuenta: {
    id: 'number',
  },
}

export class FinancesEmpresaCuentaService {
  constructor($http) {
    this.$http = $http
  }

  get(idEmpresa, idCuenta) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuenta/${idCuenta}`, {
      schema: FinancesEmpresaCuentaSchema,
    })
  }
}

module.service('FinancesEmpresaCuentaService', FinancesEmpresaCuentaService)
