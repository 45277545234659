import module from '@finances/partidas/module'

module.config(($stateProvider) => {
  $stateProvider.state('partidas.proteccionCheques.exportar', {
    url: '',
    data: {
      title: 'Exportar',
    },
    views: {
      '@': { component: 'financesPartidasProteccionChequesExportar' },
    },
  })
})
