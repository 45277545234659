import module from './module.js'
/* Components */
import './components/configuracion/component.js'
import './components/clasificarmovimientos/component.js'
import './components/divisas/component.js'
import './components/empresas/component.js'
import './components/importarmovimientos/component.js'
import './components/importarpartidas/component.js'
import './components/partidas/component.js'
import './components/posicionbancaria/component.js'

export default module.name
