import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresaCuentaInformacionComponent {
  constructor($state, FinancesEmpresaCuentaInformacionService) {
    this.FinancesEmpresaCuentaInformacionService = FinancesEmpresaCuentaInformacionService
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.data = undefined
  }

  $onInit() {
    this.promise = this.FinancesEmpresaCuentaInformacionService.get(this.idEmpresa, this.idCuenta).then((response) => {
      this.data = response.data
    })
  }
}

module.component('financesEmpresaCuentaInformacion', {
  controller: FinancesEmpresaCuentaInformacionComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
  },
})
