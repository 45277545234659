import module from '@finances/configuracion/module'

module.config(($stateProvider) => {
  $stateProvider.state('configuracion.categoriascuentas', {
    url: '/categorias_cuentas',
    data: { title: 'Categorías de cuentas' },
    views: {
      '@': 'financesConfiguracionCategoriascuentas',
    },
  })
})
