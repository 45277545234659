import module from '@finances/empresa/module'

export const financesEmpresaCuentaPrestamosPredeterminadoSchema = {
  fecha: 'date',
  id_moneda: 'number',
  interes_porcentaje: 'number',
}

/**
 * @ngdoc service
 * @name FinancesEmpresaCuentaPrestamosPredeterminadoService
 */
export class FinancesEmpresaCuentaPrestamosPredeterminadoService {
  constructor($http) {
    this.$http = $http
  }

  /**
   * @memberof FinancesEmpresaCuentaPrestamosPredeterminadoService
   */
  get(idEmpresa, idCuenta) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/prestamos/predeterminado`, {
      schema: financesEmpresaCuentaPrestamosPredeterminadoSchema,
    })
  }
}

module.service(
  'FinancesEmpresaCuentaPrestamosPredeterminadoService',
  FinancesEmpresaCuentaPrestamosPredeterminadoService,
)
