import module from '@finances/clasificarmovimientos/module'
import template from './template.html'
import { isDate } from 'angular'

export class FinancesClasificarmovimientosComponent {
  constructor(
    $q,
    $state,
    $window,
    FinancesClasificarmovimientosService,
    FinancesCatalogosTiposMovimientoService,
    FinancesCatalogosCategoriasCuentaService,
    FinancesCatalogosMonedasService,
  ) {
    this.$q = $q
    this.$state = $state
    this.$window = $window
    this.catalogos = {}
    this.fecha = undefined
    this.idCategoria = undefined
    this.idMoneda = undefined
    this.FinancesClasificarmovimientosService = FinancesClasificarmovimientosService
    this.FinancesCatalogosTiposMovimientoService = FinancesCatalogosTiposMovimientoService
    this.FinancesCatalogosCategoriasCuentaService = FinancesCatalogosCategoriasCuentaService
    this.FinancesCatalogosMonedasService = FinancesCatalogosMonedasService
  }

  $onChanges(changes) {
    if (changes.fecha && !isDate(this.fecha)) this.fecha = new Date()
    if (changes.idCategoria) this.idCategoria = this.idCategoria || 0
    if (changes.idMoneda) this.idMoneda = this.idMoneda || 0
  }

  $onInit() {
    this.promise = this.$q.all([
      this.FinancesClasificarmovimientosService.get(),
      this.FinancesCatalogosTiposMovimientoService.get().then((response) => (this.tiposMovimiento = response.data)),
      this.FinancesCatalogosCategoriasCuentaService.get().then(
        (response) => (this.catalogos.categoriasCuenta = [{ id: 0, descripcion: 'Todas' }].concat(response.data)),
      ),
      this.FinancesCatalogosMonedasService.get().then(
        (response) => (this.catalogos.monedas = [{ id: 0, descripcion: 'Todas' }].concat(response.data)),
      ),
    ])
  }

  actualizar(data) {
    this.data = data
  }

  buscar() {
    this.$state.go(
      this.$state.current,
      {
        fecha: this.fecha.toJSON().slice(0, 10),
        id_categoria: this.idCategoria,
        id_moneda: this.idMoneda,
      },
      { location: 'replace' },
    )
  }

  fechaModificar(intervalo) {
    if (!this.uiCanExit()) return

    this.fecha = new Date(this.fecha.getFullYear(), this.fecha.getMonth(), this.fecha.getDate() + intervalo)
    this.buscar()
  }

  uiCanExit() {
    return (
      !this.data ||
      !this.data.modificados ||
      this.$window.confirm('Tiene partidas no guardadas, ¿desea salir de este módulo?')
    )
  }
}

module.component('financesClasificarmovimientos', {
  controller: FinancesClasificarmovimientosComponent,
  template,
  bindings: {
    fecha: '<',
    idCategoria: '<',
    idMoneda: '<',
  },
})
