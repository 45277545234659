import module from './module.js'
/* Routes */
import './routes/importarmovimientos.js'
import './routes/importarmovimientos/historial.js'
/* Components */
import './components/component.js'
import './components/guardar/component.js'
import './components/historial/component.js'
import './components/historial/detallado/component.js'
import './components/historial/eliminar/component.js'
import './components/historial/lista/component.js'
import './components/historial/lista/item/component.js'
import './components/invalidos/component.js'
import './components/lista/component.js'
import './components/lista/item/component.js'
import './components/origen/component.js'
import './components/origen/bancomer/component.js'
import './components/origen/concord/component.js'
import './components/origen/excel/component.js'
/* Services */
import './services/Importarmovimientos.js'
import './services/Importarmovimientos/Cuentas.js'
import './services/Importarmovimientos/Decodificar.js'
import './services/Importarmovimientos/Historial.js'
import './services/Importarmovimientos/Historial/Lote.js'

export default module.name
