import module from '@finances/importarmovimientos/module'

module.config(($stateProvider) => {
  $stateProvider.state('importarmovimientos', {
    url: '/importar_movimientos',
    component: 'financesImportarmovimientos',
    data: {
      title: 'Importar movimientos',
    },
  })
})
