import module from '@finances/partida/module'
import template from './template.html'

export class FinancesPartidaInformacionEditarComponent {
  constructor(
    $q,
    FinancesCatalogosFormasPagoPartidasService,
    FinancesCatalogosLeyendasService,
    FinancesPartidaInformacionService,
    FinancesCatalogosTiposMovimientoService,
  ) {
    this.$q = $q
    this.FinancesCatalogosFormasPagoPartidasService = FinancesCatalogosFormasPagoPartidasService
    this.FinancesCatalogosLeyendasService = FinancesCatalogosLeyendasService
    this.FinancesPartidaInformacionService = FinancesPartidaInformacionService
    this.FinancesCatalogosTiposMovimientoService = FinancesCatalogosTiposMovimientoService
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idPartida = undefined
    this.procesando = false
  }

  $onInit() {
    this.promise = this.$q.all([
      this.FinancesCatalogosFormasPagoPartidasService.get().then((response) => (this.formasPago = response.data)),
      this.FinancesCatalogosLeyendasService.get().then((response) => (this.leyendas = response.data)),
      this.FinancesCatalogosTiposMovimientoService.get().then((response) => (this.tiposMovimiento = response.data)),
      this.FinancesPartidaInformacionService.get(this.idEmpresa, this.idCuenta, this.idPartida).then(
        (response) => (this.data = response.data),
      ),
    ])
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.FinancesPartidaInformacionService.put(this.idEmpresa, this.idCuenta, this.idPartida, this.data)
      .then((response) => this.cerrar(response.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar(data) {
    this.onClose({ $event: { data } })
  }

  seleccionarFormaPago() {
    if (this.data.id_forma_pago !== '02') {
      this.data.cheque = null
      this.data.id_leyenda = 0
    }
  }

  onClose() {}
}

module.component('financesPartidaInformacionEditar', {
  controller: FinancesPartidaInformacionEditarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPartida: '<',
    onChange: '&',
    onClose: '&',
  },
})
