import module from '@finances/configuracion/module'
import template from './template.html'
import '@finances/configuracion/services/Configuracion/Bancos/Banco'

export class FinancesConfiguracionBancosEliminarComponent {
  constructor(FinancesConfiguracionBancosBancoService) {
    this.bancoService = FinancesConfiguracionBancosBancoService
    this.modal = undefined
    this.error = null
    this.completado = false
    this.procesando = false
    this.eliminado = false
    this.data = undefined
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.bancoService
      .delete(this.data.id)
      .then((response) => {
        this.eliminado = response.data
        this.completado = true
      })
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    this.modal.close(this.eliminado)
  }
}

module.component('financesConfiguracionBancosEliminar', {
  controller: FinancesConfiguracionBancosEliminarComponent,
  template,
  bindings: {
    data: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
