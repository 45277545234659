import module from '@finances/catalogos/module'

export class FinancesCatalogosEstatusPartidasService {
  constructor($http) {
    this.$http = $http
    this.schema = {}
  }

  get() {
    return this.$http.get('api/catalogos/estatus_partidas', {
      schema: this.schema,
      cache: true,
    })
  }
}

module.service('FinancesCatalogosEstatusPartidasService', FinancesCatalogosEstatusPartidasService)
