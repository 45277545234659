import module from '@finances/empresa/module'

export class FinancesEmpresaService {
  constructor($http) {
    this.$http = $http
  }

  get(idEmpresa) {
    return this.$http.get(`api/empresa/${idEmpresa}`)
  }
}

module.service('FinancesEmpresaService', FinancesEmpresaService)
