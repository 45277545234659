import module from '@finances/partida/module'
import template from './template.html'

export class FinancesPartidaOrigenComponent {
  constructor($state) {
    this.$state = $state
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idPartida = undefined
    this.data = undefined
    this.onChange = undefined
    this.editarDialogo = undefined
  }

  editar() {
    this.editarDialogo.open().then((data) => {
      if (data && (data.id_empresa !== this.idEmpresa || data.id_cuenta !== this.idCuenta)) {
        const stateName = 'partidas.editar'

        this.$state.go(
          stateName,
          {
            id_empresa: data.id_empresa,
            id_cuenta: data.id_cuenta,
            id_partida: data.id_partida,
          },
          { location: this.$state.current.name === stateName ? 'replace' : true },
        )
      }
    })
  }
}

module.component('financesPartidaOrigen', {
  controller: FinancesPartidaOrigenComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPartida: '<',
    data: '<',
    onChange: '&',
  },
})
