import angular from 'angular'
import template from './template.html'

export class FinancesEmpresaCuentasComponent {
  constructor($state, FinancesEmpresaCuentasService) {
    this.$state = $state
    this.FinancesEmpresasImportarmovimientosCuentasService = FinancesEmpresaCuentasService
    this.idEmpresa = undefined
  }

  $onInit() {
    this.promise = this.FinancesEmpresasImportarmovimientosCuentasService.get(this.idEmpresa).then(
      (response) => (this.data = response.data),
    )
  }

  agregar() {
    this.$state.go('empresa.cuentas.agregar', { id_empresa: this.idEmpresa })
  }
}

angular.module('finances.empresa').component('financesEmpresaCuentas', {
  controller: FinancesEmpresaCuentasComponent,
  template,
  bindings: {
    idEmpresa: '<',
  },
})
