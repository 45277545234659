import module from '@finances/clasificarmovimientos/module'
import template from './template.html'

export class FinancesClasificarmovimientosCuentaComponent {
  constructor() {
    this.cuenta = undefined
    this.movimientos = undefined
  }

  actualizar() {
    this.onChange({ $event: { data: this.cuenta } })
  }

  onChange() {}
}

module.component('financesClasificarmovimientosCuenta', {
  controller: FinancesClasificarmovimientosCuentaComponent,
  template,
  bindings: {
    cuenta: '<',
    movimientos: '<',
    onChange: '&',
  },
})
