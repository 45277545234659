import module from '@finances/configuracion/module'

export const financesConfiguracionMonedasSchema = {
  en_uso: 'boolean',
}

export class FinancesConfiguracionMonedasService {
  constructor($http) {
    this.$http = $http
  }

  get() {
    return this.$http.get('api/configuracion/monedas', {
      schema: financesConfiguracionMonedasSchema,
    })
  }

  post(data) {
    return this.$http.post('api/configuracion/monedas', data, {
      schema: financesConfiguracionMonedasSchema,
    })
  }
}

module.service('FinancesConfiguracionMonedasService', FinancesConfiguracionMonedasService)
