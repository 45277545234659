import module from '@finances/partidas/module'
import template from './template.html'
import './style.scss'

export class FinancesPartidasProteccionChequesExportarItemComponent {
  data = undefined

  editando = false

  eliminado = false

  actualizar(data) {
    this.data.beneficiario = data.beneficiario.nombre
    this.data.cheque = data.informacion.cheque
    this.data.fecha = data.informacion.fecha
    this.data.importe = data.informacion.importe
    this.data.tipo_movimiento = data.informacion.tipo_movimiento
  }

  editar(editando = !this.editando) {
    this.editando = editando
  }

  eliminar(data) {
    this.eliminado = true
    this.editando = false
    this.onDelete({ $event: { data } })
  }

  seleccionar(seleccionado = !this.data.seleccionado) {
    this.data.seleccionado = seleccionado
    this.onSelect({ $event: { data: this.data, seleccionado } })
  }

  proteger() {
    this.data.protegido = !this.data.protegido
  }

  onDelete() {}

  onSelect() {}
}

module.component('financesPartidasProteccionChequesExportarItem', {
  controller: FinancesPartidasProteccionChequesExportarItemComponent,
  template,
  bindings: {
    data: '<',
    onSelect: '&',
    onDelete: '&',
  },
})
