import module from '@finances/empresa/module.js'

module.config(($stateProvider) => {
  $stateProvider.state('empresa.cuenta.movimientos.agregar', {
    url: '',
    data: { title: 'Agregar' },
    params: { naturaleza: 'C' },
    views: {
      '@empresa.cuenta': 'financesEmpresaCuentaMovimientosAgregar',
    },
    resolve: {
      naturaleza: ($transition$) => $transition$.params().naturaleza,
    },
  })
})
