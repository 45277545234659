import module from '@finances/configuracion/module'
import template from './template.html'
import './agregar/component.js'
import './editar/component.js'
import './eliminar/component.js'
import '@finances/configuracion/services/Configuracion/Firmantes'

export class FinancesConfiguracionFirmantesComponent {
  constructor(FinancesConfiguracionFirmantesService) {
    this.firmantesService = FinancesConfiguracionFirmantesService
    this.seleccionado = null
    this.dialogoEditar = undefined
    this.dialogoEliminar = undefined
    this.dialogoAgregar = undefined
  }

  $onInit() {
    this.promise = this.firmantesService.get().then((response) => {
      this.data = response.data
    })
  }

  agregar() {
    this.dialogoAgregar.open().then((data) => {
      if (data) this.data = this.data.concat([data])
    })
  }

  editar(firmante) {
    this.seleccionado = firmante

    this.dialogoEditar.open().then((data) => {
      if (data) Object.assign(this.seleccionado, data)

      this.seleccionado = null
    })
  }

  eliminar(firmante) {
    this.seleccionado = firmante

    this.dialogoEliminar.open().then((eliminar) => {
      if (eliminar) this.data = this.data.filter((item) => item !== firmante)

      this.seleccionado = null
    })
  }
}

module.component('financesConfiguracionFirmantes', {
  controller: FinancesConfiguracionFirmantesComponent,
  template,
  bindings: {},
})
