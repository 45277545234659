import module from '@finances/importarpartidas/module'
import { isNumber, isString } from 'angular'

class PartidasModel {
  constructor() {
    this.cheque = null
    this.id_cuenta = null // Requerido
    this.numero_cuenta = null // Requerido
    this.fecha = null // Requerido
    this.importe = null // Requerido
    this.id_tipo_movimiento = null // Requerido
    this.id_leyenda = 0
    this.status = 't'
    this.comentarios = ''
    this.id_forma_pago = '01'

    // Beneficiario
    this.id_tipo_beneficiario = 0
    this.id_beneficiario_empresa = ''
    this.id_beneficiario_empleado = ''
    this.beneficiario = null // Requerido

    // Gasto
    this.gasto = {}
  }
}

export class FinancesImportarpartidasConfiguracionExcelService {
  constructor($q) {
    this.$q = $q
  }

  get() {
    // TODO: Implementar clasificación de gasto
    // return this.$q.all({ categoriasGasto: this.obtenerCategoriasGasto() }).then(({ categoriasGasto }) => {
    return this.$q.all([]).then(() => {
      return {
        modelo: PartidasModel,
        encabezados: [
          {
            id: 'numero_cuenta',
            name: 'Cuenta bancaria',
            group: 'Requerido',
            parser: (value) => (value !== '' ? value.trim() : null),
            processor: this.processorFunction('numero_cuenta'),
            match: this.matchFunction(['CUENTA BANCARIA']),
          },
          {
            id: 'fecha',
            name: 'Fecha',
            group: 'Requerido',
            parser: (value) => new Date(value.trim()) || null,
            processor: this.processorFunction('fecha'),
            match: (value) => /fecha/i.test(value),
          },
          {
            id: 'importe',
            name: 'Importe',
            group: 'Requerido',
            parser: (value) => {
              value = this.parseNumber(value, 2)
              return (value > 0 && value) || null
            },
            processor: this.processorFunction('importe'),
            match: (value) => /importe/i.test(value),
          },
          {
            id: 'beneficiario',
            name: 'Beneficiario',
            group: 'Requerido',
            parser: (value) => (value !== '' ? value.trim() : null),
            processor: this.processorFunction('beneficiario'),
            match: (value) => /beneficiario/i.test(value),
          },
          {
            id: 'numero_cheque',
            name: 'Nº Cheque',
            group: 'Requerido',
            parser: (value) => (value !== '' ? value.trim() : null),
            processor: (object, value) => {
              object.cheque = value
              object.id_forma_pago = '02'

              return object
            },
            match: (value) => /cheque|numero( de)? cheque/i.test(value),
          },
          {
            id: 'tipo_movimiento',
            name: 'Tipo de movimiento',
            group: 'Opcional',
            parser: (value) => (value !== '' ? value.trim() : null),
            processor: (object, value) => {
              // TODO: Implementar clasificación de gasto
              // if (categoriasGasto[value]) {
              //   object.gasto = object.gasto || new PartidasModelGasto()

              //   object.gasto.id_categoria = categoriasGasto[value]
              //   object.gasto.tipo_movimiento = value
              // }
              object.tipo_movimiento = value

              return object
            },
            match: (value) => /concepto/i.test(value),
          },
          {
            id: 'estatus',
            name: 'Estatus',
            group: 'Opcional',
            parser: (value) => {
              value = value.toLowerCase()
              return (['t'].indexOf(value) >= 0 && value) || 't'
            },
            processor: this.processorFunction('estatus'),
            match: (value) => /status/i.test(value),
          },
          {
            id: 'comentarios',
            name: 'Comentarios',
            group: 'Opcional',
            parser: (value) => value.trim(),
            processor: this.processorFunction('comentarios'),
            match: (value) => /comentario|nota|proveedor/i.test(value),
          },
        ],
      }
    })
  }

  parseNumber(numero, redondeo) {
    return isString(numero) || isNumber(numero)
      ? Number(parseFloat(numero.replace(/[^-\d.]/g, '')).toFixed(redondeo))
      : Number.NaN
  }

  matchFunction(val) {
    return (value) => val.indexOf(value.toUpperCase()) >= 0
  }

  processorFunction(val) {
    return (object, value) => {
      object[val] = value

      return object
    }
  }

  // TODO: Implementar clasificación de gasto
  // obtenerCategoriasGasto() {
  //   return this.FinancesCatalogosCategoriasGastoService.get().then((response) => {
  //     return response.data.reduce((carry, next) => {
  //       carry[next.descripcion] = next.id

  //       return carry
  //     }, {})
  //   })
  // }
}

module.service('FinancesImportarpartidasConfiguracionExcelService', FinancesImportarpartidasConfiguracionExcelService)
