import module from '@finances/partida/module'

export const financesPartidaInformacionSchema = {
  cheque: 'number',
  estatus_fecha: 'date',
  fecha: 'date',
  fecha_cobro: 'date',
  fecha_cancelacion: 'date',
  fecha_retencion: 'date',
  id_leyenda: 'number',
  id_tipo_movimiento: 'number',
  importe: 'number',
}

export class FinancesPartidaInformacionService {
  constructor($http) {
    this.$http = $http
  }

  get(idEmpresa, idCuenta, idPartida) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/partida/${idPartida}/informacion`, {
      schema: financesPartidaInformacionSchema,
    })
  }

  put(idEmpresa, idCuenta, idPartida, data) {
    return this.$http.put(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/partida/${idPartida}/informacion`, data, {
      schema: financesPartidaInformacionSchema,
    })
  }
}

module.service('FinancesPartidaInformacionService', FinancesPartidaInformacionService)
