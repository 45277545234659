import module from '@finances/catalogos/module'

export class FinancesCatalogosFirmantesService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      id: 'number',
    }
  }

  get() {
    return this.$http.get('api/catalogos/firmantes', {
      schema: this.schema,
      cache: true,
    })
  }
}

module.service('FinancesCatalogosFirmantesService', FinancesCatalogosFirmantesService)
