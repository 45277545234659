import module from '@finances/empresas/module'
import template from './template.html'

export class FinancesPosicionbancariaSaldosEmpresaCuentaPrestamosComponent {
  constructor() {
    this.data = undefined
  }

  $onInit() {
    this.total = this.data.reduce((total, item) => total + item.importe, 0)
  }
}

module.component('financesPosicionbancariaSaldosEmpresaCuentaPrestamos', {
  controller: FinancesPosicionbancariaSaldosEmpresaCuentaPrestamosComponent,
  template,
  bindings: {
    data: '<',
  },
})
