import module from './module.js'
/* Routes */
import './routes/partidas.js'
import './routes/partidas/agregar.js'
import './routes/partidas/editar.js'
import './routes/partidas/proteccionCheques.js'
import './routes/partidas/proteccionCheques/exportar.js'
import './routes/partidas/proteccionCheques/layouts.js'
/* Components */
import './components/component.js'
import './components/agregar/component.js'
import './components/agregar/beneficiario/component.js'
import './components/agregar/confirmar/component.js'
import './components/agregar/gasto/component.js'
import './components/agregar/informacion/component.js'
import './components/agregar/origen/component.js'
import './components/editar/component.js'
import './components/lista/component.js'
import './components/lista/item/component.js'
import './components/proteccionCheques/exportar/component.js'
import './components/proteccionCheques/exportar/agrupacion/component.js'
import './components/proteccionCheques/exportar/confirmar/component.js'
import './components/proteccionCheques/exportar/editar/component.js'
import './components/proteccionCheques/exportar/item/component.js'
import './components/proteccionCheques/exportar/lista/component.js'
import './components/proteccionCheques/layouts/component.js'
import './components/proteccionCheques/layouts/eliminar/component.js'
import './components/proteccionCheques/layouts/item/component.js'
/* Services */
import './services/Partidas.js'
import './services/Partidas/Predeterminado.js'
import './services/ProteccionCheques.js'
import './services/ProteccionCheques/Layout.js'
import './services/ProteccionCheques/Layouts.js'
import './services/ProteccionCheques/Pendientes.js'

export default module.name
