import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresaCuentaPrestamosComponent {
  constructor(FinancesEmpresaCuentaPrestamosService) {
    this.FinancesEmpresaCuentaPrestamosService = FinancesEmpresaCuentaPrestamosService
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.agregando = false
    this.config = {}
  }

  $onInit() {
    this.promise = this.FinancesEmpresaCuentaPrestamosService.get(
      this.idEmpresa,
      this.idCuenta,
      this.config.fecha_inicial,
      this.config.fecha_final,
    ).then((response) => (this.data = response.data))
  }
}

module.component('financesEmpresaCuentaPrestamos', {
  controller: FinancesEmpresaCuentaPrestamosComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
  },
})
