import module from '@finances/configuracion/module'

module.config(($stateProvider) => {
  $stateProvider.state('configuracion.clasificacionmovimientos', {
    url: '/clasificacion_movimientos',
    data: { title: 'Clasificación de movimientos' },
    views: {
      '@': 'financesConfiguracionClasificacionmovimientos',
    },
  })
})
