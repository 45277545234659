import module from '@finances/configuracion/module'
import template from './template.html'
import './style.scss'

export class FinancesConfiguracionClasificacionmovimientosReglaTestComponent {
  constructor() {
    this.catalogos = undefined
    this.data = undefined
    this.onChange = undefined
    this.onDelete = undefined
    this.validarNotas = {
      regexp: (valor) => {
        try {
          const regexp = new RegExp(valor)
          return !!regexp
        } catch (e) {
          return false
        }
      },
    }
  }

  eliminar() {
    this.onDelete()
  }

  seleccionar() {
    this.data.valor = undefined
  }
}

module.component('financesConfiguracionClasificacionmovimientosReglaTest', {
  controller: FinancesConfiguracionClasificacionmovimientosReglaTestComponent,
  template,
  bindings: {
    data: '<',
    catalogos: '<',
    onDelete: '&',
  },
})
