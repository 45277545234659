import module from '@finances/partida/module'

export class FinancesPartidaChequeService {
  constructor($http) {
    this.$http = $http
  }

  get(idEmpresa, idCuenta, idPartida) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/partida/${idPartida}/cheque`, {
      download: true,
    })
  }
}

module.service('FinancesPartidaChequeService', FinancesPartidaChequeService)
