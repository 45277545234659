import module from '@finances/importarpartidas/module'
import template from './template.html'

export class FinancesImportarpartidasListaComponent {}

module.component('financesImportarpartidasLista', {
  controller: FinancesImportarpartidasListaComponent,
  template,
  bindings: {
    data: '<',
  },
})
