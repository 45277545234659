import module from '@finances/empresas/module'
import template from './template.html'

export class FinancesPosicionbancariaSaldosListaTotalesComponent {
  constructor() {
    this.data = undefined
    this.subtotales = undefined
    this.tipoCambio = undefined
  }

  $onInit() {
    this.tipoCambio = this.data.tipo_cambio

    this.subtotales = this.data.cuentas.reduce(
      (subtotales, cuenta) => {
        subtotales[cuenta.id_moneda].saldo += cuenta.saldo
        subtotales[cuenta.id_moneda].transito += cuenta.transito
        subtotales[cuenta.id_moneda].retenido += cuenta.retenido
        subtotales[cuenta.id_moneda].disponible += cuenta.disponible
        subtotales[cuenta.id_moneda].modificado += cuenta.modificado

        return subtotales
      },
      {
        1: { saldo: 0, transito: 0, retenido: 0, disponible: 0, modificado: 0 },
        2: { saldo: 0, transito: 0, retenido: 0, disponible: 0, modificado: 0 },
      },
    )

    this.totales = {
      1: this.subtotales[1].modificado + this.subtotales[2].modificado * this.tipoCambio.valor,
      2: this.subtotales[2].modificado + this.subtotales[1].modificado / this.tipoCambio.valor,
    }
  }
}

module.component('financesPosicionbancariaSaldosListaTotales', {
  controller: FinancesPosicionbancariaSaldosListaTotalesComponent,
  template,
  bindings: {
    data: '<',
  },
})
