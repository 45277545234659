import module from '@finances/partida/module'
import template from './template.html'

export class FinancesPartidaOrigenEditarComponent {
  constructor(FinancesPartidaOrigenService, FinancesCatalogosEmpresasService, FinancesCatalogosCuentasService) {
    this.completado = false
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idPartida = undefined
    this.FinancesCatalogosEmpresasService = FinancesCatalogosEmpresasService
    this.FinancesCatalogosCuentasService = FinancesCatalogosCuentasService
    this.FinancesPartidaOrigenService = FinancesPartidaOrigenService
    this.error = null
    this.modal = undefined
    this.procesando = false
    this.catalogos = {}
  }

  $onChanges() {
    this.data = {
      id_empresa: this.idEmpresa,
      id_cuenta: this.idCuenta,
      id_partida: this.idPartida,
    }
  }

  $onInit() {
    this.obtenerEmpresas()
    this.obtenerCuentas()
  }

  actualizar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.FinancesPartidaOrigenService.put(this.idEmpresa, this.idCuenta, this.idPartida, this.data)
      .then((response) => {
        this.data = response.data
        this.completado = true
      })
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    this.modal.close(this.completado ? this.data : undefined)
  }

  seleccionarEmpresa() {
    this.data.id_cuenta = undefined
    this.data.id_moneda = undefined
    this.obtenerCuentas()
  }

  obtenerCuentas() {
    this.catalogos.cuentas = undefined

    if (!this.data.id_empresa) return

    this.FinancesCatalogosCuentasService.get(this.data.id_empresa).then(
      (response) => (this.catalogos.cuentas = response.data),
    )
  }

  obtenerEmpresas() {
    this.FinancesCatalogosEmpresasService.get().then((response) => (this.catalogos.empresas = response.data))
  }
}

module.component('financesPartidaOrigenEditar', {
  controller: FinancesPartidaOrigenEditarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPartida: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
