import module from '@finances/empresas/module'
import template from './template.html'
import orderBy from 'lodash-es/orderBy.js'

export class FinancesPosicionbancariaSaldosEmpresaCuentaPartidasComponent {
  $onInit() {
    this.data = orderBy(this.data, ['fecha', 'id_partida'], ['desc', 'asc'])

    this.totalizar()
  }

  actualizar() {
    this.onChange({ $event: { data: this.data } })
  }

  actualizarAutorizacion(item, data) {
    if (data) {
      item.autorizacion = data

      this.actualizar()
    }
  }

  actualizarEstatus(item, data) {
    if (data) {
      item.estatus = data

      this.totalizar()
      this.actualizar()
    }
  }

  totalizar() {
    this.total = this.data.reduce((total, item) => total + (item.estatus.id === 't' ? item.importe : 0), 0)
  }

  onChange() {}
}

module.component('financesPosicionbancariaSaldosEmpresaCuentaPartidas', {
  controller: FinancesPosicionbancariaSaldosEmpresaCuentaPartidasComponent,
  template,
  bindings: {
    data: '<',
    onChange: '&',
  },
})
