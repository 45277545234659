import module from '@finances/configuracion/module'
import template from './template.html'
import './agregar/component.js'
import './editar/component.js'
import './eliminar/component.js'
import '@finances/configuracion/services/Configuracion/Formaspagos'

export class FinancesConfiguracionFormaspagosComponent {
  constructor(FinancesConfiguracionFormaspagosService) {
    this.formasService = FinancesConfiguracionFormaspagosService
    this.seleccionado = null
    this.dialogoEditar = undefined
    this.dialogoEliminar = undefined
    this.dialogoAgregar = undefined
  }

  $onInit() {
    this.promise = this.formasService.get().then((response) => {
      this.data = response.data
    })
  }

  agregar() {
    this.dialogoAgregar.open().then((data) => {
      if (data) this.data = this.data.concat([data])
    })
  }

  editar(forma) {
    this.seleccionado = forma

    this.dialogoEditar.open().then((data) => {
      if (data) Object.assign(this.seleccionado, data)

      this.seleccionado = null
    })
  }

  eliminar(forma) {
    this.seleccionado = forma

    this.dialogoEliminar.open().then((eliminar) => {
      if (eliminar) this.data = this.data.filter((item) => item !== forma)

      this.seleccionado = null
    })
  }
}

module.component('financesConfiguracionFormaspagos', {
  controller: FinancesConfiguracionFormaspagosComponent,
  template,
  bindings: {},
})
