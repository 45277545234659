import module from './module.js'
/* Routes */
import './routes/divisas.js'
/* Components */
export * from './components/component.js'
export * from './components/lista/component.js'
export * from './components/lista/item/component.js'
export * from './components/lista/semana/component.js'
export * from './components/editar/component.js'
/* Services */
export * from './services/Divisas.js'
export * from './services/Divisas/Fecha.js'

export default module.name
