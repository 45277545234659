import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresaCuentaInformacionRepresentanteComponent {
  constructor() {
    this.editando = false
    this.onChange = undefined
  }

  actualizar($data) {
    this.editando = false

    if ($data) {
      this.onChange({ $data })
    }
  }
}

module.component('financesEmpresaCuentaInformacionRepresentante', {
  template,
  controller: FinancesEmpresaCuentaInformacionRepresentanteComponent,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    data: '<',
    onChange: '&',
  },
})
