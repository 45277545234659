import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresaCuentaInformacionFirmantesComponent {
  constructor() {
    this.agregando = false
    this.editando = null
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.data = undefined
    this.onChange = undefined
  }

  $onchanges(changes) {
    if (changes.data) {
      this.data = this.data.filter((item) => !!item.nombre)
    }
  }

  actualizar(item, data) {
    this.editando = null

    if (data) {
      this.data.splice(this.data.indexOf(item), 1, data)
      this.onChange({ $data: this.data })
    }
  }
}

module.component('financesEmpresaCuentaInformacionFirmantes', {
  controller: FinancesEmpresaCuentaInformacionFirmantesComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    data: '<',
    onChange: '&',
  },
})
