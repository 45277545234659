import module from '@finances/partida/module'
import template from './template.html'

export class FinancesPartidaImprimirGenerarComponent {
  constructor(FinancesPartidaChequeService) {
    this.error = null
    this.procesando = false
    this.chequeService = FinancesPartidaChequeService
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idPartida = undefined
    this.modal = undefined
  }

  abrir() {
    if (!this.respuesta) return

    const ventanaAbierta = this.respuesta.open()

    if (!ventanaAbierta) {
      this.error = 'El navegador ha bloqueado la ventana emergente'
    }
  }

  cerrar() {
    this.modal.close()
  }

  generar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.chequeService
      .get(this.idEmpresa, this.idCuenta, this.idPartida)
      .then((response) => (this.respuesta = response.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }
}

module.component('financesPartidaImprimirGenerar', {
  controller: FinancesPartidaImprimirGenerarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPartida: '<',
    cheque: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
