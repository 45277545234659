import module from '@finances/configuracion/module'
import template from './template.html'
import '@finances/configuracion/services/Configuracion/Conceptos'
import './style.scss'

export class FinancesConfiguracionConceptosComponent {
  constructor(FinancesConfiguracionConceptosService) {
    this.conceptosService = FinancesConfiguracionConceptosService
    this.agregando = false
  }

  $onInit() {
    this.promise = this.conceptosService.get().then((response) => (this.data = response.data))
  }

  agregar() {
    this.dialogoAgregar.open().then((data) => {
      if (data) this.data = this.data.concat([data])
    })
  }

  editar(item) {
    this.concepto = item

    this.dialogoEditar.open().then((data) => {
      if (data) Object.assign(this.concepto, data)

      this.concepto = null
    })
  }

  toggleActivo(item) {
    this.concepto = item

    const modal = item.activo ? this.confirmarDeshabilitar : this.confirmarHabilitar

    modal.open().then((data) => {
      if (data) Object.assign(this.concepto, data)

      this.concepto = null
    })
  }
}

module.component('financesConfiguracionConceptos', {
  controller: FinancesConfiguracionConceptosComponent,
  template,
  bindings: {},
})
