import module from '@finances/importarpartidas/module'

export class FinancesImportarpartidasHistorialLoteService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      importe: 'number',
      fecha: 'date',
    }
  }

  get(idLote) {
    return this.$http.get(`api/importar_partidas/historial/lote/${idLote}`, {
      schema: this.schema,
    })
  }

  delete(idLote) {
    return this.$http.delete(`api/importar_partidas/historial/lote/${idLote}`)
  }
}

module.service('FinancesImportarpartidasHistorialLoteService', FinancesImportarpartidasHistorialLoteService)
