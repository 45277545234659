import module from '@finances/configuracion/module'
import template from './template.html'
import '@finances/configuracion/services/Configuracion/Conceptos'

export class FinancesConfiguracionConceptosAgregarComponent {
  constructor(FinancesConfiguracionConceptosService) {
    this.conceptosService = FinancesConfiguracionConceptosService
    this.error = null
    this.procesando = false
    this.data = { descripcion: undefined, color: undefined }
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.conceptosService
      .post(this.data)
      .then((response) => this.modal.close(response.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cancelar() {
    if (this.procesando) return

    this.modal.close()
  }
}

module.component('financesConfiguracionConceptosAgregar', {
  controller: FinancesConfiguracionConceptosAgregarComponent,
  template,
  require: {
    modal: 'kdModal',
  },
})
