import module from '@finances/empresas/module'

export class FinancesPosicionbancariaService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      id_empresa: 'number',
      cuentas: {
        pendientes: 'number',
      },
    }
  }

  get() {
    return this.$http.get('api/posicion_bancaria', {
      schema: this.schema,
    })
  }
}

module.service('FinancesPosicionbancariaService', FinancesPosicionbancariaService)
