import module from '@finances/clasificarmovimientos/module'
import template from './template.html'

export class FinancesClasificarmovimientosAplicarComponent {
  constructor(
    $q,
    KdTaskRunner,
    FinancesEmpresaCuentaMovimientoInformacionTipoMovimientoService,
    FinancesClasificarmovimientosConfiguracionService,
  ) {
    this.$q = $q
    this.KdTaskRunner = KdTaskRunner
    this.FinancesEmpresaCuentaMovimientoInformacionTipoMovimientoService =
      FinancesEmpresaCuentaMovimientoInformacionTipoMovimientoService
    this.FinancesClasificarmovimientosConfiguracionService = FinancesClasificarmovimientosConfiguracionService
    this.completado = false
    this.completados = 0
    this.errores = 0
    this.omitir = true
    this.procesando = false
    this.taskRunner = null
    this.modal = undefined
    this.reglas = []
  }

  $onChanges(changes) {
    if (changes.data) this.data = this.data.filter((item) => item.modificado)
  }

  iniciar() {
    if (this.procesando) return

    this.procesando = true

    this.taskRunner = new this.KdTaskRunner((item) => this.procesar(item), { limit: 10, ignoreError: true })
    this.taskRunner.onProgress = (item) => this.onProgress(item)
    this.taskRunner.onError = () => this.onError()
    this.taskRunner.onComplete = () => this.onComplete()

    this.data.forEach((item) => this.taskRunner.add(item))

    this.taskRunner.start()
  }

  cancelar() {
    this.modal.close(this.completado)
  }

  procesar(item) {
    const data = { id_tipo_movimiento: item.id_tipo_movimiento }

    item.procesando = true
    item.error = null

    return this.FinancesEmpresaCuentaMovimientoInformacionTipoMovimientoService.put(
      item.id_empresa,
      item.id_cuenta,
      item.id_movimiento,
      data,
    )
      .then((response) => {
        item.id_tipo_movimiento = (response.data && response.data.id_tipo_movimiento) || item.id_tipo_movimiento
        item.pendiente = !item.id_tipo_movimiento
        item.modificado = false

        if (item.id_match && this.reglas.indexOf(item.id_match) < 0) {
          this.reglas.push(item.id_match)
        }

        return item
      })
      .catch((response) => {
        item.error = response.data

        throw response
      })
      .finally(() => (item.procesando = false))
  }

  onProgress(item) {
    item.modificado = false
    this.completados++
  }

  onError() {
    this.errores++
  }

  onComplete() {
    const reglas = this.reglas.map((regla) => ({ orden: regla }))

    this.$q
      .when(reglas.length ? this.FinancesClasificarmovimientosConfiguracionService.put(reglas) : true)
      .finally(() => {
        this.procesando = false
        this.completado = true
        this.taskRunner = null
      })
  }
}

module.component('financesClasificarmovimientosAplicar', {
  controller: FinancesClasificarmovimientosAplicarComponent,
  template,
  bindings: {
    data: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
