import module from '@finances/empresa/module'

export class FinancesEmpresaCuentaMovimientosResumenService {
  constructor($filter, $http) {
    this.$filter = $filter
    this.$http = $http
  }

  get(idEmpresa, idCuenta, fechaInicial, fechaFinal) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/movimientos/resumen`, {
      params: {
        fecha_inicial: fechaInicial && this.$filter('date')(fechaInicial, 'yyyy-MM-dd'),
        fecha_final: fechaFinal && this.$filter('date')(fechaFinal, 'yyyy-MM-dd'),
      },
      download: true,
    })
  }
}

module.service('FinancesEmpresaCuentaMovimientosResumenService', FinancesEmpresaCuentaMovimientosResumenService)
