import module from '@finances/partida/module'
import template from './template.html'

export class FinancesPartidaEstatusToggleConfirmarComponent {
  constructor(FinancesCatalogosEstatusPartidasService, FinancesPartidaEstatusService) {
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idPartida = undefined
    this.estatus = undefined
    this.FinancesCatalogosEstatusPartidasService = FinancesCatalogosEstatusPartidasService
    this.FinancesPartidaEstatusService = FinancesPartidaEstatusService
    this.editando = false
    this.modal = undefined
  }

  $onChanges(changes) {
    if (changes.estatus) {
      this.data = Object.assign({}, this.estatus)
    }
  }

  $onInit() {
    this.promise = this.FinancesCatalogosEstatusPartidasService.get().then(
      (response) => (this.estatusPartida = response.data),
    )
  }

  actualizar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.FinancesPartidaEstatusService.put(this.idEmpresa, this.idCuenta, this.idPartida, this.data)
      .then((response) => this.modal.close(response.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    this.modal.close()
  }
}

module.component('financesPartidaEstatusToggleConfirmar', {
  controller: FinancesPartidaEstatusToggleConfirmarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPartida: '<',
    estatus: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
