import module from '@finances/configuracion/module'
import template from './template.html'
import '@finances/configuracion/services/Configuracion/Firmantes/Firmante'

export class FinancesConfiguracionFirmantesEditarComponent {
  constructor(FinancesConfiguracionFirmantesFirmanteService) {
    this.firmanteService = FinancesConfiguracionFirmantesFirmanteService
    this.modal = undefined
    this.firmante = undefined
    this.error = null
    this.procesando = false
    this.data = { nombre: '', apellido_paterno: '', apellido_materno: '' }
  }

  $onChanges(changes) {
    if (changes.firmante) {
      this.data.nombre = this.firmante.nombre
      this.data.apellido_paterno = this.firmante.apellido_paterno
      this.data.apellido_materno = this.firmante.apellido_materno
    }
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.firmanteService
      .put(this.firmante.id, this.data)
      .then(() => this.modal.close(this.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cancelar() {
    this.modal.close()
  }
}

module.component('financesConfiguracionFirmantesEditar', {
  controller: FinancesConfiguracionFirmantesEditarComponent,
  template,
  bindings: {
    firmante: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
