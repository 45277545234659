import module from '@finances/configuracion/module'

export const financesConfiguracionBancosSchema = {
  en_uso: 'boolean',
}

export class FinancesConfiguracionBancosService {
  constructor($http) {
    this.$http = $http
  }

  get() {
    return this.$http.get('api/configuracion/bancos', {
      schema: financesConfiguracionBancosSchema,
    })
  }

  post(data) {
    return this.$http.post('api/configuracion/bancos', data, {
      schema: financesConfiguracionBancosSchema,
    })
  }
}

module.service('FinancesConfiguracionBancosService', FinancesConfiguracionBancosService)
