import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresaCuentaMovimientoMetadataComponent {
  constructor() {
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idMovimiento = undefined
    this.data = undefined
    this.onChange = undefined
  }

  actualizar(data) {
    this.data = data
    this.onChange({ $event: { data: this.data } })
  }
}

module.component('financesEmpresaCuentaMovimientoMetadata', {
  controller: FinancesEmpresaCuentaMovimientoMetadataComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idMovimiento: '<',
    data: '<',
    onChange: '&',
  },
})
