import module from '@finances/clasificarmovimientos/module'

export const financesClasificarmovimientosMovimientosSchema = {
  id_empresa: 'number',
  pendientes: 'number',
  cuentas: {
    id_moneda: 'number',
    id_categoria: 'number',
    movimientos: {
      importe: 'number',
      id_tipo_movimiento: 'number',
    },
    pendientes: 'number',
  },
}

export class FinancesClasificarmovimientosMovimientosService {
  constructor($http) {
    this.$http = $http
  }

  get({ fecha = new Date(), idCategoria = 0, idMoneda = 0 }) {
    return this.$http.get(`api/clasificar_movimientos/movimientos`, {
      params: {
        fecha: fecha.toJSON().slice(0, 10),
        id_categoria: idCategoria,
        id_moneda: idMoneda,
      },
      schema: financesClasificarmovimientosMovimientosSchema,
    })
  }
}

module.service('FinancesClasificarmovimientosMovimientosService', FinancesClasificarmovimientosMovimientosService)
