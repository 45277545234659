import module from '@finances/configuracion/module'
import template from './template.html'

export class FinancesConfiguracionComponent {
  constructor(FinancesConfiguracionService) {
    this.configuracionService = FinancesConfiguracionService
    this.data = undefined
  }

  $onInit() {
    this.promise = this.configuracionService.get().then((response) => {
      this.data = response.data
      this.vacio = Object.values(this.data).every((item) => !item)
    })
  }
}

module.component('financesConfiguracion', {
  controller: FinancesConfiguracionComponent,
  template,
})
