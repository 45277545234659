import angular from 'angular'

export class FinancesEmpresaCuentasExisteService {
  constructor($http) {
    this.$http = $http
    this.schema = {}
  }

  get(idEmpresa, numeroCuenta) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuentas/existe`, {
      params: { numero_cuenta: numeroCuenta },
      schema: this.schema,
    })
  }
}

angular.module('finances.empresa').service('FinancesEmpresaCuentasExisteService', FinancesEmpresaCuentasExisteService)
