import module from '@finances/empresa/module.js'

module.config(($stateProvider) => {
  $stateProvider.state('empresa.cuenta.prestamos.editar', {
    url: '/{id_prestamo}',
    data: { title: 'Editar' },
    views: {
      '@empresa.cuenta': 'financesEmpresaCuentaPrestamosEditar',
    },
    resolve: {
      idPrestamo: ($transition$) => $transition$.params().id_prestamo,
    },
  })
})
