import module from '@finances/importarpartidas/module'

export class FinancesImportarpartidasService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      id: 'number',
      cheque: 'number',
      id_tipo_beneficiario: 'number',
      fecha: 'date',
      importe: 'number',
      id_leyenda: 'number',
    }
  }

  post(data) {
    return this.$http.post('api/importar_partidas', data, {
      schema: this.schema,
    })
  }
}

module.service('FinancesImportarpartidasService', FinancesImportarpartidasService)
