import module from '@finances/importarpartidas/module'
import template from './template.html'

export class FinancesImportarpartidasOrigenComponent {
  constructor() {
    this.origen = null
    this.ORIGEN_EXCEL = 1
  }

  importar(data) {
    this.origen = null

    if (data) this.onClose({ $event: { data } })
  }

  seleccionar(origen) {
    this.origen = origen
  }

  onClose() {}
}

module.component('financesImportarpartidasOrigen', {
  controller: FinancesImportarpartidasOrigenComponent,
  template,
  bindings: {
    onClose: '&',
  },
})
