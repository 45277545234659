import module from '@finances/configuracion/module'

export class FinancesConfiguracionFirmantesService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      id: 'number',
    }
  }

  get() {
    return this.$http.get('api/configuracion/firmantes', {
      schema: this.schema,
    })
  }

  post(data) {
    return this.$http.post('api/configuracion/firmantes', data, {
      schema: this.schema,
    })
  }
}

module.service('FinancesConfiguracionFirmantesService', FinancesConfiguracionFirmantesService)
