import module from '@finances/partidas/module'

module.config(($stateProvider) => {
  $stateProvider.state('partidas.proteccionCheques', {
    url: '/proteccion-cheques',
    data: {
      titleProxy: 'partidas.proteccionCheques.layouts',
    },
    abstract: true,
    redirectTo: { state: 'partidas.proteccionCheques.exportar' },
  })
})
