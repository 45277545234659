import orderBy from 'lodash-es/orderBy.js'
import module from '@finances/partidas/module'
import template from './template.html'

export class FinancesPartidasProteccionChequesExportarComponent {
  data = null

  resultado = null

  seleccionados = []

  opciones = {
    filtro: '',
    empresaId: '',
    bancoId: '',
    monedaId: '',
    agrupacion: 'dia',
    orden: 'fecha',
    desc: true,
    ordenar: ['-fecha', 'cheque'],
    proteger: true,
  }

  dialogoExportar = undefined

  catalogo = {
    empresas: [],
    bancos: [],
    monedas: [],
    agrupacion: [
      { id: '', descripcion: 'No agrupar' },
      { id: 'empresaNombre', descripcion: 'Empresa' },
      { id: 'cuentaNombre', descripcion: 'Cuenta' },
      { id: 'bancoNombre', descripcion: 'Banco' },
      { id: 'monedaNombre', descripcion: 'Moneda' },
      { id: 'dia', descripcion: 'Día' },
      { id: 'mes', descripcion: 'Mes' },
      { id: 'beneficiario', descripcion: 'Beneficiario' },
      { id: 'tipo_movimiento', descripcion: 'Tipo de movimiento' },
    ],
    orden: [
      { id: 'empresaNombre', descripcion: 'Empresa' },
      { id: 'cuentaNombre', descripcion: 'Cuenta' },
      { id: 'bancoNombre', descripcion: 'Banco' },
      { id: 'cheque', descripcion: 'Nº de cheque' },
      { id: 'beneficiario', descripcion: 'Beneficiario' },
      { id: 'fecha', descripcion: 'Fecha' },
      { id: 'importe', descripcion: 'Importe' },
    ],
  }

  filtro = {
    agrupacion: 'beneficiario',
  }

  constructor(FinancesPartidasProteccionChequesPendientesService, AppTimeService) {
    this.financesPartidasProteccionChequesPendientesService = FinancesPartidasProteccionChequesPendientesService
    this.appTimeService = AppTimeService
  }

  $onInit() {
    this.promise = this.obtenerPartidas()
  }

  seleccionar() {
    this.seleccionados = this.data.filter(({ seleccionado }) => seleccionado)
  }

  exportar() {
    this.dialogoExportar.open().then((data) => (this.resultado = data))
  }

  obtenerPartidas() {
    return this.financesPartidasProteccionChequesPendientesService.get().then(({ data }) => {
      const empresasMap = new Map()
      const bancosMap = new Map()
      const monedasMap = new Map()

      const opciones = this.opciones

      this.data = data.map((item) => {
        empresasMap.set(item.empresa.id, item.empresa)
        bancosMap.set(item.banco.id, item.banco)
        monedasMap.set(item.moneda.id, item.moneda)

        const data = { seleccionado: false, protegido: false }

        const object = Object.create(null, {
          ...Object.getOwnPropertyDescriptors(item),
          seleccionado: {
            get: () => data.seleccionado,
            set: (value) => {
              data.seleccionado = Boolean(value)
              data.protegido = data.seleccionado && opciones.proteger
            },
          },
          protegido: {
            get: () => data.protegido,
            set: (value) => {
              data.protegido = Boolean(value)
              data.seleccionado = data.seleccionado || data.protegido
            },
          },
        })

        object.empresaNombre = item.empresa.descripcion
        object.cuentaNombre = item.cuenta.descripcion
        object.bancoNombre = item.banco.descripcion
        object.monedaNombre = item.moneda.descripcion
        object.chequeNumero = String(item.cheque).padStart(8, '0')
        object.dia = this.appTimeService.dayMonthGroup(item.fecha).toUpperCase()
        object.mes = this.appTimeService.monthGroup(item.fecha).toUpperCase()

        object.busqueda = [item.beneficiario, item.cheque, item.cuenta.descripcion, item.empresa.descripcion]
          .join('\t')
          .toLowerCase()

        return object
      })

      this.catalogo.empresas = [
        { id: '', descripcion: 'Todas' },
        ...orderBy(Array.from(empresasMap.values()), 'descripcion'),
      ]
      this.catalogo.bancos = [
        { id: '', descripcion: 'Todos' },
        ...orderBy(Array.from(bancosMap.values()), 'descripcion'),
      ]
      this.catalogo.monedas = [
        { id: '', descripcion: 'Todas' },
        ...orderBy(Array.from(monedasMap.values()), 'descripcion'),
      ]
    })
  }
}

module.component('financesPartidasProteccionChequesExportar', {
  controller: FinancesPartidasProteccionChequesExportarComponent,
  template,
})
