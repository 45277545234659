import module from '@finances/empresa/module'

export class FinancesEmpresaCuentaInformacionFirmanteService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      id: 'number',
      tipo: 'number',
    }
  }

  get(idEmpresa, idCuenta, idFirmante) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/informacion/firmante/${idFirmante}`, {
      schema: this.schema,
    })
  }

  put(idEmpresa, idCuenta, idFirmante, data) {
    return this.$http.put(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/informacion/firmante/${idFirmante}`, data, {
      schema: this.schema,
    })
  }
}

module.service('FinancesEmpresaCuentaInformacionFirmanteService', FinancesEmpresaCuentaInformacionFirmanteService)
