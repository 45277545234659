import module from '@finances/partida/module'

export const financesPartidaBeneficiarioSchema = {
  id_tipo_beneficiario: 'number',
}

export class FinancesPartidaBeneficiarioService {
  constructor($http) {
    this.$http = $http
  }

  get(idEmpresa, idCuenta, idPartida) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/partida/${idPartida}/beneficiario`, {
      schema: financesPartidaBeneficiarioSchema,
    })
  }

  put(idEmpresa, idCuenta, idPartida, data) {
    return this.$http.put(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/partida/${idPartida}/beneficiario`, data, {
      schema: financesPartidaBeneficiarioSchema,
    })
  }
}

module.service('FinancesPartidaBeneficiarioService', FinancesPartidaBeneficiarioService)
