import module from './module.js'
/* Routes */
import './routes/clasificarmovimientos.js'
/* Components */
import './components/component.js'
import './components/aplicar/component.js'
import './components/clasificar/component.js'
import './components/cuenta/component.js'
import './components/exportar/component.js'
import './components/lista/component.js'
import './components/movimiento/component.js'
/* Services */
import './services/Clasificarmovimientos.js'
import './services/Clasificarmovimientos/Configuracion.js'
import './services/Clasificarmovimientos/Reporte.js'
import './services/Clasificarmovimientos/Matcher.js'
import './services/Clasificarmovimientos/Movimientos.js'

export default module.name
