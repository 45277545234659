import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresaCuentaPrestamoDocumentosAgregarComponent {
  constructor(FinancesEmpresaCuentaPrestamoDocumentosService) {
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idPrestamo = undefined
    this.data = undefined
    this.FinancesEmpresaCuentaPrestamoDocumentosService = FinancesEmpresaCuentaPrestamoDocumentosService
    this.archivo = undefined
    this.modal = undefined
    this.error = null
  }

  aceptar() {
    const data = new window.FormData()
    data.append('documento', this.archivo)

    this.procesando = true
    this.error = null

    this.FinancesEmpresaCuentaPrestamoDocumentosService.post(this.idEmpresa, this.idCuenta, this.idPrestamo, data)
      .then((response) => this.cerrar(response.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar(data) {
    this.modal.close(data)
  }
}

module.component('financesEmpresaCuentaPrestamoDocumentosAgregar', {
  controller: FinancesEmpresaCuentaPrestamoDocumentosAgregarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPrestamo: '<',
    data: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
