import module from '@finances/empresa/module'

export class FinancesEmpresaCuentaDocumentoService {
  constructor($api, $http) {
    this.$api = $api
    this.$http = $http
  }

  get(idEmpresa, idCuenta, idDocumento) {
    return this.$api.link(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/documento/${idDocumento}`)
  }

  delete(idEmpresa, idCuenta, idDocumento) {
    return this.$http.delete(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/documento/${idDocumento}`)
  }
}

module.service('FinancesEmpresaCuentaDocumentoService', FinancesEmpresaCuentaDocumentoService)
