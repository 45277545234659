import angular from 'angular'
import template from './template.html'

export class FinancesEmpresaCuentaMovimientosAgregarComponent {
  constructor($q, $state, FinancesCatalogosTiposMovimientoService, FinancesEmpresaCuentaMovimientosService) {
    this.$q = $q
    this.$state = $state
    this.FinancesCatalogosTiposMovimientoService = FinancesCatalogosTiposMovimientoService
    this.FinancesEmpresaCuentaMovimientosService = FinancesEmpresaCuentaMovimientosService
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.data = undefined
    this.error = null
    this.titulos = { A: 'Agregar abono', C: 'Agregar cargo' }
    this.naturaleza = undefined
    this.validarNotas = {
      noSignoIgual: (value) => value.substr(0, 1) !== '=',
    }
  }

  $onInit() {
    this.data = {
      id_tipo_movimiento: undefined,
      fecha: undefined,
      fecha_pago: null,
      forma_pago: undefined,
      importe: undefined,
      interes: undefined,
      capital: undefined,
      interes_porcentaje: 0,
      naturaleza: this.naturaleza,
      notas: '',
      financiado: false,
      financiamiento: null,
    }

    this.promise = this.$q.all([
      this.FinancesCatalogosTiposMovimientoService.get().then((response) => (this.tiposMovimiento = response.data)),
    ])
  }

  agregar() {
    if (this.procesando) return

    this.procesando = true
    this.error = null

    this.FinancesEmpresaCuentaMovimientosService.post(this.idEmpresa, this.idCuenta, this.data)
      .then((response) => {
        if (response.data.id_movimiento) {
          this.$state.go('empresa.cuenta.movimientos.editar', { id_movimiento: response.data.id_movimiento })
        } else {
          response.data = 'Error al agregar el movimiento'

          throw response
        }
      })
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  actualizarFinanciamiento() {
    this.data.financiamiento = this.data.financiado ? [] : null
  }

  cerrar() {
    this.$state.go('empresa.cuenta.movimientos')
  }

  recalcular() {
    if (!(this.data.importe > 0)) {
      this.data.interes = null
      this.data.capital = null

      return
    }

    this.data.interes = parseFloat((this.data.importe * this.data.interes_porcentaje).toFixed(2))
    this.data.capital = parseFloat((this.data.importe * (this.data.interes_porcentaje + 1)).toFixed(2))
  }
}

angular.module('finances.empresa').component('financesEmpresaCuentaMovimientosAgregar', {
  controller: FinancesEmpresaCuentaMovimientosAgregarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    naturaleza: '<',
  },
})
