import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresaComponent {
  constructor(FinancesEmpresaService) {
    this.empresaService = FinancesEmpresaService
  }

  $onInit() {
    this.promise = this.empresaService.get(this.idEmpresa).then((response) => (this.data = response.data))
  }
}

module.component('financesEmpresa', {
  controller: FinancesEmpresaComponent,
  template,
  bindings: {
    idEmpresa: '<',
  },
})
