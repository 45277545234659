import module from '@finances/posicionbancaria/module'

module.config(($stateProvider) => {
  $stateProvider.state('posicionbancaria', {
    url: '/posicion_bancaria/{fecha_corte:date}?{id_categoria:int}',
    data: {
      title: 'Posición bancaria',
    },
    params: {
      fecha_corte: new Date(),
      id_categoria: 0,
    },
    resolve: {
      fechaCorte: ($transition$) => $transition$.params().fecha_corte,
      idCategoria: ($transition$) => $transition$.params().id_categoria,
    },
    redirectTo: { state: 'posicionbancaria.saldos' },
    views: {
      '@': { component: 'financesPosicionbancaria' },
    },
  })
})
