import module from '@finances/partidas/module'
import template from './template.html'

export class FinancesPartidasProteccionChequesLayoutsItemComponent {
  data = undefined
  procesando = false
  eliminado = false

  constructor(FinancesPartidasProteccionChequesLayoutService, AppTimeService) {
    this.financesPartidasProteccionChequesLayoutService = FinancesPartidasProteccionChequesLayoutService
    this.appTimeService = AppTimeService
  }

  $onChanges(changes) {
    if (changes.data) {
      this.data = {
        ...this.data,
        capturaFecha: this.appTimeService.fullDate(this.data.capturaFecha),
        capturaFechaRelativa: this.appTimeService.fromNow(this.data.capturaFecha),
      }
    }
  }

  eliminar() {
    if (this.procesando) return

    this.dialogoEliminar.open().then(({ eliminado }) => {
      this.eliminado = eliminado
      if (this.eliminado) this.onDelete({ $event: { details: this.data } })
    })
  }

  descargar() {
    if (this.procesando) return

    this.procesando = true

    this.financesPartidasProteccionChequesLayoutService
      .get({ id: this.data.id })
      .then(({ data }) => data.save())
      .finally(() => (this.procesando = false))
  }

  onDelete() {}
}

module.component('financesPartidasProteccionChequesLayoutsItem', {
  controller: FinancesPartidasProteccionChequesLayoutsItemComponent,
  template,
  bindings: {
    data: '<',
    onDelete: '&',
  },
})
