import module from '@finances/importarmovimientos/module'
import template from './template.html'

function parseFecha(string) {
  const [month, day, year] = string.split(/-|\//)

  return new Date(Number(year), Number(month) - 1, Number(day))
}

export class FinancesImportarmovimientosOrigenConcordComponent {
  constructor($q, $window, $fileSystem, FinancesImportarmovimientosCuentasService) {
    this.$q = $q
    this.$window = $window
    this.$fileSystem = $fileSystem
    this.FinancesImportarmovimientosCuentasService = FinancesImportarmovimientosCuentasService
    this.archivo = null
    this.data = null
    this.error = null
    this.procesando = false
  }

  cerrar() {
    this.onClose()
  }

  seleccionar() {
    this.$fileSystem
      .select(false, 'text/plain')
      .then((files) => {
        this.error = null
        this.archivo = files[0]

        return this.leer(this.archivo)
          .then((string) => this.decodificar(string))
          .then((movimientos) => this.validar(movimientos))
      })
      .then((movimientos) => {
        const data = {
          origen: 'Archivo ' + this.archivo.name,
          movimientos,
        }

        this.onClose({ $event: { data } })
      })
      .catch((error) => (this.error = error))
  }

  leer(archivo) {
    return this.$q((resolve, reject) => {
      if (!archivo.name.endsWith('.txt')) {
        reject('Tipo de archivo incorrecto')
      } else {
        const reader = new this.$window.FileReader()

        reader.onload = (e) => resolve(e.target.result)
        reader.readAsText(archivo)
      }
    })
  }

  decodificar(string) {
    const numberPattern = /^\d{9,12}/
    const multipleSpacesPattern = / {2,}/g
    const pattern = /^(\d{9,12})\s+([/\d]{10})\s+([-.\d]+)\s+(\d+)\s+((.+?)\s{2,}(.*)|.*)/

    const movimientos = string
      .split('\r\n')
      .reduce((filas, fila, indice) => {
        indice++
        if (fila.trim() === '') return filas

        let texto = fila.replace(/"/g, '').replace(/\t/g, ' ')

        if (filas.length && fila.slice(0, 1) === ' ') {
          texto = fila.trimStart()

          if (texto.slice(0, 1) !== '#') {
            filas[filas.length - 1].texto += texto
          }
        } else {
          filas.push({ indice, texto })
        }

        return filas
      }, [])
      .filter((fila) => numberPattern.test(fila.texto))
      .map((fila) => {
        const matches = pattern.exec(fila.texto)
        if (!matches) {
          console.error(fila)
          throw new Error(`Se generó un error en la línea ${fila.indice} al decodificar el archivo.`)
        }

        const numeroCuenta = matches[1]
        const fecha = parseFecha(matches[2])
        const importe = parseFloat(matches[3])
        const tipo = matches[4]
        const descripcion = (matches[6] ? matches[6] : matches[5]).trim()
        const descripcionTransaccion = (
          matches[7] ? matches[7].replace(multipleSpacesPattern, ' ').replace(/ ,/g, ',') : ''
        ).trim()

        return {
          registro: fila.texto,
          cuenta: 'No especificada',
          empresa: 'No especificada',
          error: null,
          numero_cuenta: numeroCuenta,
          fecha,
          id_tipo_movimiento: undefined,
          id_forma_pago: '01',
          id_moneda: undefined,
          importe: Math.abs(importe),
          naturaleza: importe >= 0 ? 'A' : 'C',
          notas: `DESCRIPTION: ${descripcion} | TYPE: ${tipo}${
            descripcionTransaccion ? ' | ' + descripcionTransaccion : ''
          }`,
          referencia: '',
        }
      })

    if (!movimientos.length) {
      throw new Error('No se encontraron registros en el archivo seleccionado.')
    }

    return movimientos
  }

  obtenerCuentas(movimientos) {
    const numerosCuentas = movimientos.reduce((carry, next) => {
      if (carry.indexOf(next.numero_cuenta) < 0) carry.push(next.numero_cuenta)

      return carry
    }, [])

    return this.FinancesImportarmovimientosCuentasService.get(numerosCuentas).then((response) => {
      return response.data.reduce((carry, next) => {
        carry[next.numero_cuenta.padStart(18, '0')] = next

        return carry
      }, {})
    })
  }

  validar(movimientos) {
    return this.obtenerCuentas(movimientos).then((cuentas) => {
      return movimientos.map((movimiento) => {
        const cuenta = cuentas[movimiento.numero_cuenta.padStart(18, '0')]

        if (cuenta) Object.assign(movimiento, cuenta)

        if (!cuenta) {
          movimiento.error = `No se encontró la cuenta del registro (${movimiento.numero_cuenta})`
        } else if (!(movimiento.fecha instanceof Date)) {
          movimiento.error = 'La fecha del registro no es válida'
        } else if (movimiento.id_moneda !== cuenta.id_moneda) {
          movimiento.error = 'La moneda del registro no coincide con la de la cuenta'
        } else if (!Number.isFinite(movimiento.importe)) {
          movimiento.error = 'El importe del registro no es válido'
        }

        return movimiento
      })
    })
  }

  onClose() {}
}

module.component('financesImportarmovimientosOrigenConcord', {
  controller: FinancesImportarmovimientosOrigenConcordComponent,
  template,
  bindings: {
    onClose: '&',
  },
})
