import module from '@finances/empresas/module'

export class FinancesPosicionbancariaEstadoService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      filas: {
        empresas: {
          id_empresa: 'number',
          importe: 'number',
        },
      },
    }
  }

  get(fechaCorte, { idCategoria = 0, idMoneda = 0, agrupacion = 'm' }) {
    return this.$http.get(`api/posicion_bancaria/${fechaCorte.toJSON().slice(0, 10)}/estado`, {
      params: {
        id_categoria: idCategoria,
        id_moneda: idMoneda || 0,
        agrupacion,
      },
      schema: this.schema,
    })
  }
}

module.service('FinancesPosicionbancariaEstadoService', FinancesPosicionbancariaEstadoService)
