import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresaCuentaMovimientoExportarComponent {
  constructor(FinancesEmpresaCuentaMovimientosResumenService) {
    this.FinancesEmpresaCuentaMovimientosResumenService = FinancesEmpresaCuentaMovimientosResumenService
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.fechaInicial = undefined
    this.fechaFinal = undefined
    this.modal = undefined
    this.error = null
    this.procesando = false
    this.resultado = undefined
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.FinancesEmpresaCuentaMovimientosResumenService.get(
      this.idEmpresa,
      this.idCuenta,
      this.fechaInicial,
      this.fechaFinal,
    )
      .then((response) => (this.resultado = response.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cancelar() {
    this.modal.close()
  }

  descargar() {
    if (!this.resultado) return

    this.resultado.save() // TODO: reemplazar por fileSaver
  }
}

module.component('financesEmpresaCuentaMovimientoExportar', {
  controller: FinancesEmpresaCuentaMovimientoExportarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    fechaInicial: '<',
    fechaFinal: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
