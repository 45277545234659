import angular from 'angular'
import template from './template.html'

export class FinancesEmpresaCuentaPrestamosListaComponent {
  constructor(AppTimeService) {
    this.appTimeService = AppTimeService
  }

  $onChanges(changes) {
    if (changes.data) {
      this.data = this.data.map((item) => {
        item.agrupacion = this.appTimeService.dayMonthGroup(item.fecha)

        return item
      })
    }
  }
}

angular.module('finances.empresa').component('financesEmpresaCuentaPrestamosLista', {
  controller: FinancesEmpresaCuentaPrestamosListaComponent,
  template,
  bindings: {
    data: '<',
  },
})
