import module from '@finances/empresas/module'

export class FinancesPosicionbancariaSaldosEmpresaCuentaService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      partidas: {
        id_empresa: 'number',
        fecha: 'date',
        importe: 'number',
        estatus: {
          fecha: 'date',
        },
        autorizacion: {
          estatus: 'boolean',
        },
      },
      prestamos: {
        id_empresa: 'number',
        importe: 'number',
      },
    }
  }

  get(fechaCorte, idEmpresa, idCuenta) {
    return this.$http.get(
      `api/posicion_bancaria/${fechaCorte.toJSON().slice(0, 10)}/saldos/empresa/${idEmpresa}/cuenta/${idCuenta}`,
      {
        schema: this.schema,
      },
    )
  }
}

module.service('FinancesPosicionbancariaSaldosEmpresaCuentaService', FinancesPosicionbancariaSaldosEmpresaCuentaService)
