import angular from 'angular'
import template from './template.html'
import './style.scss'

class FinancesEmpresaCuentaDocumentosComponent {
  constructor($api, FinancesEmpresaCuentaDocumentosService) {
    this.eliminando = null
    this.src = null
    this.$api = $api
    this.documentoService = FinancesEmpresaCuentaDocumentosService
  }

  $onInit() {
    this.promise = this.documentoService
      .get(this.idEmpresa, this.idCuenta)
      .then((response) => (this.data = response.data))
  }

  agregar(data) {
    this.agregando = false

    if (data) {
      this.data.push(data)
    }
  }

  eliminar(data) {
    this.eliminando = null

    if (data) {
      this.data = this.data.filter((item) => item.id !== data.id)
    }
  }

  seleccionar(item) {
    this.src = this.$api.link('api/empresas/empresa/cuentas/cuenta/documentos/documento', {
      id_empresa: item.id_empresa,
      id_cuenta: item.id_cuenta,
      id_documento: item.id,
    })
  }
}

angular.module('finances.empresa').component('financesEmpresaCuentaDocumentos', {
  controller: FinancesEmpresaCuentaDocumentosComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
  },
})
