import angular from 'angular'
import template from './template.html'

export class FinancesEmpresaCuentaDocumentoComponent {
  constructor(FinancesEmpresaCuentaDocumentoService) {
    this.documentoService = FinancesEmpresaCuentaDocumentoService
    this.src = null
    this.visualizando = false
    this.documento = undefined
  }

  $onChanges(changes) {
    if (changes.documento && this.documento) {
      this.src = this.documentoService.get(this.documento.id_empresa, this.documento.id_cuenta, this.documento.id)
    }
  }

  eliminar() {
    this.confirmarEliminar.open().then((data) => {
      if (data) this.onDelete({ $event: { data } })
    })
  }

  onDelete() {}
}

angular.module('finances.empresa').component('financesEmpresaCuentaDocumento', {
  controller: FinancesEmpresaCuentaDocumentoComponent,
  template,
  bindings: {
    documento: '<',
    onDelete: '&',
  },
})
