import module from '@finances/importarpartidas/module'
import template from './template.html'
import { isDate } from 'angular'

export class FinancesImportarpartidasHistorialComponent {
  constructor($filter, $state) {
    this.$dateFilter = $filter('date')
    this.$state = $state
  }

  $onChanges(changes) {
    if (changes.fecha && !isDate(this.fecha)) this.fecha = new Date()
  }

  buscar() {
    this.$state.go(this.$state.current, { fecha: this.$dateFilter(this.fecha, 'yyyy-MM-dd') }, { location: 'replace' })
  }

  modificarFecha(intervalo) {
    this.fecha = new Date(this.fecha.getFullYear(), this.fecha.getMonth(), this.fecha.getDate() + intervalo)

    this.buscar()
  }
}

module.component('financesImportarpartidasHistorial', {
  template,
  controller: FinancesImportarpartidasHistorialComponent,
  bindings: {
    fecha: '<',
  },
})
