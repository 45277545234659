import module from '@finances/configuracion/module'

export const FinancesConfiguracionConceptosSchema = {
  activo: 'boolean',
  en_uso: 'boolean',
}

export class FinancesConfiguracionConceptosService {
  constructor($http) {
    this.$http = $http
  }

  get() {
    return this.$http.get('api/configuracion/conceptos', {
      schema: FinancesConfiguracionConceptosSchema,
    })
  }

  post(data) {
    return this.$http.post('api/configuracion/conceptos', data, {
      schema: FinancesConfiguracionConceptosSchema,
    })
  }
}

module.service('FinancesConfiguracionConceptosService', FinancesConfiguracionConceptosService)
