import module from '@finances/configuracion/module'

export const FinancesConfiguracionClasificacionmovimientosReglaSchema = {
  id: 'number',
  id_tipo_movimiento: 'number',
  orden: 'number',
  tests: {
    coincide: 'boolean',
  },
}

export class FinancesConfiguracionClasificacionmovimientosReglaService {
  constructor($http) {
    this.$http = $http
  }

  get(id) {
    return this.$http.get(`api/configuracion/clasificacion_movimientos/regla/${id}`, {
      schema: FinancesConfiguracionClasificacionmovimientosReglaSchema,
    })
  }

  delete(id) {
    return this.$http.delete(`api/configuracion/clasificacion_movimientos/regla/${id}`, {
      schema: FinancesConfiguracionClasificacionmovimientosReglaSchema,
    })
  }

  put(id, data) {
    return this.$http.put(`api/configuracion/clasificacion_movimientos/regla/${id}`, data, {
      schema: FinancesConfiguracionClasificacionmovimientosReglaSchema,
    })
  }
}

module.service(
  'FinancesConfiguracionClasificacionmovimientosReglaService',
  FinancesConfiguracionClasificacionmovimientosReglaService,
)
