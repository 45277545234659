import module from '@finances/divisas/module'
import template from './template.html'
import groupBy from 'lodash-es/orderBy.js'

export class FinancesDivisasListaComponent {
  constructor(FinancesDivisasService, AppTimeService) {
    this.FinancesDivisasService = FinancesDivisasService
    this.appTimeService = AppTimeService
    this.data = null
    this.semanas = null
    this.idMoneda = undefined
    this.fecha = undefined
  }

  $onChanges() {
    this.data = null
    this.semanas = null

    if (this.idMoneda && this.fecha) {
      this.promise = this.FinancesDivisasService.get(this.idMoneda, this.fecha).then((response) => {
        this.formarCalendario(this.fecha, response.data)
      })
    }
  }

  formarCalendario(fecha, data) {
    const hoy = new Date()
    const periodo = fecha.getFullYear()
    const mes = fecha.getMonth()

    this.data = Array.from({ length: this.appTimeService.daysInMonth(fecha) }, (_, index) => {
      const dia = index + 1
      const fecha = new Date(periodo, mes, dia)

      return {
        dia,
        semana: this.appTimeService.week(fecha),
        dia_semana: this.appTimeService.weekday(fecha),
        fecha,
        hoy: periodo === hoy.getFullYear() && mes === hoy.getMonth() && dia === hoy.getDate(),
        tipo_cambio: null,
      }
    })

    data.forEach((item) => (this.data[item.fecha.getDate() - 1].tipo_cambio = item.tipo_cambio))

    this.data.reverse()

    this.semanas = Object.entries(groupBy(this.data, 'semana'))
      .map(([semana, dias]) => ({
        periodo,
        mes,
        semana,
        dias,
      }))
      .reverse()

    this.evaluar()
  }

  evaluar() {
    this.data.forEach((item, index) => {
      const previo = this.data[index + 1]

      item.posicion =
        previo && previo.tipo_cambio !== item.tipo_cambio ? (previo.tipo_cambio < item.tipo_cambio ? 1 : -1) : 0
    })
  }
}

module.component('financesDivisasLista', {
  template,
  controller: FinancesDivisasListaComponent,
  bindings: {
    idMoneda: '<',
    fecha: '<',
  },
})
