import module from '@finances/importarmovimientos/module'

export class FinancesImportarmovimientosCuentasService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      id_empresa: 'number',
      id_moneda: 'number',
    }
  }

  get(numerosCuentas) {
    return this.$http.get('api/importar_movimientos/cuentas', {
      params: { numeros_cuentas: numerosCuentas.join(',') },
      schema: this.schema,
    })
  }
}

module.service('FinancesImportarmovimientosCuentasService', FinancesImportarmovimientosCuentasService)
