import module from '@finances/partidas/module'
import template from './template.html'

export class FinancesPartidasListaComponent {
  constructor(FinancesPartidasService, AppTimeService) {
    this.FinancesPartidasService = FinancesPartidasService
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.estatus = undefined
    this.fechaCorte = undefined
    this.appTimeService = AppTimeService
  }

  $onChanges() {
    this.data = undefined

    if (this.idEmpresa && this.idCuenta) {
      this.FinancesPartidasService.get(this.idEmpresa, this.idCuenta, this.estatus, this.fechaCorte).then(
        (response) => {
          this.data = response.data.map((item) => {
            item.agrupacion = this.appTimeService.dayMonthGroup(item.fecha).toUpperCase()

            return item
          })
        },
      )
    }
  }
}

module.component('financesPartidasLista', {
  controller: FinancesPartidasListaComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    fechaCorte: '<',
    estatus: '<',
  },
})
