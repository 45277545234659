import module from '@finances/configuracion/module'
import template from './template.html'
import './agregar/component.js'
import './editar/component.js'
import './eliminar/component.js'
import '@finances/configuracion/services/Configuracion/Categoriascuentas'

export class FinancesConfiguracionCategoriascuentasComponent {
  constructor(FinancesConfiguracionCategoriascuentasService) {
    this.categoriascuentasService = FinancesConfiguracionCategoriascuentasService
    this.seleccionado = null
    this.dialogoEditar = undefined
    this.dialogoEliminar = undefined
    this.dialogoAgregar = undefined
  }

  $onInit() {
    this.promise = this.categoriascuentasService.get().then((response) => {
      this.data = response.data
    })
  }

  agregar() {
    this.dialogoAgregar.open().then((data) => {
      if (data) this.data = this.data.concat([data])
    })
  }

  editar(banco) {
    this.seleccionado = banco

    this.dialogoEditar.open().then((data) => {
      if (data) Object.assign(this.seleccionado, data)

      this.seleccionado = null
    })
  }

  eliminar(banco) {
    this.seleccionado = banco

    this.dialogoEliminar.open().then((eliminar) => {
      if (eliminar) this.data = this.data.filter((item) => item !== banco)

      this.seleccionado = null
    })
  }
}

module.component('financesConfiguracionCategoriascuentas', {
  controller: FinancesConfiguracionCategoriascuentasComponent,
  template,
  bindings: {},
})
