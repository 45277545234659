import angular from 'angular'
import template from './template.html'

export class FinancesEmpresaCuentaPrestamoInformacionComponent {
  recalcular() {
    if (!(this.data.importe > 0)) {
      this.data.interes = null
      this.data.capital = null
      this.data.financiamiento = []
      this.data.destinatario_importe = null

      return
    }

    this.data.interes = parseFloat((this.data.importe * this.data.interes_porcentaje).toFixed(2))
    this.data.capital = parseFloat((this.data.importe * (this.data.interes_porcentaje + 1)).toFixed(2))

    this.data.destinatario_importe =
      this.data.id_moneda === 1 ? this.data.importe / this.data.tipo_cambio : this.data.importe * this.data.tipo_cambio
  }
}

angular.module('finances.empresa').component('financesEmpresaCuentaPrestamoInformacion', {
  controller: FinancesEmpresaCuentaPrestamoInformacionComponent,
  template,
  bindings: {
    data: '<',
    catalogos: '<',
  },
})
