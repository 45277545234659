import module from '@finances/posicionbancaria/module'

module.config(($stateProvider) => {
  $stateProvider.state('posicionbancaria.estado', {
    url: '/estado?{id_moneda:int}&{agrupacion}',
    data: {
      title: 'Estado',
    },
    reloadOnSearch: false,
    resolve: {
      idMoneda: ($transition$) => $transition$.params().id_moneda,
      agrupacion: ($transition$) => $transition$.params().agrupacion,
    },
    views: {
      '@': 'financesPosicionbancariaEstado',
    },
  })
})
