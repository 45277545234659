import module from '@finances/partidas/module'
import template from './template.html'

export class FinancesPartidasProteccionChequesExportarEditarComponent {
  data = undefined

  actualizar(data) {
    this.onChange(data)
  }

  eliminar(data) {
    this.onDelete(data)
  }

  cerrar() {
    this.onClose()
  }

  onChange() {}

  onDelete() {}

  onClose() {}
}

module.component('financesPartidasProteccionChequesExportarEditar', {
  controller: FinancesPartidasProteccionChequesExportarEditarComponent,
  template,
  bindings: {
    data: '<',
    onChange: '&',
    onDelete: '&',
    onClose: '&',
  },
})
