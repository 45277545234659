import module from '@finances/partidas/module'
import template from './template.html'

export class FinancesPartidasAgregarConfirmarComponent {
  constructor($state, FinancesPartidasService) {
    this.$state = $state
    this.FinancesPartidasService = FinancesPartidasService
    this.data = undefined
    this.error = null
    this.modal = undefined
    this.procesando = false
    this.resultado = null
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.FinancesPartidasService.post(this.data.id_empresa, this.data.id_cuenta, this.data)
      .then((response) => (this.resultado = response.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    this.modal.close(this.resultado)
  }

  nuevo() {
    this.$state.reload()
  }
}

module.component('financesPartidasAgregarConfirmar', {
  controller: FinancesPartidasAgregarConfirmarComponent,
  template,
  bindings: {
    data: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
