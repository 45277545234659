import module from '@finances/clasificarmovimientos/module'

export const financesClasificarmovimientosSchema = {}

export class FinancesClasificarmovimientosService {
  constructor($http) {
    this.$http = $http
  }

  get() {
    return this.$http.get(`api/clasificar_movimientos`, {
      schema: financesClasificarmovimientosSchema,
    })
  }
}

module.service('FinancesClasificarmovimientosService', FinancesClasificarmovimientosService)
