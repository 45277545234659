import module from '@finances/partida/module'
import template from './template.html'

export class FinancesPartidaAutorizacionToggleComponent {
  constructor($timeout, FinancesPartidaAutorizacionService) {
    this.$timeout = $timeout
    this.autorizacionService = FinancesPartidaAutorizacionService
    this.error = null
    this.estatus = undefined
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idPartida = undefined
    this.procesando = false
  }

  actualizar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    const data = { estatus: !this.estatus }

    this.autorizacionService
      .put(this.idEmpresa, this.idCuenta, this.idPartida, data)
      .then((response) => this.cerrar(response.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar(data) {
    this.onChange({ $event: { data } })
  }

  onChange() {}
}

module.component('financesPartidaAutorizacionToggle', {
  controller: FinancesPartidaAutorizacionToggleComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPartida: '<',
    estatus: '<',
    onChange: '&',
  },
})
