import module from '@finances/importarmovimientos/module'
import template from './template.html'
import './style.scss'

export class FinancesImportarmovimientosListaItemComponent {
  constructor(
    FinancesCatalogosTiposMovimientoService,
    FinancesEmpresaCuentaMovimientoInformacionTipoMovimientoService,
  ) {
    this.data = undefined
    this.FinancesCatalogosTiposMovimientoService = FinancesCatalogosTiposMovimientoService
    this.FinancesEmpresaCuentaMovimientoInformacionTipoMovimientoService =
      FinancesEmpresaCuentaMovimientoInformacionTipoMovimientoService
    this.procesando = false
  }

  $onInit() {
    this.FinancesCatalogosTiposMovimientoService.get().then((response) => (this.tiposMovimiento = response.data))
  }

  actualizar() {
    if (this.procesando) return

    if (!this.data.id_movimiento) {
      this.onChange({ $event: { data: this.data } })

      return
    }

    this.procesando = true

    const data = { id_tipo_movimiento: this.data.id_tipo_movimiento }

    this.FinancesEmpresaCuentaMovimientoInformacionTipoMovimientoService.put(
      this.data.id_empresa,
      this.data.id_cuenta,
      this.data.id_movimiento,
      data,
    )
      .then((response) => {
        Object.assign(this.data, response.data)

        this.onChange({ $event: { data: this.data } })
      })
      .finally(() => (this.procesando = false))
  }

  onChange() {}
}

module.component('financesImportarmovimientosListaItem', {
  controller: FinancesImportarmovimientosListaItemComponent,
  template,
  bindings: {
    data: '<',
    onChange: '&',
  },
})
