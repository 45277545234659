import module from '@finances/empresas/module'
import template from './template.html'

export class FinancesPosicionbancariaResumenComponent {
  constructor(FinancesPosicionbancariaResumenService, FinancesCatalogosCategoriasCuentaService) {
    this.FinancesPosicionbancariaResumenService = FinancesPosicionbancariaResumenService
    this.fechaCorte = undefined
    this.completado = false
    this.error = null
    this.modal = undefined
    this.opciones = { idCategoria: 0, incluirSaldos: true, incluirEstado: true, incluirPartidas: true }
    this.procesando = false
    this.resultado = null
    this.catalogos = {}
    this.promise = FinancesCatalogosCategoriasCuentaService.get().then((response) => {
      this.catalogos.categoriasCuenta = [{ id: 0, descripcion: 'Todas' }].concat(response.data)
    })
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.FinancesPosicionbancariaResumenService.get(this.fechaCorte, this.opciones)
      .then((response) => {
        this.completado = true
        this.resultado = response.data
      })
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    this.modal.close(this.resultado)
  }

  guardar() {
    if (this.resultado) this.resultado.save()
  }
}

module.component('financesPosicionbancariaResumen', {
  controller: FinancesPosicionbancariaResumenComponent,
  template,
  bindings: {
    fechaCorte: '<',
    onClose: '&',
  },
  require: {
    modal: 'kdModal',
  },
})
