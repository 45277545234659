import module from '@finances/configuracion/module'

export class FinancesConfiguracionMonedasMonedaService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      id: 'number',
    }
  }

  put(id, data) {
    return this.$http.put(`api/configuracion/monedas/${id}`, data, {
      schema: this.schema,
    })
  }

  delete(id) {
    return this.$http.delete(`api/configuracion/monedas/${id}`)
  }
}

module.service('FinancesConfiguracionMonedasMonedaService', FinancesConfiguracionMonedasMonedaService)
