import module from '@finances/empresas/module'
import template from './template.html'
import orderBy from 'lodash-es/orderBy.js'
import './style.scss'

export class FinancesPosicionbancariaEstadoGridComponent {
  constructor($interpolate, FinancesPosicionbancariaEstadoService) {
    this.filtro = ''
    this.grid = undefined
    this.posicionbancariaEstadoService = FinancesPosicionbancariaEstadoService
    this.$interpolate = $interpolate
    this.idCategoria = undefined
    this.fechaCorte = undefined
    this.agrupacion = undefined
  }

  $onChanges(changes) {
    if (changes.idMoneda && [1, 2].indexOf(this.idMoneda) < 0) {
      this.idMoneda = undefined
    }

    if (changes.fechaCorte || changes.idCategoria || changes.idMoneda || changes.agrupacion) {
      this.load()
    } else if (changes.filtro) {
      this.filtrar()
    }
  }

  load() {
    this.grid = undefined

    const opciones = {
      idCategoria: this.idCategoria,
      idMoneda: this.idMoneda,
      agrupacion: this.agrupacion,
    }

    this.promise = this.posicionbancariaEstadoService.get(this.fechaCorte, opciones).then((response) => {
      this.columnas = orderBy(response.data.columnas, 'descripcion')
      this.data = response.data.filas.map((item) => {
        const row = {
          id: item.id,
          id_naturaleza: item.id_naturaleza,
          descripcion: item.descripcion,
          total: 0,
        }

        item.empresas.forEach((empresa) => {
          row['empresa_' + empresa.id_empresa] = empresa.importe

          row.total += empresa.importe
        })

        return row
      })

      this.data = orderBy(this.data, ['id_naturaleza', 'id'], ['desc', 'asc'])

      this.onChange({ $event: { data: this.data } })

      this.grid = this.generarGrid()

      this.filtrar()
    })
  }

  filtrar() {
    if (this.grid && this.grid.api) {
      this.grid.api.setQuickFilter(this.filtro)
    }
  }

  generarGrid() {
    const conceptoRendererTemplate =
      '<code class="tag" style="line-height:1.5;margin-right:4px">{{id}}</code> {{descripcion}}'
    const conceptoRendererCompiler = this.$interpolate(conceptoRendererTemplate)
    const currencyRendererTemplate = '<div align="right"><span float="left">$</span>{{importe | currency: ""}}</div>'
    const currencyRendererCompiler = this.$interpolate(currencyRendererTemplate)

    const conceptoRenderer = (params) => {
      if (params.node.group) {
        return params.node.key === 'I' ? 'Ingresos' : 'Egresos'
      } else {
        return conceptoRendererCompiler({ id: params.data.id, descripcion: params.data.descripcion })
      }
    }

    const currencyRenderer = (params) => {
      const importe = params.value

      return importe
        ? currencyRendererCompiler({ importe })
        : '<div style="text-align: right; opacity: .25">&mdash;</div>'
    }

    const currencyStyle = (params) => {
      const style = {}

      if (params.value < 0) {
        style.color = 'var(--error__color)'
      }

      return style
    }

    const columnas = [
      {
        field: 'id_naturaleza',
        hide: true,
      },
      {
        field: 'descripcion',
        hide: true,
      },
      {
        headerName: 'Total',
        field: 'total',
        width: 110,
        minWidth: 80,
        cellRenderer: currencyRenderer,
        cellStyle: currencyStyle,
        aggFunc: 'sum',
      },
    ]

    this.columnas.forEach((item) => {
      columnas.push({
        headerName: item.descripcion,
        field: 'empresa_' + item.id,
        width: 120,
        minWidth: 80,
        cellRenderer: currencyRenderer,
        cellStyle: currencyStyle,
        aggFunc: 'sum',
      })
    })

    return {
      pinnedColumnCount: 2,
      enableColResize: true,
      rowSelection: 'single',
      columnDefs: columnas,
      rowData: this.data,
      icons: {
        groupExpanded: '<i class="fa fa-fw fa-caret-down"></i>',
        groupContracted: '<i class="fa fa-fw fa-caret-right"></i>',
        headerGroupOpened: '<i class="fa fa-fw fa-caret-left"></i>',
        headerGroupClosed: '<i class="fa fa-fw fa-caret-right"></i>',
      },
      groupKeys: ['id_naturaleza'],
      groupDefaultExpanded: true,
      groupColumnDef: {
        headerName: 'Concepto',
        field: 'descripcion',
        width: 250,
        minWidth: 100,
        cellRenderer: {
          renderer: 'group',
          padding: 0,
          innerRenderer: conceptoRenderer,
        },
      },
    }
  }

  onChange() {}
}

module.component('financesPosicionbancariaEstadoGrid', {
  controller: FinancesPosicionbancariaEstadoGridComponent,
  template,
  bindings: {
    fechaCorte: '<',
    idCategoria: '<',
    idMoneda: '<',
    agrupacion: '<',
    filtro: '<',
    onChange: '&',
  },
})
