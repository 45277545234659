import module from '@finances/catalogos/module'

export class FinancesCatalogosAreasService {
  constructor($http) {
    this.$http = $http
    this.schema = {}
  }

  get() {
    return this.$http.get('api/catalogos/areas', {
      schema: this.schema,
      cache: true,
    })
  }
}

module.service('FinancesCatalogosAreasService', FinancesCatalogosAreasService)
