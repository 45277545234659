import module from '@finances/importarmovimientos/module'

export class FinancesImportarmovimientosDecodificarService {
  constructor($log, AppTimeService, KdWorkerService) {
    this.$log = $log
    this.appTimeService = AppTimeService
    this.KdWorkerService = KdWorkerService
  }

  workerRef = null

  get worker() {
    if (!this.workerRef) {
      this.worker = this.KdWorkerService.createWorker('api/importar_movimientos/script/')
    }

    return this.workerRef
  }

  set worker(worker) {
    if (this.workerRef === worker) return

    if (this.workerRef) {
      this.workerRef.terminate()
    }

    this.workerRef = worker
  }

  terminar() {
    this.worker = null
  }

  canHandle(parserId) {
    return this.worker.canHandle(parserId)
  }

  getConfig(parserId) {
    return this.worker.getOptions(parserId)
  }

  decodificar(parserId, data, opciones, cuenta) {
    return this.worker.parse(parserId, data, opciones).then((resultado) =>
      resultado.map((item, index) => {
        if (!validarImporte(item.importe)) {
          throw new Error(`Error en la fila ${index + 1}, importe no válido: ${String(item.importe)}`)
        }

        if (!validarFecha(item.fecha)) {
          throw new Error(`Error en la fila ${index + 1}, fecha no válida: ${String(item.fecha)}`)
        }

        if (!validarFormaPagoId(item.formaPagoId)) {
          throw new Error(`Error en la fila ${index + 1}, la forma de pago no es válida: ${String(item.formaPagoId)}`)
        }

        return {
          id: index,
          id_empresa: cuenta.id_empresa,
          id_cuenta: cuenta.id_cuenta,
          id_banco: cuenta.id_banco,
          id_moneda: cuenta.id_moneda,
          id_cuenta_kardex: cuenta.id_cuenta_kardex,
          id_forma_pago: item.formaPagoId.toString().padStart(2, '0'),
          numero_cuenta: cuenta.numero_cuenta,
          importe: Math.abs(item.importe),
          naturaleza: item.importe < 0 ? 'C' : 'A',
          notas: item.notas,
          moneda: cuenta.moneda,
          fecha: item.fecha,
          banco: cuenta.banco,
          referencia: item.referencia,
          agrupacion: this.appTimeService.dayMonthGroup(item.fecha),
        }
      }),
    )
  }
}

function validarFecha(fecha) {
  return fecha instanceof Date && !isNaN(fecha.getTime())
}

function validarImporte(importe) {
  return Number.isFinite(importe)
}

function validarFormaPagoId(id) {
  return id === 1 || id === 2
}

module.service('FinancesImportarmovimientosDecodificarService', FinancesImportarmovimientosDecodificarService)
