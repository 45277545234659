import module from '@finances/empresa/module'

export const financesEmpresaCuentaMovimientoPagoSchema = {
  fecha_pago: 'date',
}

export class FinancesEmpresaCuentaMovimientoPagoService {
  constructor($http) {
    this.$http = $http
  }

  get(idEmpresa, idCuenta, idMovimiento) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/movimiento/${idMovimiento}/pago`, {
      schema: financesEmpresaCuentaMovimientoPagoSchema,
    })
  }

  put(idEmpresa, idCuenta, idMovimiento, data) {
    return this.$http.put(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/movimiento/${idMovimiento}/pago`, data, {
      schema: financesEmpresaCuentaMovimientoPagoSchema,
    })
  }
}

module.service('FinancesEmpresaCuentaMovimientoPagoService', FinancesEmpresaCuentaMovimientoPagoService)
