import module from '@finances/partidas/module'
import template from './template.html'

export class FinancesPartidasEditarComponent {
  constructor($state) {
    this.$state = $state
    this.eliminado = false
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idPartida = undefined
  }

  cerrar() {
    this.$state.go('partidas', { id_empresa: this.idEmpresa, id_cuenta: this.idCuenta }, { reload: true })
  }

  eliminar() {
    this.eliminado = true
  }
}

module.component('financesPartidasEditar', {
  controller: FinancesPartidasEditarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPartida: '<',
  },
})
