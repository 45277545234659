import module from '@finances/empresa/module'

export const financesEmpresaCuentaPrestamosSchema = {
  capital: 'number',
  destinatario_importe: 'number',
  destinatario_id_moneda: 'number',
  destinatario_tipo_cambio: 'number',
  fecha: 'date',
  fecha_pago: 'date',
  financiamiento: {
    capital: 'number',
    fecha: 'date',
    importe: 'number',
    indice: 'number',
    interes: 'number',
  },
  id_moneda: 'number',
  importe: 'number',
  interes: 'number',
  interes_porcentaje: 'number',
  tipo_cambio: 'number',
}

export class FinancesEmpresaCuentaPrestamosService {
  constructor($http) {
    this.$http = $http
  }

  get(idEmpresa, idCuenta, fechaInicial, fechaFinal) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/prestamos`, {
      params: {
        fecha_inicial: fechaInicial?.toJSON().slice(0, 10),
        fecha_final: fechaFinal?.toJSON().slice(0, 10),
      },
      schema: financesEmpresaCuentaPrestamosSchema,
    })
  }

  post(idEmpresa, idCuenta, data) {
    return this.$http.post(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/prestamos`, data, {
      schema: financesEmpresaCuentaPrestamosSchema,
      clearCache: 'finances',
    })
  }
}

module.service('FinancesEmpresaCuentaPrestamosService', FinancesEmpresaCuentaPrestamosService)
