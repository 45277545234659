import module from '@finances/empresas/module'
import template from './template.html'

class FinancesEmpresaCuentasListaGrupoComponent {
  constructor() {
    this.expandido = false
    this.filtro = undefined
  }

  $onChanges(changes) {
    if (changes.data) {
      this.data = Array.isArray(this.data) ? this.data : []

      this.activo = this.data.filter((item) => item.activo)
      this.inactivo = this.data.filter((item) => !item.activo)
    }
  }
}

module.component('financesEmpresaCuentasListaGrupo', {
  controller: FinancesEmpresaCuentasListaGrupoComponent,
  template,
  bindings: {
    data: '<',
    filtro: '<',
  },
})
