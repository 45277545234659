import module from '@finances/empresas/module'
import template from './template.html'

export class FinancesPosicionbancariaSaldosListaComponent {
  constructor($q, FinancesPosicionbancariaSaldosService) {
    this.$q = $q
    this.FinancesPosicionbancariaSaldosService = FinancesPosicionbancariaSaldosService
    this.filtro = { $: '', id_categoria: '' }
    this.fechaCorte = undefined
    this.idCategoria = undefined
  }

  $onChanges(changes) {
    if (changes.fechaCorte || changes.idCategoria) {
      this.data = undefined

      if (this.fechaCorte) {
        this.FinancesPosicionbancariaSaldosService.get(this.fechaCorte, { idCategoria: this.idCategoria }).then(
          (response) => {
            this.data = response.data

            this.onChange({ $event: { data: this.data } })
          },
        )
      }
    }
  }

  actualizar(item, data) {
    Object.assign(item, data)

    this.data.cuentas = [].concat(this.data.cuentas)
  }

  onChange() {}
}

module.component('financesPosicionbancariaSaldosLista', {
  controller: FinancesPosicionbancariaSaldosListaComponent,
  template,
  bindings: {
    fechaCorte: '<',
    idCategoria: '<',
    filtro: '<',
    onChange: '&',
  },
})
