import module from '@finances/catalogos/module'

export const financesCatalogosTiposCuentaSchema = {
  id: 'number',
}

export class FinancesCatalogosTiposCuentaService {
  constructor($http) {
    this.$http = $http
  }

  get() {
    return this.$http.get('api/catalogos/tipos_cuenta', {
      schema: financesCatalogosTiposCuentaSchema,
      cache: true,
    })
  }
}

module.service('FinancesCatalogosTiposCuentaService', FinancesCatalogosTiposCuentaService)
