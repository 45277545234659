import module from '@finances/importarmovimientos/module'

module.config(($stateProvider) => {
  $stateProvider.state('importarmovimientos.historial', {
    url: '/historial?{fecha:date}',
    data: {
      title: 'Historial',
    },
    reloadOnSearch: false,
    views: {
      '@': 'financesImportarmovimientosHistorial',
    },
    resolve: {
      fecha: ($transition$) => $transition$.params().fecha,
    },
  })
})
