import module from '@finances/configuracion/module'

module.config(($stateProvider) => {
  $stateProvider.state('configuracion.bancos', {
    url: '/bancos',
    data: { title: 'Bancos' },
    views: {
      '@': 'financesConfiguracionBancos',
    },
  })
})
