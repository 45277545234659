import module from '@finances/empresa/module'

export class FinancesEmpresaCuentaMovimientoDocumentosService {
  constructor($http, $window) {
    this.$http = $http
    this.$window = $window
  }

  post(idEmpresa, idCuenta, idMovimiento, data) {
    const formData = new this.$window.FormData()
    formData.set('documento', data)

    return this.$http.post(
      `api/empresa/${idEmpresa}/cuenta/${idCuenta}/movimiento/${idMovimiento}/documentos`,
      formData,
    )
  }
}

module.service('FinancesEmpresaCuentaMovimientoDocumentosService', FinancesEmpresaCuentaMovimientoDocumentosService)
