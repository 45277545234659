import module from '@finances/importarmovimientos/module'

export class FinancesImportarmovimientosService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      id: 'number',
      id_empresa: 'number',
      id_moneda: 'number',
      importe: 'number',
      fecha: 'date',
    }
  }

  get(data) {
    return this.$http.post('api/importar_movimientos', data, {
      schema: this.schema,
    })
  }

  post(data) {
    return this.$http.post('api/importar_movimientos', data, {
      schema: this.schema,
    })
  }
}

module.service('FinancesImportarmovimientosService', FinancesImportarmovimientosService)
