import module from '@finances/empresas/module'

export const financesEmpresasSchema = {
  acceso_fecha: 'datetime',
  id_empresa: 'number',
}

export class FinancesEmpresasService {
  constructor($http) {
    this.$http = $http
  }

  get() {
    return this.$http.get('api/empresas', {
      schema: financesEmpresasSchema,
    })
  }

  post(data) {
    return this.$http.post('api/empresas', data, {
      schema: financesEmpresasSchema,
    })
  }
}

module.service('FinancesEmpresasService', FinancesEmpresasService)
