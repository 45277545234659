import module from '@finances/clasificarmovimientos/module'
import template from './template.html'

export class FinancesClasificarmovimientosClasificarComponent {
  constructor(FinancesClasificarmovimientosMatcherService) {
    this.FinancesClasificarmovimientosMatcherService = FinancesClasificarmovimientosMatcherService
    this.data = undefined
    this.error = null
    this.omitir = true
    this.procesando = false
    this.modal = undefined
    this.modificados = 0
    this.completado = false
  }

  $onInit() {
    this.totalizar()
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true
    this.modificados = 0
    this.completado = false

    this.FinancesClasificarmovimientosMatcherService.get()
      .then((matcher) => {
        this.movimientos.forEach((item) => {
          const idTipoMovimiento = item.id_tipo_movimiento
          const match = matcher.find(item)
          const modificado = idTipoMovimiento !== match.data.id_tipo_movimiento

          if (!match.id || !modificado) return

          item.modificado = true
          item.id_match = match.id
          item.id_tipo_movimiento = match.data.id_tipo_movimiento
          item.pendiente = !item.id_tipo_movimiento

          this.modificados++
        })

        this.completado = true
      })
      .catch((error) => (this.error = error))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    this.modal.close()
  }

  totalizar() {
    this.movimientos = this.data.filter((item) => (this.omitir ? item.pendiente : true))
  }
}

module.component('financesClasificarmovimientosClasificar', {
  controller: FinancesClasificarmovimientosClasificarComponent,
  template,
  bindings: {
    data: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
