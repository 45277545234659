import module from '@finances/importarmovimientos/module'
import template from './template.html'

export class FinancesImportarmovimientosListaComponent {
  constructor() {
    this.confirmar = false
    this.data = undefined
    this.idLote = null
  }

  actualizar(item, data) {
    this.data.splice(this.data.indexOf(item), 1, data)

    this.onChange({ $event: { data: this.data } })
  }

  onChange() {}
}

module.component('financesImportarmovimientosLista', {
  controller: FinancesImportarmovimientosListaComponent,
  template,
  bindings: {
    data: '<',
    onChange: '&',
  },
})
