import module from '@finances/empresas/module'
import template from './template.html'

export class FinancesPartidasProteccionChequesExportarConfirmarComponent {
  data = []

  completado = false

  error = null

  modal = undefined

  procesando = false

  resultado = null

  archivo = null

  constructor(FinancesPartidasProteccionChequesLayoutService, FinancesPartidasProteccionChequesLayoutsService) {
    this.financesPartidasProteccionChequesLayoutService = FinancesPartidasProteccionChequesLayoutService
    this.financesPartidasProteccionChequesLayoutsService = FinancesPartidasProteccionChequesLayoutsService
  }

  exportar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    const partidas = Object.fromEntries(this.data.map(({ id, protegido }) => [id, protegido ? 'P' : 'D']))

    this.financesPartidasProteccionChequesLayoutsService
      .post({ partidas })
      .then(({ data }) => {
        this.resultado = data
        return this.descargar(data.id)
      })
      .then(({ data }) => {
        this.completado = true
        this.archivo = data
      })
      .catch(({ data }) => (this.error = data))
      .finally(() => (this.procesando = false))
  }

  descargar(id) {
    return this.financesPartidasProteccionChequesLayoutService.get({ id })
  }

  cerrar() {
    this.modal.close(this.resultado)
  }

  guardar() {
    if (this.archivo) this.archivo.save()
  }
}

module.component('financesPartidasProteccionChequesExportarConfirmar', {
  controller: FinancesPartidasProteccionChequesExportarConfirmarComponent,
  template,
  bindings: {
    data: '<',
    onClose: '&',
  },
  require: {
    modal: 'kdModal',
  },
})
