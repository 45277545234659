import module from '@finances/empresa/module.js'

module.config(($stateProvider) => {
  $stateProvider.state('empresa.cuenta.movimientos.editar', {
    url: '/{id_movimiento}',
    data: { title: 'Editar' },
    views: {
      '@': 'financesEmpresaCuentaMovimientosEditar',
    },
    resolve: {
      idMovimiento: ($transition$) => $transition$.params().id_movimiento,
    },
  })
})
