import module from '@finances/clasificarmovimientos/module'
import template from './template.html'
import { isDate } from 'angular'

export class FinancesClasificarmovimientosExportarComponent {
  constructor(FinancesClasificarmovimientosReporteService) {
    this.FinancesClasificarmovimientosReporteService = FinancesClasificarmovimientosReporteService
    this.error = null
    this.fecha = undefined
    this.idCategoria = undefined
    this.idMoneda = undefined
    this.modal = undefined
    this.procesando = false
    this.opciones = {
      agruparFecha: true,
    }
  }

  $onChanges(changes) {
    if (changes.fecha && isDate(this.fecha)) {
      this.opciones.fechaInicial = new Date(this.fecha)
      this.opciones.fechaFinal = new Date(this.fecha)
    }

    if (changes.idCategoria) this.opciones.idCategoria = this.idCategoria
    if (changes.idMoneda) this.opciones.idMoneda = this.idMoneda
  }

  cerrar() {
    this.modal.close()
  }

  guardar() {
    if (this.respuesta) this.respuesta.save()
  }

  exportar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.FinancesClasificarmovimientosReporteService.get(this.opciones)
      .then((response) => (this.respuesta = response.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }
}

module.component('financesClasificarmovimientosExportar', {
  controller: FinancesClasificarmovimientosExportarComponent,
  template,
  bindings: {
    fecha: '<',
    idCategoria: '<',
    idMoneda: '<',
    catalogos: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
