import module from '@finances/partidas/module'
import template from './template.html'

export class FinancesPartidasListaItemComponent {
  constructor() {
    this.data = undefined
    this.editando = false
    this.eliminado = false
  }

  actualizar(data) {
    this.data.autorizacion.estatus = data.autorizacion.estatus
    this.data.beneficiario = data.beneficiario.nombre
    this.data.cheque = data.informacion.cheque
    this.data.estatus = data.estatus
    this.data.estatus_fecha = data.informacion.estatus_fecha
    this.data.fecha = data.informacion.fecha
    this.data.importe = data.informacion.importe
    this.data.tipo_movimiento = data.informacion.tipo_movimiento
  }

  autorizar(data) {
    if (data) this.data.autorizacion = data
  }

  actualizarEstatus(data) {
    this.data.estatus = data
  }

  eliminar(data) {
    this.eliminado = true
    this.editando = false

    this.onDelete({ $event: { data } })
  }

  onDelete() {}
}

module.component('financesPartidasListaItem', {
  controller: FinancesPartidasListaItemComponent,
  template,
  bindings: {
    data: '<',
    onDelete: '&',
  },
})
