import module from '@finances/importarmovimientos/module'
import template from './template.html'

export class FinancesImportarmovimientosComponent {
  constructor($filter, $window, FinancesCatalogosTiposMovimientoService) {
    this.$window = $window
    this.orderBy = $filter('orderBy')
    this.dialogoGuardar = undefined
    this.FinancesCatalogosTiposMovimientoService = FinancesCatalogosTiposMovimientoService
    this.cuentas = null
    this.data = null
    this.idLote = null
    this.invalidos = null
    this.invertirOrden = false
    this.origen = null
  }

  $onInit() {
    this.promise = this.FinancesCatalogosTiposMovimientoService.get().then(
      (response) => (this.tiposMovimiento = response.data),
    )
  }

  borrar() {
    this.cuentas = null
    this.data = null
    this.idLote = null
    this.invalidos = null
    this.origen = null
  }

  guardar() {
    this.dialogoGuardar.open().then((data) => {
      this.confirmar = false

      if (!data) return

      this.idLote = data.id_lote

      this.data.forEach((item, indice) => Object.assign(item, data.movimientos[indice]))
    })
  }

  importar(origen, data) {
    this.idLote = null

    if (Array.isArray(data) && data.length) {
      this.data = data.filter((item) => !item.error)
      this.invalidos = data.filter((item) => !!item.error)

      this.cuentas = this.data.reduce((cuentas, movimiento) => {
        if (cuentas.indexOf(movimiento.cuenta) < 0) cuentas.push(movimiento.cuenta)

        return cuentas
      }, [])

      this.origen = origen

      this.ordenar()
    } else {
      this.cuentas = null
      this.data = null
      this.invalidos = null
      this.origen = null
    }
  }

  ordenar() {
    this.data = this.orderBy(this.data, ['empresa', 'cuenta', '-fecha', this.invertirOrden ? 'id' : '-id'])
  }

  uiCanExit() {
    return (
      this.idLote !== null ||
      !Array.isArray(this.data) ||
      !this.data.length ||
      this.$window.confirm('Tiene movimientos no guardados, ¿desea salir de este módulo?')
    )
  }
}

module.component('financesImportarmovimientos', {
  controller: FinancesImportarmovimientosComponent,
  template,
})
