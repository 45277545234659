import module from '@finances/empresas/module'
import template from './template.html'

export class FinancesPosicionbancariaComponent {
  constructor(FinancesPosicionbancariaService) {
    this.FinancesPosicionbancariaService = FinancesPosicionbancariaService
  }

  $onInit() {
    this.promise = this.FinancesPosicionbancariaService.get().then((response) => (this.data = response.data))
  }
}

module.component('financesPosicionbancaria', {
  controller: FinancesPosicionbancariaComponent,
  template,
  bindings: {
    fechaCorte: '<',
    idCategoria: '<',
  },
})
