import module from '@finances/configuracion/module'

module.config(($stateProvider) => {
  $stateProvider.state('configuracion.formaspagos', {
    url: '/formas_pago',
    data: { title: 'Formas de pago' },
    views: {
      '@': 'financesConfiguracionFormaspagos',
    },
  })
})
