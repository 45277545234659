import module from '@finances/partida/module'

export const financesPartidaSchema = {
  autorizacion: {
    estatus: 'boolean',
  },
  captura: {
    fecha: 'datetime',
  },
  eliminado: 'boolean',
  estatus: {
    fecha: 'date',
  },
  id_empresa: 'number',
  informacion: {
    fecha: 'date',
    importe: 'number',
  },
  modificado: 'boolean',
}

export class FinancesPartidaService {
  constructor($http) {
    this.$http = $http
  }

  get(idEmpresa, idCuenta, idPartida) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/partida/${idPartida}`, {
      schema: financesPartidaSchema,
    })
  }

  delete(idEmpresa, idCuenta, idPartida) {
    return this.$http.delete(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/partida/${idPartida}`)
  }
}

module.service('FinancesPartidaService', FinancesPartidaService)
