import module from '@finances/configuracion/module'
import template from './template.html'
import './test/component.js'
import './tester/component.js'

export class FinancesConfiguracionClasificacionmovimientosReglaComponent {
  constructor(
    $q,
    FinancesCatalogosTiposMovimientoService,
    FinancesCatalogosBancosService,
    FinancesCatalogosMonedasService,
    FinancesCatalogosCuentasService,
    FinancesCatalogosCategoriasCuentaService,
    FinancesConfiguracionClasificacionmovimientosService,
    FinancesConfiguracionClasificacionmovimientosReglaService,
  ) {
    this.$q = $q
    this.FinancesCatalogosTiposMovimientoService = FinancesCatalogosTiposMovimientoService
    this.FinancesCatalogosBancosService = FinancesCatalogosBancosService
    this.FinancesCatalogosMonedasService = FinancesCatalogosMonedasService
    this.FinancesCatalogosCuentasService = FinancesCatalogosCuentasService
    this.FinancesCatalogosCategoriasCuentaService = FinancesCatalogosCategoriasCuentaService
    this.FinancesConfiguracionClasificacionmovimientosService = FinancesConfiguracionClasificacionmovimientosService
    this.FinancesConfiguracionClasificacionmovimientosReglaService =
      FinancesConfiguracionClasificacionmovimientosReglaService
    this.catalogos = {
      campos: [
        { id: 'id_moneda', descripcion: 'Moneda' },
        { id: 'id_banco', descripcion: 'Banco' },
        { id: 'id_cuenta', descripcion: 'Cuenta' },
        { id: 'id_categoria', descripcion: 'Categoria' },
        { id: 'naturaleza', descripcion: 'Naturaleza' },
        { id: 'notas', descripcion: 'Notas' },
      ],
      coincide: [
        { id: true, descripcion: 'Coincide' },
        { id: false, descripcion: 'No coincide' },
      ],
      naturaleza: [
        { id: 'C', descripcion: 'Cargo' },
        { id: 'A', descripcion: 'Abono' },
      ],
    }
    this.error = null
    this.modificado = false
    this.procesando = false
    this.idRegla = undefined
    this.orden = undefined
    this.onChange = undefined
    this.onClose = undefined
    this.financesConfiguracionClasificacionmovimientosReglaTester = undefined
    this.sortableConfig = {
      animation: 150,
      handle: '.finances-configuracion-clasificacionmovimientos-regla-test__handle',
      ghostClass: 'finances-configuracion-clasificacionmovimientos-regla-test--ghost',
      onEnd: () => (this.modificado = true),
    }
  }

  $onInit() {
    const requests = [
      this.FinancesCatalogosTiposMovimientoService.get().then(
        (response) => (this.catalogos.tiposMovimiento = response.data),
      ),
      this.FinancesCatalogosBancosService.get().then(
        (response) =>
          (this.catalogos.bancos = response.data.map((item) => ({
            id: String(item.id),
            descripcion: item.descripcion,
          }))),
      ),
      this.FinancesCatalogosMonedasService.get().then(
        (response) =>
          (this.catalogos.monedas = response.data.map((item) => ({
            id: String(item.id),
            descripcion: item.descripcion,
          }))),
      ),
      this.FinancesCatalogosCuentasService.get().then(
        (response) =>
          (this.catalogos.cuentas = response.data.map((item) => ({
            id: String(item.id),
            descripcion: item.descripcion,
          }))),
      ),
      this.FinancesCatalogosCategoriasCuentaService.get().then(
        (response) =>
          (this.catalogos.categorias = response.data.map((item) => ({
            id: String(item.id),
            descripcion: item.descripcion,
          }))),
      ),
    ]

    if (this.idRegla) {
      requests.push(
        this.FinancesConfiguracionClasificacionmovimientosReglaService.get(this.idRegla).then(
          (response) => (this.data = response.data),
        ),
      )
    } else {
      this.modificado = true
      this.data = {
        tests: [],
        descripcion: '',
      }
    }

    this.promise = this.$q.all(requests)
  }

  actualizar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.FinancesConfiguracionClasificacionmovimientosReglaService.put(this.idRegla, this.data)
      .then((response) => {
        this.onChange({ $event: { data: response.data } })
        this.onClose()
      })
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  agregar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.FinancesConfiguracionClasificacionmovimientosService.post(this.data)
      .then((response) => this.onClose({ $event: { data: response.data } }))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  agregarTest() {
    this.modificado = true
    this.data.tests.push({
      campo: 'notas',
      coincide: true,
      valor: undefined,
    })
  }

  cerrar() {
    this.onClose()
  }

  eliminarTest(item) {
    this.modificado = true
    this.data.tests.splice(this.data.tests.indexOf(item), 1)
  }

  test() {
    this.financesConfiguracionClasificacionmovimientosReglaTester.open().then((ejemplo) => (this.ejemplo = ejemplo))
  }
}

module.component('financesConfiguracionClasificacionmovimientosRegla', {
  controller: FinancesConfiguracionClasificacionmovimientosReglaComponent,
  template,
  bindings: {
    idRegla: '<',
    orden: '<',
    onChange: '&',
    onClose: '&',
  },
})
