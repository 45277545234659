import module from '@finances/partidas/module'
import template from './template.html'

export class FinancesPartidasProteccionChequesExportarAgrupacionComponent {
  agrupacion = ''

  data = []

  seleccionado = false

  indeterminado = false

  $onChanges(changes) {
    if (changes.data) {
      this.regenerarEstatus()
    }
  }

  seleccionarTodos() {
    this.onSelect({ $event: { data: { seleccionado: !this.seleccionado } } })
  }

  seleccionar() {
    this.regenerarEstatus()
    this.onSelect()
  }

  regenerarEstatus() {
    const total = this.data.length
    const seleccionados = this.data.filter((item) => item.seleccionado).length
    this.seleccionado = total === seleccionados
    this.indeterminado = seleccionados > 0 && seleccionados < total
  }
}

module.component('financesPartidasProteccionChequesExportarAgrupacion', {
  controller: FinancesPartidasProteccionChequesExportarAgrupacionComponent,
  template,
  bindings: {
    agrupacion: '<',
    data: '<',
    onSelect: '&',
  },
})
