import module from '@finances/importarpartidas/module'
import template from './template.html'

export class FinancesImportarpartidasGuardarComponent {
  constructor(FinancesImportarpartidasService) {
    this.data = undefined
    this.error = null
    this.importarpartidasService = FinancesImportarpartidasService
    this.modal = undefined
    this.procesando = false
    this.resultado = null
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    const data = this.data.map((item) => {
      return {
        beneficiario: item.beneficiario,
        cheque: item.cheque,
        comentarios: item.comentarios,
        fecha: item.fecha,
        id: item.id,
        id_beneficiario_empleado: item.id_beneficiario_empleado,
        id_beneficiario_empresa: item.id_beneficiario_empresa,
        id_cuenta: item.id_cuenta,
        id_tipo_beneficiario: item.id_tipo_beneficiario,
        id_tipo_movimiento: item.id_tipo_movimiento,
        id_leyenda: item.id_leyenda,
        id_forma_pago: item.id_forma_pago,
        importe: item.importe,
        status: item.status,
      }
    })

    this.importarpartidasService
      .post(data)
      .then((response) => (this.resultado = response.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    this.modal.close(this.resultado)
  }
}

module.component('financesImportarpartidasGuardar', {
  controller: FinancesImportarpartidasGuardarComponent,
  template,
  bindings: {
    data: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
