import module from '@finances/partida/module'
import template from './template.html'

export class FinancesPartidaEliminarComponent {
  constructor() {
    this.confirmar = undefined
    this.partidaComponent = undefined
  }

  eliminar() {
    this.confirmar.open().then((data) => {
      if (data && data.eliminado && this.partidaComponent) this.partidaComponent.eliminar()
    })
  }
}

module.component('financesPartidaEliminar', {
  controller: FinancesPartidaEliminarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPartida: '<',
    data: '<',
    onChange: '&',
  },
  require: {
    partidaComponent: '?^financesPartida',
  },
})
