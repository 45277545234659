import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresaCuentaPrestamoDocumentosDocumentoComponent {
  constructor(FinancesEmpresaCuentaPrestamoDocumentoService) {
    this.FinancesEmpresaCuentaPrestamoDocumentoService = FinancesEmpresaCuentaPrestamoDocumentoService
    this.data = undefined
    this.onDelete = undefined
    this.eliminando = false
    this.src = null
    this.eliminarConfirmar = undefined
  }

  abrir() {
    this.src = this.FinancesEmpresaCuentaPrestamoDocumentoService.get(
      this.data.id_empresa,
      this.data.id_cuenta,
      this.data.id_prestamo,
      this.data.indice,
    )
  }

  eliminar() {
    this.eliminarConfirmar.open().then((data) => {
      this.eliminando = false

      if (data) {
        this.onDelete({ $event: { data } })
      }
    })
  }
}

module.component('financesEmpresaCuentaPrestamoDocumentosDocumento', {
  controller: FinancesEmpresaCuentaPrestamoDocumentosDocumentoComponent,
  template,
  bindings: {
    data: '<',
    onDelete: '&',
  },
})
