import module from '@finances/partidas/module'
import template from './template.html'

export class FinancesPartidasProteccionChequesExportarListaComponent {
  data = []

  lista = []

  $onChanges(changes) {
    if (changes.opciones) {
      this.filtrar()
    }

    if (changes.data) {
      this.data = [...this.data]
      this.regenerarEstatus()
    }
  }

  filtrar() {
    const filtro = this.opciones.filtro.toLowerCase()
    const empresaId = this.opciones.empresaId
    const bancoId = this.opciones.bancoId
    const monedaId = this.opciones.monedaId

    this.lista = this.data.filter(({ empresa, banco, moneda, busqueda }) => {
      return (
        (!empresaId || empresa.id === empresaId) &&
        (!bancoId || banco.id === bancoId) &&
        (!monedaId || moneda.id === monedaId) &&
        (!filtro || busqueda.includes(filtro))
      )
    })

    this.regenerarEstatus()
  }

  ordenar() {
    this.opciones.ordenar = [`${this.opciones.desc ? '-' : '+'}${this.opciones.orden}`, 'cheque']
  }

  seleccionarTodos(seleccionado = null, agrupacion = null) {
    if (seleccionado !== null) {
      this.lista = this.lista.map((item) => {
        if (agrupacion) {
          if (item[this.opciones.agrupacion] === agrupacion) {
            item.seleccionado = seleccionado
          }
        }
        if (!agrupacion) {
          item.seleccionado = this.seleccionado
        }

        return item
      })
    }

    this.regenerarEstatus()
    this.onSelect()
  }

  seleccionar() {
    this.regenerarEstatus()
    this.onSelect()
  }

  regenerarEstatus() {
    const total = this.lista.length
    const seleccionados = this.lista.filter((item) => item.seleccionado).length
    this.seleccionado = total > 0 && total === seleccionados
    this.indeterminado = seleccionados > 0 && seleccionados < total
  }
}

module.component('financesPartidasProteccionChequesExportarLista', {
  controller: FinancesPartidasProteccionChequesExportarListaComponent,
  template,
  bindings: {
    data: '<',
    seleccionados: '<',
    opciones: '<',
    catalogo: '<',
    onSelect: '&',
  },
})
