import module from '@finances/empresas/module'
import template from './template.html'

class FinancesEmpresaCuentaPrestamoDocumentosComponent {
  constructor() {
    this.financesEmpresaCuentaPrestamoDocumentosAgregar = undefined
    this.data = undefined
  }

  agregar() {
    this.financesEmpresaCuentaPrestamoDocumentosAgregar.open().then((data) => {
      if (data) {
        this.data.push(data)
      }
    })
  }

  eliminar(data) {
    this.data.splice(this.data.indexOf(data), 1)
  }
}

module.component('financesEmpresaCuentaPrestamoDocumentos', {
  controller: FinancesEmpresaCuentaPrestamoDocumentosComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPrestamo: '<',
    data: '<',
  },
})
