import module from '@finances/importarmovimientos/module'
import template from './template.html'

export class FinancesImportarmovimientosHistorialEliminarComponent {
  constructor(FinancesImportarmovimientosHistorialLoteService) {
    this.loteService = FinancesImportarmovimientosHistorialLoteService
    this.completado = false
    this.data = undefined
    this.error = null
    this.modal = undefined
    this.procesando = false
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.loteService
      .delete(this.data.id_lote)
      .then(() => (this.completado = true))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    this.modal.close({ eliminado: this.completado })
  }
}

module.component('financesImportarmovimientosHistorialEliminar', {
  controller: FinancesImportarmovimientosHistorialEliminarComponent,
  template,
  bindings: {
    data: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
