import module from '@finances/partidas/module'

export class FinancesPartidasProteccionChequesPendientesService {
  schema = {
    empresa: {
      id: 'number',
    },
    moneda: {
      id: 'number',
    },
    fecha: 'date',
    cheque: 'number',
    importe: 'number',
  }

  constructor($filter, $http) {
    this.$filterDate = $filter('date')
    this.$http = $http
  }

  get() {
    return this.$http.get('api/partidas/proteccion-cheques/pendientes', {
      schema: this.schema,
    })
  }

  post(idEmpresa, idCuenta, data) {
    return this.$http.post('api/partidas', data, {
      params: {
        id_empresa: idEmpresa,
        id_cuenta: idCuenta,
      },
      schema: this.schema,
    })
  }
}

module.service('FinancesPartidasProteccionChequesPendientesService', FinancesPartidasProteccionChequesPendientesService)
