import module from '@finances/partida/module'

export const financesPartidaOrigenSchema = {
  id_empresa: 'number',
}

export class FinancesPartidaOrigenService {
  constructor($http) {
    this.$http = $http
  }

  get(idEmpresa, idCuenta, idPartida) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/partida/${idPartida}/origen`, {
      schema: financesPartidaOrigenSchema,
    })
  }

  put(idEmpresa, idCuenta, idPartida, data) {
    return this.$http.put(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/partida/${idPartida}/origen`, data, {
      schema: financesPartidaOrigenSchema,
    })
  }
}

module.service('FinancesPartidaOrigenService', FinancesPartidaOrigenService)
