import module from '@finances/empresas/module'
import template from './template.html'

export class FinancesEmpresasComponent {
  constructor(FinancesEmpresasService) {
    this.FinancesEmpresasService = FinancesEmpresasService
    this.data = null
  }

  $onInit() {
    this.promise = this.FinancesEmpresasService.get().then((response) => (this.data = response.data))
  }
}

module.component('financesEmpresas', {
  controller: FinancesEmpresasComponent,
  template,
})
