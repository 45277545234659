import module from '@finances/importarmovimientos/module'
import template from './template.html'

export class FinancesImportarmovimientosGuardarComponent {
  constructor(FinancesImportarmovimientosService) {
    this.importarmovimientoService = FinancesImportarmovimientosService
    this.data = undefined
    this.error = null
    this.modal = undefined
    this.procesando = false
    this.resultado = undefined
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    const data = this.data.map((item) => ({
      fecha: item.fecha,
      id: item.id,
      id_banco: item.id_banco,
      id_cuenta: item.id_cuenta,
      id_cuenta_kardex: item.id_cuenta_kardex,
      id_empresa: item.id_empresa,
      id_forma_pago: item.id_forma_pago,
      id_moneda: item.id_moneda,
      id_tipo_movimiento: item.id_tipo_movimiento,
      importe: item.importe,
      naturaleza: item.naturaleza,
      notas: item.notas,
      numero_cuenta: item.numero_cuenta,
      referencia: item.referencia,
    }))

    // let chunks = Array(Math.ceil(data.length / chunkSize)).fill().map((_, i) => data.slice(i * chunkSize, i * chunkSize + chunkSize));

    this.importarmovimientoService
      .post(data)
      .then((response) => {
        this.completado = true
        this.fecha = new Date()
        this.resultado = response.data
        this.partidasVinculadas = this.resultado.movimientos.reduce(
          (total, item) => total + (item.id_partida ? 1 : 0),
          0,
        )
      })
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    this.modal.close(this.resultado)
  }
}

module.component('financesImportarmovimientosGuardar', {
  controller: FinancesImportarmovimientosGuardarComponent,
  template,
  bindings: {
    data: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
