import angular from 'angular'
import template from './template.html'

export class FinancesEmpresaCuentaMovimientoDocumentoEliminarComponent {
  constructor(FinancesEmpresaCuentaMovimientoDocumentoService) {
    this.documentoService = FinancesEmpresaCuentaMovimientoDocumentoService
    this.eliminando = false
  }

  eliminar() {
    if (this.eliminando) {
      return
    }

    this.eliminando = true

    this.documentoService
      .delete(this.idEmpresa, this.idCuenta, this.idMovimiento, this.idDocumento)
      .then((response) => this.cerrar(response.data))
      .finally(() => (this.eliminando = false))
  }

  cerrar(data) {
    this.modal.close(data)
  }
}

angular.module('finances.empresa').component('financesEmpresaCuentaMovimientoDocumentoEliminar', {
  controller: FinancesEmpresaCuentaMovimientoDocumentoEliminarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idMovimiento: '<',
    idDocumento: '<',
    archivoNombre: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
