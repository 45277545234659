import module from '@finances/partidas/module'
import template from './template.html'

export class FinancesPartidasAgregarGastoComponent {
  constructor(
    FinancesCatalogosAreasService,
    FinancesCatalogosCategoriasGastoService,
    FinancesCatalogosTiposGastoService,
    FinancesCatalogosSucursalesService,
    FinancesCatalogosPuestosService,
  ) {
    FinancesCatalogosAreasService.get().then((response) => (this.areas = response.data))

    FinancesCatalogosCategoriasGastoService.get().then((response) => (this.categoriasGasto = response.data))

    FinancesCatalogosTiposGastoService.get().then((response) => (this.tiposGasto = response.data))

    FinancesCatalogosSucursalesService.get().then((response) => (this.sucursales = response.data))

    FinancesCatalogosPuestosService.get().then((response) => (this.puestos = response.data))
  }

  cancelar() {
    this.onClose()
  }

  onClose() {}
}

module.component('financesPartidasAgregarGasto', {
  controller: FinancesPartidasAgregarGastoComponent,
  template,
  bindings: {
    data: '<',
    onClose: '&',
  },
})
