import module from '@finances/partida/module'
import template from './template.html'

export class FinancesPartidaBeneficiarioEditarComponent {
  constructor($q, FinancesCatalogosTiposBeneficiarioService, FinancesPartidaBeneficiarioService) {
    this.$q = $q
    this.tiposBeneficiarioService = FinancesCatalogosTiposBeneficiarioService
    this.beneficiarioService = FinancesPartidaBeneficiarioService
    this.data = undefined
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idPartida = undefined
    this.procesando = false
  }

  $onInit() {
    this.promise = this.$q.all([
      this.tiposBeneficiarioService.get().then((response) => (this.tiposBeneficiario = response.data)),
      this.beneficiarioService
        .get(this.idEmpresa, this.idCuenta, this.idPartida)
        .then((response) => (this.data = response.data)),
    ])
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    return this.beneficiarioService
      .put(this.idEmpresa, this.idCuenta, this.idPartida, this.data)
      .then((response) => this.onClose({ $event: { data: response.data } }))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    this.onClose()
  }

  onClose() {}
}

module.component('financesPartidaBeneficiarioEditar', {
  controller: FinancesPartidaBeneficiarioEditarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPartida: '<',
    onChange: '&',
    onClose: '&',
  },
})
