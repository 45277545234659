import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresaCuentaMovimientoEliminarConfirmarComponent {
  constructor(FinancesEmpresaCuentaMovimientoService) {
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idPartida = undefined
    this.data = undefined
    this.modal = undefined
    this.FinancesEmpresaCuentaMovimientoService = FinancesEmpresaCuentaMovimientoService
    this.eliminado = false
    this.error = null
    this.procesando = false
  }

  eliminar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.FinancesEmpresaCuentaMovimientoService.delete(this.idEmpresa, this.idCuenta, this.idPartida)
      .then((response) => {
        this.eliminado = response.data && response.data.eliminado

        if (!this.eliminado) {
          this.error = 'No se pudo eliminar el movimiento'
        }
      })
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    this.modal.close({ eliminado: this.eliminado })
  }
}

module.component('financesEmpresaCuentaMovimientoEliminarConfirmar', {
  controller: FinancesEmpresaCuentaMovimientoEliminarConfirmarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPartida: '<',
    data: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
