import module from '@finances/partida/module'
import template from './template.html'

export class FinancesPartidaImprimirComponent {}

module.component('financesPartidaImprimir', {
  controller: FinancesPartidaImprimirComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPartida: '<',
    cheque: '<',
  },
})
