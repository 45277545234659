import module from '@finances/importarpartidas/module'
import template from './template.html'
import { isDate } from 'angular'

export class FinancesImportarpartidasHistorialListaComponent {
  constructor(FinancesImportarpartidasHistorialService) {
    this.FinancesImportarpartidasHistorialService = FinancesImportarpartidasHistorialService
    this.data = null
    this.fecha = undefined
  }

  $onChanges(changes) {
    if (changes.fecha) {
      this.data = null

      if (isDate(this.fecha)) this.cargarHistorial()
    }
  }

  cargarHistorial() {
    this.promise = this.FinancesImportarpartidasHistorialService.get(this.fecha).then(
      (response) => (this.data = response.data),
    )
  }

  eliminar(item) {
    this.data = this.data.filter((elem) => elem !== item)
  }
}

module.component('financesImportarpartidasHistorialLista', {
  controller: FinancesImportarpartidasHistorialListaComponent,
  template,
  bindings: {
    fecha: '<',
  },
})
