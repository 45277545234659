import module from '@finances/partidas/module'

export class FinancesPartidasPredeterminadoService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      fecha: 'date',
      gasto: {
        factura: 'number',
      },
      importe: 'number',
      id_empresa: 'number',
      id_leyenda: 'number',
      id_tipo_beneficiario: 'number',
      id_tipo_movimiento: 'number',
      numero_cheque: 'number',
    }
  }

  get() {
    return this.$http.get('api/partidas/predeterminado', {
      schema: this.schema,
    })
  }
}

module.service('FinancesPartidasPredeterminadoService', FinancesPartidasPredeterminadoService)
