import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresaCuentaConfiguracionComponent {
  constructor(
    $q,
    $state,
    $window,
    FinancesCatalogosBancosService,
    FinancesCatalogosCategoriasCuentaService,
    FinancesCatalogosMonedasService,
    FinancesCatalogosTiposCuentaService,
    FinancesEmpresaCuentaConfiguracionService,
    FinancesEmpresaCuentasExisteService,
  ) {
    this.form = undefined
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.$q = $q
    this.$state = $state
    this.$window = $window
    this.catalogos = {}
    this.FinancesCatalogosBancosService = FinancesCatalogosBancosService
    this.FinancesCatalogosCategoriasCuentaService = FinancesCatalogosCategoriasCuentaService
    this.FinancesCatalogosMonedasService = FinancesCatalogosMonedasService
    this.FinancesCatalogosTiposCuentaService = FinancesCatalogosTiposCuentaService
    this.FinancesEmpresaCuentaConfiguracionService = FinancesEmpresaCuentaConfiguracionService
    this.FinancesEmpresaCuentasExisteService = FinancesEmpresaCuentasExisteService
    this.error = null
    this.procesando = false
  }

  $onInit() {
    this.promise = this.$q
      .all([
        this.FinancesEmpresaCuentaConfiguracionService.get(this.idEmpresa, this.idCuenta).then(
          (response) => (this.data = response.data),
        ),
        this.FinancesCatalogosBancosService.get().then((response) => (this.catalogos.bancos = response.data)),
        this.FinancesCatalogosCategoriasCuentaService.get().then(
          (response) => (this.catalogos.categoriasCuenta = response.data),
        ),
        this.FinancesCatalogosMonedasService.get().then((response) => (this.catalogos.monedas = response.data)),
        this.FinancesCatalogosTiposCuentaService.get().then((response) => (this.catalogos.tiposCuenta = response.data)),
      ])
      .finally(() => (this.numeroCuentaOriginal = this.data && this.data.numero_cuenta))
  }

  actualizar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.FinancesEmpresaCuentaConfiguracionService.put(this.idEmpresa, this.idCuenta, this.data)
      .then(() => this.form && this.form.$setPristine())
      .then(() => this.$state.go('empresa.cuenta.informacion'))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    if (this.procesando) return

    this.$state.go('empresa.cuenta.informacion')
  }

  validarCuenta(numeroCuenta) {
    if (!numeroCuenta) return

    if (this.numeroCuentaOriginal === numeroCuenta) return this.$q.resolve()

    return this.FinancesEmpresaCuentasExisteService.get(this.idEmpresa, numeroCuenta).then((response) =>
      response.data.length ? this.$q.reject() : this.$q.resolve(),
    )
  }

  uiCanExit() {
    return !this.form || this.form.$pristine || this.$window.confirm('Ha realizado cambios, ¿desea salir?')
  }
}

module.component('financesEmpresaCuentaConfiguracion', {
  controller: FinancesEmpresaCuentaConfiguracionComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
  },
})
