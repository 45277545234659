import module from '@finances/empresa/module'

export class FinancesEmpresaCuentaDocumentosService {
  constructor($http, $window) {
    this.$http = $http
    this.$window = $window
  }

  get(idEmpresa, idCuenta) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/documentos`)
  }

  post(idEmpresa, idCuenta, data) {
    const formData = new this.$window.FormData()
    formData.set('id_tipo_documento', data.id_tipo_documento)
    formData.set('file', data.file)

    return this.$http.post(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/documentos`, formData)
  }
}

module.service('FinancesEmpresaCuentaDocumentosService', FinancesEmpresaCuentaDocumentosService)
