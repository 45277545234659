import module from '@finances/partida/module'

export const financesPartidaAutorizacionSchema = {
  estatus: 'boolean',
}

export class FinancesPartidaAutorizacionService {
  constructor($http) {
    this.$http = $http
  }

  put(idEmpresa, idCuenta, idPartida, data) {
    return this.$http.put(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/partida/${idPartida}/autorizacion`, data, {
      schema: financesPartidaAutorizacionSchema,
    })
  }
}

module.service('FinancesPartidaAutorizacionService', FinancesPartidaAutorizacionService)
