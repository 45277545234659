import module from '@finances/clasificarmovimientos/module'

export const FinancesClasificarmovimientosConfiguracionSchema = {
  id: 'number',
  data: {
    id_tipo_movimiento: 'number',
  },
  ultimo_uso: 'date',
  tests: {
    coincide: 'boolean',
  },
}

export class FinancesClasificarmovimientosConfiguracionService {
  constructor($http) {
    this.$http = $http
  }

  get() {
    return this.$http.get(`api/clasificar_movimientos/configuracion`, {
      schema: FinancesClasificarmovimientosConfiguracionSchema,
    })
  }

  put(data) {
    return this.$http.put(`api/clasificar_movimientos/configuracion`, data, {
      schema: FinancesClasificarmovimientosConfiguracionSchema,
    })
  }
}

module.service('FinancesClasificarmovimientosConfiguracionService', FinancesClasificarmovimientosConfiguracionService)
