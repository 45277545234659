import module from '@finances/empresa/module'
import template from './template.html'
import orderBy from 'lodash-es/orderBy.js'

export class FinancesEmpresaCuentaComponent {
  constructor($state, FinancesEmpresaCuentaService, FinancesEmpresaCuentasService) {
    this.$state = $state
    this.FinancesEmpresaCuentaService = FinancesEmpresaCuentaService
    this.FinancesEmpresaCuentasService = FinancesEmpresaCuentasService
    this.cuentas = null
    this.seleccionando = false
    this.idEmpresa = undefined
    this.idCuenta = undefined
  }

  $onInit() {
    this.promise = this.FinancesEmpresaCuentaService.get(this.idEmpresa, this.idCuenta).then(
      (response) => (this.data = response.data),
    )
  }

  seleccionar() {
    this.seleccionando = !this.seleccionando

    if (this.seleccionando && !this.cuentas) {
      this.FinancesEmpresaCuentasService.get(this.idEmpresa).then((response) => {
        this.cuentas = orderBy(response.data, ['banco', 'moneda', 'cuenta'])
      })
    }
  }

  cambiarCuenta(idEmpresa, idCuenta) {
    this.$state.go(this.$state.current, { id_empresa: idEmpresa, id_cuenta: idCuenta })
  }
}

module.component('financesEmpresaCuenta', {
  controller: FinancesEmpresaCuentaComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
  },
})
