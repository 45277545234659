import module from '@finances/configuracion/module'

export const FinancesConfiguracionSchema = {}

export class FinancesConfiguracionService {
  constructor($http) {
    this.$http = $http
  }

  get() {
    return this.$http.get('api/configuracion', {
      schema: FinancesConfiguracionSchema,
    })
  }
}

module.service('FinancesConfiguracionService', FinancesConfiguracionService)
