import module from '@finances/clasificarmovimientos/module'
import template from './template.html'

export class FinancesClasificarmovimientosMovimientoComponent {
  constructor() {
    this.data = undefined
    this.parent = undefined
    this.procesando = false
  }

  $onInit() {
    this.catalogos = {
      tiposMovimiento: this.parent.tiposMovimiento,
    }
  }

  actualizar() {
    this.data.id_match = null
    this.data.modificado = true
    this.data.pendiente = !this.data.id_tipo_movimiento

    this.onChange({ $event: { data: this.data } })
  }

  onChange() {}
}

module.component('financesClasificarmovimientosMovimiento', {
  controller: FinancesClasificarmovimientosMovimientoComponent,
  template,
  bindings: {
    data: '<',
    onChange: '&',
  },
  require: {
    parent: '^financesClasificarmovimientos',
  },
})
