import module from '@finances/partida/module'
import template from './template.html'

export class FinancesPartidaComponent {
  constructor(FinancesPartidaService) {
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idPartida = undefined
    this.FinancesPartidaService = FinancesPartidaService
  }

  $onInit() {
    this.promise = this.FinancesPartidaService.get(this.idEmpresa, this.idCuenta, this.idPartida).then(
      (response) => (this.data = response.data),
    )
  }

  actualizar(item, data) {
    Object.assign(item, data)

    this.onChange({ $event: { data: this.data } })
  }

  eliminar() {
    this.onDelete({ $event: { data: this.data } })
  }

  onChange() {}

  onDelete() {}
}

module.component('financesPartida', {
  controller: FinancesPartidaComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPartida: '<',
    onChange: '&',
    onDelete: '&',
  },
})
