import angular from 'angular'

export class FinancesEmpresaCuentasService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      id_moneda: 'number',
      activo: 'boolean',
    }
  }

  get(idEmpresa) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuentas`, {
      schema: this.schema,
    })
  }

  post(idEmpresa, data) {
    return this.$http.post(`api/empresa/${idEmpresa}/cuentas`, data, {
      schema: this.schema,
    })
  }
}

angular.module('finances.empresa').service('FinancesEmpresaCuentasService', FinancesEmpresaCuentasService)
