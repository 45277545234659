import module from '@finances/empresa/module'
import template from './template.html'
import './style.scss'

class FinancesEmpresaCuentaMovimientosListaItemComponent {
  constructor() {
    this.indice = undefined
    this.data = undefined
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idMovimiento = undefined
    this.onDelete = undefined
    this.onChange = undefined
    this.editando = false
    this.eliminado = false
  }

  eliminar() {
    this.eliminado = true
    this.editando = false

    this.onDelete()
  }

  actualizar(data) {
    this.onChange({ $event: { data } })
  }
}

module.component('financesEmpresaCuentaMovimientosListaItem', {
  controller: FinancesEmpresaCuentaMovimientosListaItemComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idMovimiento: '<',
    data: '<',
    color: '<',
    onDelete: '&',
    onChange: '&',
  },
})
