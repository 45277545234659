import module from '@finances/empresa/module'

export const financesEmpresaCuentaConfiguracionSchema = {
  id_categoria: 'number',
  id_moneda: 'number',
  id_tipo_cuenta: 'number',
  activo: 'boolean',
}

export class FinancesEmpresaCuentaConfiguracionService {
  constructor($http) {
    this.$http = $http
  }

  get(idEmpresa, idCuenta) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/configuracion`, {
      schema: financesEmpresaCuentaConfiguracionSchema,
    })
  }

  put(idEmpresa, idCuenta, data) {
    return this.$http.put(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/configuracion`, data, {
      schema: financesEmpresaCuentaConfiguracionSchema,
    })
  }
}

module.service('FinancesEmpresaCuentaConfiguracionService', FinancesEmpresaCuentaConfiguracionService)
