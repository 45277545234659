import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresaCuentaMovimientosEditarComponent {
  constructor($state) {
    this.$state = $state
    this.eliminado = false
  }

  cerrar() {
    this.$state.go('empresa.cuenta.movimientos')
  }

  eliminar() {
    this.eliminado = true
  }
}

module.component('financesEmpresaCuentaMovimientosEditar', {
  controller: FinancesEmpresaCuentaMovimientosEditarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idMovimiento: '<',
  },
})
