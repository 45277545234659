import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresaCuentaMovimientoComponent {
  constructor(FinancesEmpresaCuentaMovimientoService) {
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idMovimiento = undefined
    this.onChange = undefined
    this.onDelete = undefined
    this.movimientoService = FinancesEmpresaCuentaMovimientoService
  }

  $onInit() {
    this.promise = this.movimientoService
      .get(this.idEmpresa, this.idCuenta, this.idMovimiento)
      .then((response) => (this.data = response.data))
  }

  actualizar(data) {
    if (data) {
      this.data = data

      this.onChange({ $event: { data: this.data } })
    } else {
      this.onDelete()
    }
  }
}

module.component('financesEmpresaCuentaMovimiento', {
  controller: FinancesEmpresaCuentaMovimientoComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idMovimiento: '<',
    onChange: '&',
    onDelete: '&',
  },
})
