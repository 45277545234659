import module from '@finances/empresa/module'
import template from './template.html'

export class financesEmpresasAgregarConfirmarComponent {
  constructor(FinancesEmpresasService) {
    this.data = undefined
    this.FinancesEmpresasService = FinancesEmpresasService
    this.procesando = false
    this.idEmpresa = undefined
    this.modal = undefined
  }

  aceptar() {
    this.modal.close(this.idEmpresa)
  }

  agregar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.FinancesEmpresasService.post(this.data)
      .then((response) => (this.idEmpresa = response.data.id_empresa))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    this.modal.close()
  }
}

module.component('financesEmpresasAgregarConfirmar', {
  controller: financesEmpresasAgregarConfirmarComponent,
  template,
  bindings: {
    data: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
