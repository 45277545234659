import angular from 'angular'

export class FinancesEmpresaCuentaMovimientoDocumentoService {
  constructor($http, $api) {
    this.$http = $http
    this.$api = $api
  }

  get(idEmpresa, idCuenta, idMovimiento, idDocumento) {
    return this.$api.link(
      `api/empresa/${idEmpresa}/cuenta/${idCuenta}/movimiento/${idMovimiento}/documento/${idDocumento}`,
    )
  }

  delete(idEmpresa, idCuenta, idMovimiento, idDocumento) {
    return this.$http.delete(
      `api/empresa/${idEmpresa}/cuenta/${idCuenta}/movimiento/${idMovimiento}/documento/${idDocumento}`,
    )
  }
}

angular
  .module('finances.empresa')
  .service('FinancesEmpresaCuentaMovimientoDocumentoService', FinancesEmpresaCuentaMovimientoDocumentoService)
