import module from '@finances/clasificarmovimientos/module'

export class Matcher {
  constructor(reglas) {
    this.reglas = reglas.map((regla) => new Rule(regla.id, regla.data, regla.tests))
  }

  find(query) {
    return this.reglas.find((regla) => regla.test(query))
  }
}

export class Rule {
  constructor(id = null, data = {}, tests = []) {
    this.id = id
    this.data = data
    this.tests = tests.map((test) => {
      const regex = new RegExp(test.valor, 'i')

      return function (objeto) {
        if (!Object.hasOwn(objeto, test.campo)) return false

        let value = objeto[test.campo]

        if (typeof value === 'string') {
          value = value.replace(/[ \t\n\r][ \t\n\r]*/g, ' ').trim()
        }

        return regex.test(value) === test.coincide
      }
    })
  }

  test(query) {
    return this.tests.every((test) => test(query))
  }
}

export class FinancesClasificarmovimientosMatcherService {
  constructor(FinancesClasificarmovimientosConfiguracionService) {
    this.FinancesClasificarmovimientosConfiguracionService = FinancesClasificarmovimientosConfiguracionService
  }

  get() {
    return this.FinancesClasificarmovimientosConfiguracionService.get()
      .then((response) => new Matcher(response.data))
      .catch((response) => {
        throw new Error(response.data)
      })
  }
}

module.service('FinancesClasificarmovimientosMatcherService', FinancesClasificarmovimientosMatcherService)
