import module from '@finances/importarpartidas/module'
import template from './template.html'

export class FinancesImportarpartidasHistorialDetalladoComponent {
  constructor(FinancesImportarpartidasHistorialLoteService) {
    this.idLote = undefined
    this.loteService = FinancesImportarpartidasHistorialLoteService
  }

  $onInit() {
    this.promise = this.loteService.get(this.idLote).then((response) => (this.data = response.data))
  }

  cerrar() {
    this.onClose()
  }

  onClose() {}
}

module.component('financesImportarpartidasHistorialDetallado', {
  controller: FinancesImportarpartidasHistorialDetalladoComponent,
  template,
  bindings: {
    idLote: '<',
    titulo: '<',
    subtitulo: '<',
    capturaUsuario: '<',
    capturaFecha: '<',
    onClose: '&',
  },
})
