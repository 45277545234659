import module from '@finances/divisas/module'
import template from './template.html'

export class FinancesDivisasEditarComponent {
  constructor(FinancesDivisasFechaService) {
    this.fechaService = FinancesDivisasFechaService
    this.error = null
    this.procesando = false
    this.modal = undefined
    this.tipoCambio = undefined
    this.fecha = undefined
    this.idMoneda = 2
  }

  $onInit() {
    this.data = {
      tipo_cambio: this.tipoCambio,
    }
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.fechaService
      .put(this.idMoneda, this.fecha, this.data)
      .then((response) => this.cerrar(response.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar(data) {
    this.modal.close(data)
  }
}

module.component('financesDivisasEditar', {
  template,
  controller: FinancesDivisasEditarComponent,
  bindings: {
    fecha: '<',
    tipoCambio: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
