import module from '@finances/configuracion/module'
import template from './template.html'
import { Rule } from '@finances/clasificarmovimientos/services/Clasificarmovimientos/Matcher'

export class FinancesConfiguracionClasificacionmovimientosReglaTesterComponent {
  constructor() {
    this.catalogos = undefined
    this.ejemplo = undefined
    this.tests = undefined
    this.modal = undefined
  }

  $onInit() {
    this.tester = new Rule(null, {}, this.tests)

    this.test()
  }

  test() {
    if (!this.ejemplo) return

    this.aprobado = this.tester.test(this.ejemplo)
  }

  cerrar() {
    this.modal.close(this.ejemplo)
  }
}

module.component('financesConfiguracionClasificacionmovimientosReglaTester', {
  controller: FinancesConfiguracionClasificacionmovimientosReglaTesterComponent,
  template,
  bindings: {
    ejemplo: '<',
    catalogos: '<',
    tests: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
