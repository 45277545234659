import angular from 'angular'
import template from './template.html'

class FinancesEmpresaCuentaFinanciamientoGeneradorComponent {
  constructor() {
    this.catalogos = {
      plazo_tipo: [
        { id: 'weeks', descripcion: 'Semanas' },
        { id: 'quarters', descripcion: 'Quincenas' },
        { id: 'months', descripcion: 'Meses' },
      ],
    }

    this.plazoCantidad = undefined
    this.plazoTipo = 'months'
  }

  aceptar() {
    if (!(this.plazoCantidad > 0)) return

    let plazoFecha = this.plazoInicio
    const financiamiento = []

    for (let i = 1; i <= this.plazoCantidad; i++) {
      financiamiento.push({
        indice: i,
        fecha: plazoFecha,
        importe: parseFloat((this.importe / this.plazoCantidad).toFixed(2)),
        interes: parseFloat((this.interes / this.plazoCantidad).toFixed(2)),
        capital: parseFloat((this.capital / this.plazoCantidad).toFixed(2)),
        notas: '',
      })

      switch (this.plazoTipo) {
        case 'months':
          plazoFecha = new Date(plazoFecha.getFullYear(), plazoFecha.getMonth() + 1, plazoFecha.getDate())
          break

        case 'quarters':
          plazoFecha = new Date(plazoFecha.getFullYear(), plazoFecha.getMonth(), plazoFecha.getDate() + 14)
          break

        case 'weeks':
          plazoFecha = new Date(plazoFecha.getFullYear(), plazoFecha.getMonth(), plazoFecha.getDate() + 7)
          break
      }
    }

    this.cerrar(financiamiento)
  }

  cerrar(data) {
    this.modal.close(data)
  }
}

angular.module('finances.empresa').component('financesEmpresaCuentaFinanciamientoGenerador', {
  controller: FinancesEmpresaCuentaFinanciamientoGeneradorComponent,
  template,
  bindings: {
    importe: '<',
    interes: '<',
    capital: '<',
    plazoInicio: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
