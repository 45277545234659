import module from './module.js'
/* Components */
import './components/component.js'
import './components/cuenta/component.js'
import './components/cuenta/configuracion/component.js'
import './components/cuenta/documento/component.js'
import './components/cuenta/documento/eliminar/component.js'
import './components/cuenta/documentos/component.js'
import './components/cuenta/documentos/agregar/component.js'
import './components/cuenta/financiamiento/component.js'
import './components/cuenta/financiamiento/generador/component.js'
import './components/cuenta/financiamiento/tabla/component.js'
import './components/cuenta/informacion/component.js'
import './components/cuenta/informacion/firmantes/component.js'
import './components/cuenta/informacion/firmantes/editar/component.js'
import './components/cuenta/informacion/representante/component.js'
import './components/cuenta/informacion/representante/editar/component.js'
import './components/cuenta/movimiento/component.js'
import './components/cuenta/movimiento/documento/component.js'
import './components/cuenta/movimiento/documento/eliminar/component.js'
import './components/cuenta/movimiento/documentos/component.js'
import './components/cuenta/movimiento/eliminar/component.js'
import './components/cuenta/movimiento/eliminar/confirmar/component.js'
import './components/cuenta/movimiento/informacion/component.js'
import './components/cuenta/movimiento/informacion/editar/component.js'
import './components/cuenta/movimiento/metadata/component.js'
import './components/cuenta/movimiento/pago/component.js'
import './components/cuenta/movimiento/pago/editar/component.js'
import './components/cuenta/movimientos/component.js'
import './components/cuenta/movimientos/agregar/component.js'
import './components/cuenta/movimientos/editar/component.js'
import './components/cuenta/movimientos/exportar/component.js'
import './components/cuenta/movimientos/lista/item/component.js'
import './components/cuenta/prestamo/component.js'
import './components/cuenta/prestamo/documentos/component.js'
import './components/cuenta/prestamo/documentos/agregar/component.js'
import './components/cuenta/prestamo/documentos/documento/component.js'
import './components/cuenta/prestamo/documentos/eliminar/component.js'
import './components/cuenta/prestamo/informacion/component.js'
import './components/cuenta/prestamos/component.js'
import './components/cuenta/prestamos/agregar/component.js'
import './components/cuenta/prestamos/editar/component.js'
import './components/cuenta/prestamos/lista/component.js'
import './components/cuentas/component.js'
import './components/cuentas/agregar/component.js'
import './components/cuentas/agregar/confirmar/component.js'
import './components/cuentas/lista/component.js'
import './components/cuentas/lista/grupo/component.js'
/* Services */
import './services/Empresa.js'
import './services/Empresa/Cuenta.js'
import './services/Empresa/Cuenta/Configuracion.js'
import './services/Empresa/Cuenta/Documento.js'
import './services/Empresa/Cuenta/Documentos.js'
import './services/Empresa/Cuenta/Informacion.js'
import './services/Empresa/Cuenta/Informacion/Firmante.js'
import './services/Empresa/Cuenta/Informacion/Representante.js'
import './services/Empresa/Cuenta/Movimiento.js'
import './services/Empresa/Cuenta/Movimiento/Documento.js'
import './services/Empresa/Cuenta/Movimiento/Documento/Preview.js'
import './services/Empresa/Cuenta/Movimiento/Documentos.js'
import './services/Empresa/Cuenta/Movimiento/Informacion.js'
import './services/Empresa/Cuenta/Movimiento/Informacion/TipoMovimiento.js'
import './services/Empresa/Cuenta/Movimiento/Pago.js'
import './services/Empresa/Cuenta/Movimientos.js'
import './services/Empresa/Cuenta/Movimientos/Exportar.js'
import './services/Empresa/Cuenta/Prestamo.js'
import './services/Empresa/Cuenta/Prestamo/Documento.js'
import './services/Empresa/Cuenta/Prestamo/Documentos.js'
import './services/Empresa/Cuenta/Prestamos.js'
import './services/Empresa/Cuenta/Prestamos/Predeterminado.js'
import './services/Empresa/Cuentas.js'
import './services/Empresa/Cuentas/Existe.js'
/* Routes */
import './routes/empresa.js'
import './routes/empresa/cuenta.js'
import './routes/empresa/cuenta/configuracion.js'
import './routes/empresa/cuenta/documentos.js'
import './routes/empresa/cuenta/informacion.js'
import './routes/empresa/cuenta/movimientos.js'
import './routes/empresa/cuenta/movimientos/agregar.js'
import './routes/empresa/cuenta/movimientos/editar.js'
import './routes/empresa/cuenta/prestamos.js'
import './routes/empresa/cuenta/prestamos/agregar.js'
import './routes/empresa/cuenta/prestamos/editar.js'
import './routes/empresa/cuentas.js'
import './routes/empresa/cuentas/agregar.js'

export default module.name
