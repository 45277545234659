import module from '@finances/importarpartidas/module'

module.config(($stateProvider) => {
  $stateProvider.state('importarpartidas', {
    url: '/importar_partidas',
    component: 'financesImportarpartidas',
    data: {
      title: 'Importar partidas',
    },
  })
})
