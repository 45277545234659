import module from '@finances/empresa/module'

export const FinancesEmpresaCuentaMovimientoInformacionSchema = {
  capital: 'number',
  fecha: 'date',
  financiado: 'boolean',
  financiamiento: {
    indice: 'number',
    importe: 'number',
    interes: 'number',
    capital: 'number',
    fecha: 'date',
  },
  id_tipo_movimiento: 'number',
  valor: 'number',
  importe: 'number',
  interes: 'number',
  interes_porcentaje: 'number',
}

/**
 * @ngdoc service
 * @name FinancesEmpresaCuentaMovimientoInformacionService
 */
export class FinancesEmpresaCuentaMovimientoInformacionService {
  constructor($http) {
    this.$http = $http
  }

  /**
   * @memberof FinancesEmpresaCuentaMovimientoInformacionService
   */
  get(idEmpresa, idCuenta, idMovimiento) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/movimiento/${idMovimiento}/informacion`, {
      schema: FinancesEmpresaCuentaMovimientoInformacionSchema,
    })
  }

  /**
   * @memberof FinancesEmpresaCuentaMovimientoInformacionService
   */
  put(idEmpresa, idCuenta, idMovimiento, data) {
    return this.$http.put(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/movimiento/${idMovimiento}/informacion`, data, {
      schema: FinancesEmpresaCuentaMovimientoInformacionSchema,
    })
  }
}

module.service('FinancesEmpresaCuentaMovimientoInformacionService', FinancesEmpresaCuentaMovimientoInformacionService)
