import module from '@finances/empresa/module'

export class FinancesEmpresaCuentaMovimientoDocumentoPreviewService {
  constructor($api) {
    this.$api = $api
  }

  get(idEmpresa, idCuenta, idMovimiento, idDocumento) {
    return this.$api.link(
      `api/empresa/${idEmpresa}/cuenta/${idCuenta}/movimiento/${idMovimiento}/documento/${idDocumento}/preview`,
    )
  }
}

module.service(
  'FinancesEmpresaCuentaMovimientoDocumentoPreviewService',
  FinancesEmpresaCuentaMovimientoDocumentoPreviewService,
)
