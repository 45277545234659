import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresaCuentaMovimientoInformacionComponent {
  constructor() {
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idMovimiento = undefined
    this.data = undefined
    this.onChange = undefined
    this.editando = false
  }

  actualizar(data) {
    Object.assign(this.data.informacion, data)
    this.onChange({ $event: { data: this.data } })
  }
}

module.component('financesEmpresaCuentaMovimientoInformacion', {
  controller: FinancesEmpresaCuentaMovimientoInformacionComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idMovimiento: '<',
    data: '<',
    onChange: '&',
  },
})
