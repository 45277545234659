import module from '@finances/configuracion/module'
import template from './template.html'
import '@finances/configuracion/services/Configuracion/Formaspagos/Formapago'

export class FinancesConfiguracionFormaspagosEditarComponent {
  constructor(FinancesConfiguracionFormaspagosFormapagoService) {
    this.formaService = FinancesConfiguracionFormaspagosFormapagoService
    this.modal = undefined
    this.error = null
    this.procesando = false
    this.data = undefined
  }

  $onChanges(changes) {
    if (changes.data) {
      this.data = Object.assign({}, this.data)
    }
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.formaService
      .put(this.data.id, this.data)
      .then(() => this.modal.close(this.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cancelar() {
    this.modal.close()
  }
}

module.component('financesConfiguracionFormaspagosEditar', {
  controller: FinancesConfiguracionFormaspagosEditarComponent,
  template,
  bindings: {
    data: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
