import module from '@finances/partidas/module'

module.config(($stateProvider) => {
  $stateProvider.state('partidas.agregar', {
    url: '/agregar',
    data: {
      title: 'Agregar',
    },
    resolve: {
      idEmpresa: ($transition$) => $transition$.params().id_empresa,
      idCuenta: ($transition$) => $transition$.params().id_cuenta,
    },
    views: {
      '@': 'financesPartidasAgregar',
    },
  })
})
