import module from '@finances/importarpartidas/module'
import template from './template.html'

export class FinancesImportarpartidasHistorialEliminarComponent {
  constructor(FinancesImportarpartidasHistorialLoteService) {
    this.loteService = FinancesImportarpartidasHistorialLoteService
    this.completado = false
    this.data = undefined
    this.error = null
    this.modal = undefined
    this.procesando = false
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.loteService
      .delete(this.data.id_lote)
      .then(() => (this.completado = true))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    this.modal.close({ eliminado: this.completado })
  }
}

module.component('financesImportarpartidasHistorialEliminar', {
  controller: FinancesImportarpartidasHistorialEliminarComponent,
  template,
  bindings: {
    data: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
