import module from '@finances/partidas/module.js'
import template from './template.html'
import orderBy from 'lodash-es/orderBy.js'

export class FinancesPartidasComponent {
  constructor(
    $q,
    $location,
    FinancesCatalogosEstatusPartidasService,
    FinancesCatalogosEmpresasService,
    FinancesCatalogosCuentasService,
  ) {
    this.$q = $q
    this.$location = $location
    this.FinancesCatalogosEstatusPartidasService = FinancesCatalogosEstatusPartidasService
    this.FinancesCatalogosEmpresasService = FinancesCatalogosEmpresasService
    this.FinancesCatalogosCuentasService = FinancesCatalogosCuentasService
    this.cuentas = undefined
    this.empresas = undefined
    this.idEmpresa = undefined
  }

  $onChanges(changes) {
    if (changes.busqueda) {
      this.busqueda = {
        id_empresa: this.busqueda.id_empresa || undefined,
        id_cuenta: this.busqueda.id_cuenta || undefined,
        estatus: this.busqueda.estatus || 't',
        fecha_corte: this.busqueda.fecha_corte,
      }

      this.promise = this.$q.all([
        this.FinancesCatalogosEstatusPartidasService.get().then((response) => (this.estatusPartidas = response.data)),
        this.obtenerEmpresas(),
        this.obtenerCuentas(),
      ])
    }
  }

  buscar() {
    if (this.busqueda && this.busqueda.id_empresa && this.busqueda.id_cuenta) {
      this.$location.search({
        id_empresa: this.busqueda.id_empresa,
        id_cuenta: this.busqueda.id_cuenta,
        estatus: this.busqueda.estatus,
        fecha_corte: this.busqueda.fecha_corte && this.busqueda.fecha_corte.toJSON().slice(0, 10),
      })

      this.$location.replace()
    }
  }

  obtenerCuentas() {
    if (!this.busqueda.id_empresa) return null

    this.cuentas = undefined

    return this.FinancesCatalogosCuentasService.get(this.busqueda.id_empresa).then(
      (response) => (this.cuentas = orderBy(response.data, 'descripcion')),
    )
  }

  obtenerEmpresas() {
    return this.FinancesCatalogosEmpresasService.get(this.idEmpresa).then(
      (response) => (this.empresas = orderBy(response.data, 'descripcion')),
    )
  }

  seleccionarEmpresa() {
    this.busqueda.id_cuenta = undefined
    this.obtenerCuentas()
  }
}

module.component('financesPartidas', {
  controller: FinancesPartidasComponent,
  template,
  bindings: {
    busqueda: '<',
  },
})
