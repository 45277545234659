import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresaCuentasListaComponent {}

module.component('financesEmpresaCuentasLista', {
  controller: FinancesEmpresaCuentasListaComponent,
  template,
  bindings: {
    data: '<',
    filtro: '<',
  },
})
