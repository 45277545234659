import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresaCuentasAgregarComponent {
  constructor(
    $q,
    $state,
    FinancesCatalogosBancosService,
    FinancesCatalogosMonedasService,
    FinancesCatalogosFirmantesService,
    FinancesCatalogosTiposFirmanteService,
    FinancesEmpresaCuentasExisteService,
    FinancesCatalogosCategoriasCuentaService,
    FinancesCatalogosTiposCuentaService,
  ) {
    this.$q = $q
    this.$state = $state
    this.FinancesCatalogosBancosService = FinancesCatalogosBancosService
    this.FinancesCatalogosMonedasService = FinancesCatalogosMonedasService
    this.FinancesCatalogosFirmantesService = FinancesCatalogosFirmantesService
    this.FinancesCatalogosTiposFirmanteService = FinancesCatalogosTiposFirmanteService
    this.FinancesEmpresaCuentasExisteService = FinancesEmpresaCuentasExisteService
    this.FinancesCatalogosCategoriasCuentaService = FinancesCatalogosCategoriasCuentaService
    this.FinancesCatalogosTiposCuentaService = FinancesCatalogosTiposCuentaService
    this.agregando = false
    this.idEmpresa = undefined
    this.data = {
      clabe: '',
      cuenta_contable: '',
      cuenta_contable_complementaria: '',
      id_banco: undefined,
      id_categoria: undefined,
      id_moneda: undefined,
      id_tipo_cuenta: 1,
      numero_contrato: '',
      numero_cuenta: '',
      representante_legal: '',
      firmantes: {
        1: { id: null, tipo: null },
        2: { id: null, tipo: null },
        3: { id: null, tipo: null },
        4: { id: null, tipo: null },
      },
    }
    this.catalogos = {}
    this.confirmarAgregar = undefined
    this.validarCuenta = {
      cuenta: (numeroCuenta) => {
        if (!numeroCuenta) return

        return this.FinancesEmpresaCuentasExisteService.get(this.idEmpresa, numeroCuenta).then((response) =>
          response.data.length ? this.$q.reject() : this.$q.resolve(),
        )
      },
    }
  }

  $onInit() {
    this.promise = this.$q.all([
      this.FinancesCatalogosBancosService.get().then((response) => (this.catalogos.bancos = response.data)),
      this.FinancesCatalogosMonedasService.get().then((response) => (this.catalogos.monedas = response.data)),
      this.FinancesCatalogosFirmantesService.get().then((response) => (this.catalogos.personas = response.data)),
      this.FinancesCatalogosTiposFirmanteService.get().then(
        (response) => (this.catalogos.tiposFirmante = response.data),
      ),
      this.FinancesCatalogosCategoriasCuentaService.get().then(
        (response) => (this.catalogos.categoriasCuenta = response.data),
      ),
      this.FinancesCatalogosTiposCuentaService.get().then((response) => (this.catalogos.tiposCuenta = response.data)),
    ])
  }

  cerrar() {
    this.$state.go('empresa.cuentas')
  }

  agregar() {
    this.confirmarAgregar.open().then((idCuenta) => {
      if (idCuenta) {
        this.$state.go(
          'empresa.cuenta',
          {
            id_empresa: this.idEmpresa,
            id_cuenta: idCuenta,
          },
          { location: 'replace' },
        )
      }
    })
  }
}

module.component('financesEmpresaCuentasAgregar', {
  controller: FinancesEmpresaCuentasAgregarComponent,
  template,
  bindings: {
    idEmpresa: '<',
  },
})
