import module from '@finances/importarpartidas/module'
import template from './template.html'

export class FinancesImportarpartidasListaItemComponent {
  constructor() {
    this.catalofoFormaPago = {
      '01': 'Transferencia',
      '02': 'Cheque',
      '03': 'Efectivo',
      '04': 'Vales',
    }

    this.catalogoEstatus = {
      t: 'Tránsito',
    }
  }
}

module.component('financesImportarpartidasListaItem', {
  controller: FinancesImportarpartidasListaItemComponent,
  template,
  bindings: {
    data: '<',
  },
})
