import module from '@finances/importarpartidas/module'
import template from './template.html'

export class FinancesImportarpartidasHistorialListaItemComponent {
  constructor() {
    this.data = undefined
    this.onDelete = undefined
    this.confirmarEliminar = undefined
    this.editando = false
  }

  $onInit() {
    this.titulo =
      (this.data.bancos.length === 1 ? this.data.bancos[0] : this.data.bancos.length + ' bancos') +
      (this.data.cuentas.length === 1 ? ' ' + this.data.cuentas[0] : ', ' + this.data.cuentas.length + ' cuentas')

    this.subtitulo = this.data.partidas + ' partidas'
  }

  eliminar() {
    this.confirmarEliminar.open().then((data) => {
      if (data.eliminado) this.onDelete()
    })
  }
}

module.component('financesImportarpartidasHistorialListaItem', {
  controller: FinancesImportarpartidasHistorialListaItemComponent,
  template,
  bindings: {
    data: '<',
    onDelete: '&',
  },
})
