import module from '@finances/partidas/module'

module.config(($stateProvider) => {
  $stateProvider.state('partidas.editar', {
    url: '/editar?{id_partida}',
    data: {
      title: 'Editar',
    },
    resolve: {
      idEmpresa: ($transition$) => $transition$.params().id_empresa,
      idCuenta: ($transition$) => $transition$.params().id_cuenta,
      idPartida: ($transition$) => $transition$.params().id_partida,
    },
    views: {
      '@': 'financesPartidasEditar',
    },
  })
})
