import module from '@finances/empresa/module'

module.config(($stateProvider) => {
  $stateProvider.state('empresa', {
    url: '/empresa/{id_empresa}',
    data: { title: 'Empresa' },
    component: 'financesEmpresa',
    redirectTo: 'empresa.cuentas',
    resolve: {
      idEmpresa: ($transition$) => $transition$.params().id_empresa,
    },
  })
})
