import angular from 'angular'
import template from './template.html'

export class FinancesEmpresaCuentaFinanciamientoComponent {
  $onChanges(changes) {
    if (changes.data && (changes.data.currentValue !== this.data || !Array.isArray(this.data))) {
      this.data = Array.isArray(this.data) ? this.data.map((item) => Object.assign({}, item)) : []

      this.data.id = Math.random()

      this.actualizar()
    }
  }

  generarPagos() {
    this.generador.open().then((data) => {
      if (data) {
        data.forEach((item, index) => {
          item.notas = (this.data[index] && this.data[index].notas) || item.notas
          return item
        })

        this.data = data

        this.actualizar()
      }
    })
  }

  actualizar() {
    this.onChange({ $event: { data: this.data } })
  }
}

angular.module('finances.empresa').component('financesEmpresaCuentaFinanciamiento', {
  controller: FinancesEmpresaCuentaFinanciamientoComponent,
  template,
  bindings: {
    data: '<',
    fecha: '<',
    importe: '<',
    interes: '<',
    capital: '<',
    editable: '<',
    onChange: '&',
  },
})
