import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresaCuentaPrestamosEditarComponent {
  constructor($state, FinancesEmpresaCuentaPrestamoService) {
    this.$state = $state
    this.FinancesEmpresaCuentaPrestamoService = FinancesEmpresaCuentaPrestamoService
    this.procesando = false
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idPrestamo = undefined
    this.error = null
  }

  $onInit() {
    this.promise = this.FinancesEmpresaCuentaPrestamoService.get(this.idEmpresa, this.idCuenta, this.idPrestamo).then(
      (response) => (this.data = response.data),
    )
  }

  aceptar() {
    if (this.procesando) return

    this.procesando = true
    this.error = null

    this.FinancesEmpresaCuentaPrestamoService.put(this.idEmpresa, this.idCuenta, this.idPrestamo, this.data)
      .then(() => this.$state.go('empresa.cuenta.prestamos'))
      .catch((response) => (this.error = response.data)) // TODO: Mostrar error en interface
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    if (this.procesando) return

    this.$state.go('empresa.cuenta.prestamos')
  }
}

module.component('financesEmpresaCuentaPrestamosEditar', {
  controller: FinancesEmpresaCuentaPrestamosEditarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPrestamo: '<',
  },
})
