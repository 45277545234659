import module from './module.js'
/* Services */
import './services/Catalogos/Areas.js'
import './services/Catalogos/Bancos.js'
import './services/Catalogos/CategoriasCuenta.js'
import './services/Catalogos/CategoriasGasto.js'
import './services/Catalogos/Cuentas.js'
import './services/Catalogos/Empresas.js'
import './services/Catalogos/EstatusCheque.js'
import './services/Catalogos/EstatusPartidas.js'
import './services/Catalogos/FormasPago.js'
import './services/Catalogos/FormasPagoPartidas.js'
import './services/Catalogos/Leyendas.js'
import './services/Catalogos/Monedas.js'
import './services/Catalogos/Firmantes.js'
import './services/Catalogos/Proveedores.js'
import './services/Catalogos/Puestos.js'
import './services/Catalogos/Sucursales.js'
import './services/Catalogos/TiposBeneficiario.js'
import './services/Catalogos/TiposCuenta.js'
import './services/Catalogos/TiposFirmante.js'
import './services/Catalogos/TiposGasto.js'
import './services/Catalogos/TiposMovimiento.js'

export default module.name
