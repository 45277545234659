import module from '@finances/configuracion/module'
import template from './template.html'
import '@finances/configuracion/services/Configuracion/Categoriascuentas/Categoriacuenta'

export class FinancesConfiguracionCategoriascuentasEditarComponent {
  constructor(FinancesConfiguracionCategoriascuentasCategoriacuentaService) {
    this.categoriacuentaService = FinancesConfiguracionCategoriascuentasCategoriacuentaService
    this.modal = undefined
    this.data = undefined
    this.error = null
    this.procesando = false
  }

  $onChanges(changes) {
    if (changes.data) {
      this.data = Object.assign({}, this.data)
    }
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.categoriacuentaService
      .put(this.data.id, this.data)
      .then(() => this.modal.close(this.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cancelar() {
    this.modal.close()
  }
}

module.component('financesConfiguracionCategoriascuentasEditar', {
  controller: FinancesConfiguracionCategoriascuentasEditarComponent,
  template,
  bindings: {
    data: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
