import module from '@finances/partidas/module'

export const schema = {
  id: 'number',
  registros: 'number',
  capturaFecha: 'datetime',
}

export class FinancesPartidasProteccionChequesLayoutsService {
  constructor($http) {
    this.$http = $http
  }

  get({ id } = {}) {
    return this.$http.get('api/partidas/proteccion-cheques/layouts', {
      params: { id },
      schema,
    })
  }

  post(data) {
    return this.$http.post('api/partidas/proteccion-cheques/layouts', data, {
      schema,
    })
  }
}

module.service('FinancesPartidasProteccionChequesLayoutsService', FinancesPartidasProteccionChequesLayoutsService)
