import module from '@finances/configuracion/module'

export const FinancesConfiguracionConceptosConceptoSchema = {
  activo: 'boolean',
}

export class FinancesConfiguracionConceptosConceptoService {
  constructor($http) {
    this.$http = $http
  }

  put(id, data) {
    return this.$http.put(`api/configuracion/conceptos/concepto/${id}`, data, {
      schema: FinancesConfiguracionConceptosConceptoSchema,
    })
  }
}

module.service('FinancesConfiguracionConceptosConceptoService', FinancesConfiguracionConceptosConceptoService)
