import module from '@finances/empresa/module'

export class FinancesEmpresaCuentaInformacionRepresentanteService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      id: 'number',
    }
  }

  get(idEmpresa, idCuenta) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/informacion/representante`, {
      schema: this.schema,
    })
  }

  put(idEmpresa, idCuenta, data) {
    return this.$http.put(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/informacion/representante`, data, {
      schema: this.schema,
    })
  }
}

module.service(
  'FinancesEmpresaCuentaInformacionRepresentanteService',
  FinancesEmpresaCuentaInformacionRepresentanteService,
)
