import module from '@finances/partida/module'
import template from './template.html'

export class FinancesPartidaEstatusComponent {
  actualizar(data) {
    if (data) this.onChange({ $event: { data } })
  }

  onChange() {}
}

module.component('financesPartidaEstatus', {
  controller: FinancesPartidaEstatusComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPartida: '<',
    data: '<',
    onChange: '&',
  },
})
