import module from '@finances/empresa/module'

export class FinancesEmpresaCuentaMovimientoService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      captura: {
        fecha: 'datetime',
      },
      documentos: {
        id_documento: 'number',
        captura_fecha: 'datetime',
      },
      eliminado: 'boolean',
      informacion: {
        fecha: 'date',
        importe: 'number',
        financiado: 'boolean',
      },
      pago: {
        fecha_pago: 'date',
      },
    }
  }

  get(idEmpresa, idCuenta, idMovimiento) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/movimiento/${idMovimiento}`, {
      schema: this.schema,
    })
  }

  delete(idEmpresa, idCuenta, idMovimiento) {
    return this.$http.delete(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/movimiento/${idMovimiento}`)
  }
}

module.service('FinancesEmpresaCuentaMovimientoService', FinancesEmpresaCuentaMovimientoService)
