import module from '@finances/partida/module'
import template from './template.html'

export class FinancesPartidaEstatusToggleComponent {
  constructor() {
    this.modificarConfirmar = undefined
  }

  modificar() {
    this.modificarConfirmar.open().then((data) => {
      if (data) this.onChange({ $event: { data } })
    })
  }

  onChange() {}
}

module.component('financesPartidaEstatusToggle', {
  controller: FinancesPartidaEstatusToggleComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPartida: '<',
    estatus: '<',
    onChange: '&',
  },
  transclude: true,
})
