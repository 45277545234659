import module from '@finances/empresa/module'
import template from './template.html'
import orderBy from 'lodash-es/orderBy.js'

export class FinancesEmpresaCuentaPrestamosAgregarComponent {
  constructor(
    $state,
    $q,
    FinancesCatalogosEmpresasService,
    FinancesCatalogosCuentasService,
    FinancesEmpresaCuentaPrestamosService,
    FinancesEmpresaCuentaPrestamosPredeterminadoService,
  ) {
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.onClose = undefined
    this.$state = $state
    this.$q = $q
    this.FinancesCatalogosEmpresasService = FinancesCatalogosEmpresasService
    this.FinancesCatalogosCuentasService = FinancesCatalogosCuentasService
    this.FinancesEmpresaCuentaPrestamosService = FinancesEmpresaCuentaPrestamosService
    this.FinancesEmpresaCuentaPrestamosPredeterminadoService = FinancesEmpresaCuentaPrestamosPredeterminadoService
    this.procesando = false
    this.destinatario = {
      empresa: null,
      cuenta: null,
    }
  }

  $onInit() {
    this.promise = this.$q.all([
      this.FinancesCatalogosEmpresasService.get().then(
        (response) => (this.empresas = orderBy(response.data, ['descripcion'])),
      ),
      this.FinancesEmpresaCuentaPrestamosPredeterminadoService.get(this.idEmpresa, this.idCuenta).then(
        (response) => (this.data = response.data),
      ),
    ])
  }

  aceptar() {
    if (this.procesando) return

    this.procesando = true

    this.FinancesEmpresaCuentaPrestamosService.post(this.idEmpresa, this.idCuenta, this.data)
      .then((response) => this.$state.go('empresa.cuenta.prestamos.editar', { id_prestamo: response.data.id }))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    if (this.procesando) return

    this.$state.go('empresa.cuenta.prestamos')
  }

  obtenerCuentas() {
    this.cuentas = null
    this.data.destinatario_id_moneda = undefined
    this.data.destinatario_id_empresa = this.destinatario.empresa.id
    this.data.destinatario_id_cuenta = undefined
    this.destinatario.cuenta = null

    this.FinancesCatalogosCuentasService.get(this.data.destinatario_id_empresa).then(
      (response) => (this.cuentas = orderBy(response.data, 'descripcion')),
    )
  }

  seleccionarCuenta() {
    this.data.destinatario_id_moneda = this.destinatario.cuenta.id_moneda
    this.data.destinatario_id_cuenta = this.destinatario.cuenta.id_cuenta

    if (this.data.id_moneda === this.data.destinatario_id_moneda) {
      this.data.tipo_cambio = 1
      this.data.destinatario_importe = this.data.importe
    } else {
      this.data.destinatario_importe =
        this.data.id_moneda === 1
          ? this.data.importe / this.data.tipo_cambio
          : this.data.importe * this.data.tipo_cambio
    }
  }
}

module.component('financesEmpresaCuentaPrestamosAgregar', {
  controller: FinancesEmpresaCuentaPrestamosAgregarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    onClose: '&',
  },
})
