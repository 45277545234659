import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresaCuentaPrestamoDocumentosEliminarComponent {
  constructor(FinancesEmpresaCuentaPrestamoDocumentoService) {
    this.FinancesEmpresaCuentaPrestamoDocumentoService = FinancesEmpresaCuentaPrestamoDocumentoService
    this.data = undefined
    this.modal = undefined
    this.archivo = undefined
    this.error = null
  }

  aceptar() {
    if (this.procesando) return

    this.procesando = true
    this.error = null

    this.FinancesEmpresaCuentaPrestamoDocumentoService.delete(
      this.data.id_empresa,
      this.data.id_cuenta,
      this.data.id_prestamo,
      this.data.indice,
    )
      .then((response) => this.cerrar(response.data))
      .catch((response) => (this.error = response.data)) // TODO: Mostrar mensaje de error
      .finally(() => (this.procesando = false))
  }

  cerrar(data) {
    this.modal.close(data)
  }
}

module.component('financesEmpresaCuentaPrestamoDocumentosEliminar', {
  controller: FinancesEmpresaCuentaPrestamoDocumentosEliminarComponent,
  template,
  bindings: {
    data: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
