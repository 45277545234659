import module from '@finances/empresa/module'

export const financesEmpresaCuentaPrestamoSchema = {
  capital: 'number',
  destinatario_importe: 'number',
  destinatario_id_moneda: 'number',
  destinatario_tipo_cambio: 'number',
  documentos: {
    indice: 'number',
    file_size: 'number',
  },
  fecha: 'date',
  fecha_pago: 'date',
  financiamiento: {
    capital: 'number',
    fecha: 'date',
    importe: 'number',
    indice: 'number',
    interes: 'number',
  },
  id_moneda: 'number',
  importe: 'number',
  interes: 'number',
  interes_porcentaje: 'number',
  tipo_cambio: 'number',
}

/**
 * @ngdoc service
 * @name FinancesEmpresaCuentaPrestamoService
 */
export class FinancesEmpresaCuentaPrestamoService {
  constructor($http) {
    this.$http = $http
  }

  /**
   * @memberof FinancesEmpresaCuentaPrestamoService
   */
  get(idEmpresa, idCuenta, idPrestamo) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/prestamo/${idPrestamo}`, {
      schema: financesEmpresaCuentaPrestamoSchema,
    })
  }

  /**
   * @memberof FinancesEmpresaCuentaPrestamoService
   */
  put(idEmpresa, idCuenta, idPrestamo, data) {
    return this.$http.put(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/prestamo/${idPrestamo}`, data, {
      schema: financesEmpresaCuentaPrestamoSchema,
    })
  }
}

module.service('FinancesEmpresaCuentaPrestamoService', FinancesEmpresaCuentaPrestamoService)
