import module from '@finances/importarmovimientos/module'
import template from './template.html'

export class FinancesImportarmovimientosOrigenExcelComponent {
  form = undefined
  empresas = undefined
  cuentas = undefined
  procesando = false
  error = null
  config = null
  input = null
  scriptInvalido = false
  opciones = {}

  constructor(
    $fileSystem,
    $q,
    $scope,
    $window,
    FinancesCatalogosEmpresasService,
    FinancesCatalogosCuentasService,
    FinancesImportarmovimientosDecodificarService,
  ) {
    this.$fileSystem = $fileSystem
    this.$q = $q
    this.$window = $window
    this.$scope = $scope
    this.catalogoCuentasService = FinancesCatalogosCuentasService
    this.importarmovimientosDecodificarService = FinancesImportarmovimientosDecodificarService
    this.empresasService = FinancesCatalogosEmpresasService
  }

  $onInit() {
    this.promise = this.empresasService.get().then((response) => (this.empresas = response.data))
  }

  $onDestroy() {
    this.importarmovimientosDecodificarService.terminar()
  }

  cerrar() {
    this.onClose()
  }

  decodificar() {
    if (this.procesando) return

    this.procesando = true
    this.error = null

    this.importarmovimientosDecodificarService
      .decodificar(this.cuenta.script_importar, this.input, this.opciones, this.cuenta)
      .then((movimientos) => {
        const data = {
          origen: 'Copiar y pegar desde Excel',
          movimientos: movimientos.map((item) => {
            item.empresa = this.empresa.descripcion
            item.cuenta = this.cuenta.banco + ' ' + this.cuenta.numero_cuenta + ' ' + this.cuenta.moneda

            return item
          }),
        }

        this.onClose({ $event: { data } })
      })
      .catch((error) => (this.error = error))
      .finally(() => {
        this.procesando = false
        this.input = null
        this.$scope.$digest()
      })
  }

  obtenerCuentas() {
    this.cuenta = undefined
    this.cuentas = undefined

    if (!this.empresa) return

    this.catalogoCuentasService.get(this.empresa.id).then((response) => {
      this.cuentas = response.data
      this.obtenerConfig()
    })
  }

  obtenerConfig() {
    if (!this.cuenta) return

    this.scriptInvalido = false
    this.config = null
    this.opciones = {}

    this.importarmovimientosDecodificarService.canHandle(this.cuenta.script_importar).then((canHandle) => {
      this.$scope.$apply(() => {
        this.scriptInvalido = !canHandle

        if (!canHandle) return

        this.importarmovimientosDecodificarService.getConfig(this.cuenta.script_importar).then((config) => {
          this.$scope.$apply(() => {
            this.config = config

            if (this.config) {
              for (const { key, values } of this.config) {
                this.opciones[key] = values.find((option) => option.default)
              }
            }
          })
        })
      })
    })
  }

  importarTexto() {
    this.input = { type: 'text', content: this.inputText }
    this.decodificar()
  }

  async importarPortapapeles() {
    try {
      const queryOpts = { name: 'clipboard-read', allowWithoutGesture: false }
      const permissionStatus = await navigator.permissions.query(queryOpts)

      if (permissionStatus.state === 'denied') {
        this.pegarNoPermitido.open()

        return
      }

      const content = await navigator.clipboard.readText()
      this.input = { type: 'text', content }
      this.decodificar()
    } catch (error) {
      console.log('error', error)
      // console.log('Failed to read clipboard. Using execCommand instead.');
      // document.querySelector('textarea').focus();
      // const result = document.execCommand('paste')
      // console.log('document.execCommand result: ', result);
    }
  }

  importarArchivo() {
    this.$fileSystem
      .select(false)
      .then(([archivo]) => this.leerArchivo(archivo))
      .then((content) => {
        this.input = { type: 'file', content }
        this.decodificar()
      })
      .catch((error) => (this.error = error))
  }

  leerArchivo(archivo) {
    return this.$q((resolve) => {
      const reader = new this.$window.FileReader()
      reader.onload = (e) => resolve(e.target.result)
      reader.readAsText(archivo)
    })
  }

  onClose() {}
}

module.component('financesImportarmovimientosOrigenExcel', {
  controller: FinancesImportarmovimientosOrigenExcelComponent,
  template,
  bindings: {
    onClose: '&',
  },
})
