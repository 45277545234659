import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresaCuentaMovimientoEliminarComponent {
  constructor() {
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idPartida = undefined
    this.data = undefined
    this.onChange = undefined
    this.financesEmpresaCuentaMovimientoEliminarConfirmar = undefined
  }

  eliminar() {
    this.financesEmpresaCuentaMovimientoEliminarConfirmar.open().then((data) => {
      if (data && data.eliminado) {
        this.onChange({ $event: { data: null } })
      }
    })
  }
}

module.component('financesEmpresaCuentaMovimientoEliminar', {
  controller: FinancesEmpresaCuentaMovimientoEliminarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPartida: '<',
    data: '<',
    onChange: '&',
  },
})
