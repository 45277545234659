import module from '@finances/partida/module'
import template from './template.html'

export class FinancesPartidaMetadataComponent {}

module.component('financesPartidaMetadata', {
  controller: FinancesPartidaMetadataComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPartida: '<',
    data: '<',
  },
})
