import module from '@finances/clasificarmovimientos/module'

module.config(($stateProvider) => {
  $stateProvider.state('clasificarmovimientos', {
    url: '/clasificar_movimientos?{fecha}&{id_categoria}&{id_moneda}',
    data: {
      title: 'Clasificación de movimientos',
    },
    params: {
      fecha: { type: 'date', value: new Date(), dynamic: true },
      id_categoria: { type: 'int', value: 0, dynamic: true },
      id_moneda: { type: 'int', value: 0, dynamic: true },
    },
    views: {
      '@': 'financesClasificarmovimientos',
    },
    resolve: {
      fecha: ($transition$) => $transition$.params().fecha,
      idCategoria: ($transition$) => $transition$.params().id_categoria,
      idMoneda: ($transition$) => $transition$.params().id_moneda,
    },
  })
})
