import module from '@finances/empresas/module'
import template from './template.html'
import colores from './colores.json'
import './style.scss'

export class FinancesConfiguracionConceptosEditarColoresComponent {
  constructor() {
    this.color = undefined
    this.onChange = undefined
    this.colores = colores
  }

  $onChanges(changes) {
    if (changes.color && this.colores.indexOf(this.color) < 0) {
      this.seleccionar(this.colores[this.colores.length - 1])
    }
  }

  seleccionar(color) {
    this.color = color
    this.onChange({ $event: { data: color } })
  }
}

module.component('financesConfiguracionConceptosEditarColores', {
  controller: FinancesConfiguracionConceptosEditarColoresComponent,
  template,
  bindings: {
    color: '<',
    onChange: '&',
  },
})
