import module from '@finances/empresas/module'
import template from './template.html'

export class FinancesPartidasProteccionChequesLayoutsEliminarComponent {
  data = undefined

  error = null

  modal = undefined

  procesando = false

  constructor(FinancesPartidasProteccionChequesLayoutService) {
    this.financesPartidasProteccionChequesLayoutService = FinancesPartidasProteccionChequesLayoutService
  }

  eliminar() {
    if (this.procesando) return

    this.procesando = true

    this.financesPartidasProteccionChequesLayoutService
      .delete({ id: this.data.id })
      .then(() => this.cerrar(true))
      .catch(({ data }) => (this.error = data))
      .finally(() => (this.procesando = false))
  }

  cerrar(eliminado = false) {
    this.modal.close({ eliminado })
  }
}

module.component('financesPartidasProteccionChequesLayoutsEliminar', {
  controller: FinancesPartidasProteccionChequesLayoutsEliminarComponent,
  template,
  bindings: {
    data: '<',
    onClose: '&',
  },
  require: {
    modal: 'kdModal',
  },
})
