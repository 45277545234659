import module from '@finances/catalogos/module'

export const FinancesCatalogosTiposMovimientoSchema = {
  id: 'number',
  id_moneda: 'number',
  saldo_inicial: 'boolean',
}

export class FinancesCatalogosTiposMovimientoService {
  constructor($http) {
    this.$http = $http
  }

  get() {
    return this.$http.get('api/catalogos/tipos_movimiento', {
      schema: FinancesCatalogosTiposMovimientoSchema,
      cache: true,
    })
  }
}

module.service('FinancesCatalogosTiposMovimientoService', FinancesCatalogosTiposMovimientoService)
