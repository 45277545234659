import module from '@finances/empresa/module.js'

module.config(($stateProvider) => {
  $stateProvider.state('empresa.cuenta.prestamos.agregar', {
    url: '/nuevo',
    data: { title: 'Nuevo' },
    views: {
      '@': 'financesEmpresaCuentaPrestamosAgregar',
    },
  })
})
