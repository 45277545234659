import angular from 'angular'
import template from './template.html'
import './style.scss'

export class FinancesEmpresaCuentaMovimientoDocumentoComponent {
  constructor(FinancesEmpresaCuentaMovimientoDocumentoService, AppTimeService) {
    this.appTimeService = AppTimeService
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idMovimiento = undefined
    this.idDocumento = undefined
    this.archivoNombre = undefined
    this.capturaFecha = undefined
    this.onDelete = undefined
    this.FinancesEmpresaCuentaMovimientoDocumentoService = FinancesEmpresaCuentaMovimientoDocumentoService
    this.src = null
    this.visualizando = false
  }

  $onInit() {
    this.src = this.FinancesEmpresaCuentaMovimientoDocumentoService.get(
      this.idEmpresa,
      this.idCuenta,
      this.idMovimiento,
      this.idDocumento,
    )
    this.nombre = this.archivoNombre.replace(/.pdf$/, '')
    this.fecha = this.appTimeService.fromNow(this.capturaFecha)
  }

  eliminar() {
    this.confirmarEliminar.open().then((data) => {
      if (data) this.onDelete({ $event: { data } })
    })
  }

  onDelete() {}
}

angular.module('finances.empresa').component('financesEmpresaCuentaMovimientoDocumento', {
  controller: FinancesEmpresaCuentaMovimientoDocumentoComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idMovimiento: '<',
    idDocumento: '<',
    archivoNombre: '<',
    capturaFecha: '<',
    onDelete: '&',
  },
})
