import module from '@finances/divisas/module'
import template from './template.html'

export class FinancesDivisasListaSemanaComponent {
  constructor(AppTimeService) {
    this.semana = undefined
    this.dias = undefined
    this.appTimeService = AppTimeService
  }

  $onInit() {
    this.expandido = this.semana === this.appTimeService.week(new Date())
    this.evaluar()
  }

  evaluar() {
    const inicial = this.dias.find((item) => item.tipo_cambio)
    const final = [...this.dias].reverse().find((item) => item.tipo_cambio)
    if (inicial && final && inicial !== final) {
      this.descripcion = `${inicial.tipo_cambio.toFixed(4)} - ${final.tipo_cambio.toFixed(4)}`
    } else {
      this.descripcion = (inicial && inicial.tipo_cambio) || ''
    }

    const completados = this.dias.filter((item) => item.tipo_cambio).length
    this.parcial = completados > 0 && completados < this.dias.length
  }
}

module.component('financesDivisasListaSemana', {
  template,
  controller: FinancesDivisasListaSemanaComponent,
  bindings: {
    semana: '<',
    dias: '<',
  },
})
