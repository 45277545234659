import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresasAgregarComponent {
  constructor($q, $state) {
    this.$q = $q
    this.$state = $state
    this.data = {
      nombre: '',
      rfc: '',
      alias: '',
    }
    this.confirmarAgregar = undefined
  }

  $onInit() {}

  cerrar() {
    this.$state.go('empresas')
  }

  agregar() {
    this.confirmarAgregar.open().then((idEmpresa) => {
      if (idEmpresa) {
        this.$state.go(
          'empresa',
          {
            id_empresa: idEmpresa,
          },
          { location: 'replace' },
        )
      }
    })
  }
}

module.component('financesEmpresasAgregar', {
  controller: FinancesEmpresasAgregarComponent,
  template,
})
