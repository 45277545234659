import module from '@finances/empresa/module'
import template from './template.html'
import './style.scss'

export class FinancesEmpresaCuentaMovimientoDocumentosComponent {
  constructor($fileSystem, FinancesEmpresaCuentaMovimientoDocumentosService) {
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idMovimiento = undefined
    this.data = undefined
    this.onChange = undefined
    this.$fileSystem = $fileSystem
    this.documentosService = FinancesEmpresaCuentaMovimientoDocumentosService
    this.agregando = false
    this.error = null
  }

  actualizar() {
    this.onChange({ $event: { data: this.data } })
  }

  agregar() {
    this.error = null

    this.$fileSystem.select(false, 'application/pdf').then((files) => {
      this.agregando = true
      this.documentosService
        .post(this.idEmpresa, this.idCuenta, this.idMovimiento, files[0])
        .then((response) => {
          this.data.documentos.push(response.data)
          this.actualizar()
        })
        .catch((response) => (this.error = response.data))
        .finally(() => (this.agregando = false))
    })
  }

  eliminar(item) {
    this.data.documentos.splice(this.data.documentos.indexOf(item), 1)
    this.actualizar()
  }
}

module.component('financesEmpresaCuentaMovimientoDocumentos', {
  controller: FinancesEmpresaCuentaMovimientoDocumentosComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idMovimiento: '<',
    data: '<',
    onChange: '&',
  },
})
