import module from '@finances/partidas/module'
import template from './template.html'

export class FinancesPartidasAgregarBeneficiarioComponent {
  constructor(FinancesCatalogosTiposBeneficiarioService) {
    this.FinancesCatalogosTiposBeneficiarioService = FinancesCatalogosTiposBeneficiarioService
  }

  $onInit() {
    this.FinancesCatalogosTiposBeneficiarioService.get().then((response) => (this.tiposBeneficiario = response.data))
  }
}

module.component('financesPartidasAgregarBeneficiario', {
  controller: FinancesPartidasAgregarBeneficiarioComponent,
  template,
  bindings: {
    data: '<',
  },
})
