import module from '@finances/configuracion/module'

export const financesConfiguracionFormaspagosSchema = {
  en_uso: 'boolean',
}

export class FinancesConfiguracionFormaspagosService {
  constructor($http) {
    this.$http = $http
  }

  get() {
    return this.$http.get('api/configuracion/formas_pago', {
      schema: financesConfiguracionFormaspagosSchema,
    })
  }

  post(data) {
    return this.$http.post('api/configuracion/formas_pago', data, {
      schema: financesConfiguracionFormaspagosSchema,
    })
  }
}

module.service('FinancesConfiguracionFormaspagosService', FinancesConfiguracionFormaspagosService)
