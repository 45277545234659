import module from '@finances/configuracion/module'

module.config(($stateProvider) => {
  $stateProvider.state('configuracion.conceptos', {
    url: '/conceptos',
    data: { title: 'Conceptos' },
    views: {
      '@': 'financesConfiguracionConceptos',
    },
  })
})
