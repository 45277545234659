import module from '@finances/empresas/module'

const noClasificado = { id: '000', descripcion: 'No clasificado' }

export class FinancesImportarpartidasConfiguracionAsociacionService {
  constructor($q, $http) {
    this.$q = $q
    this.$http = $http
    this.tests = null
    this.schema = { id_moneda: 'number' }
  }

  buscar(tipoMovimiento) {
    return this.obtenerCatalogo().then(
      (movimientos) => movimientos.find((movimiento) => movimiento.descripcion === tipoMovimiento) || noClasificado,
    )
  }

  obtenerCatalogo() {
    if (!this.tests) {
      this.tests = this.$http
        .get(`api/catalogos/tipos_movimiento`, {
          params: { id_moneda: '1', naturaleza: 'C' },
          schema: this.schema,
        })
        .then((response) => response.data)
    }

    return this.tests
  }
}

module.service(
  'FinancesImportarpartidasConfiguracionAsociacionService',
  FinancesImportarpartidasConfiguracionAsociacionService,
)
