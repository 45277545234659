import module from '@finances/configuracion/module'

export const financesConfiguracionClasificacionmovimientosOrdenSchema = {
  orden: 'number',
}

export class FinancesConfiguracionClasificacionmovimientosOrdenService {
  constructor($http) {
    this.$http = $http
  }

  put(data) {
    return this.$http.put('api/configuracion/clasificacion_movimientos/orden', data, {
      schema: financesConfiguracionClasificacionmovimientosOrdenSchema,
    })
  }
}

module.service(
  'FinancesConfiguracionClasificacionmovimientosOrdenService',
  FinancesConfiguracionClasificacionmovimientosOrdenService,
)
