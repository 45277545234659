import module from '@finances/catalogos/module'

export class FinancesCatalogosCuentasService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      id_empresa: 'number',
      id_moneda: 'number',
    }
  }

  get(idEmpresa, idMoneda) {
    return this.$http.get('api/catalogos/cuentas', {
      params: { id_empresa: idEmpresa, id_moneda: idMoneda },
      schema: this.schema,
      cache: true,
    })
  }
}

module.service('FinancesCatalogosCuentasService', FinancesCatalogosCuentasService)
