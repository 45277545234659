import module from './module.js'
/* Components */
import './components/component.js'
import './components/autorizacion/component.js'
import './components/autorizacion/toggle/component.js'
import './components/beneficiario/component.js'
import './components/beneficiario/editar/component.js'
import './components/eliminar/component.js'
import './components/eliminar/confirmar/component.js'
import './components/estatus/component.js'
import './components/estatus/toggle/component.js'
import './components/estatus/toggle/confirmar/component.js'
import './components/gasto/component.js'
import './components/gasto/editar/component.js'
import './components/imprimir/component.js'
import './components/imprimir/generar/component.js'
import './components/informacion/component.js'
import './components/informacion/editar/component.js'
import './components/metadata/component.js'
import './components/origen/component.js'
import './components/origen/editar/component.js'
/* Services */
import './services/Partida.js'
import './services/Partida/Autorizacion.js'
import './services/Partida/Beneficiario.js'
import './services/Partida/Cheque.js'
import './services/Partida/Estatus.js'
import './services/Partida/Gasto.js'
import './services/Partida/Informacion.js'
import './services/Partida/Origen.js'

export default module.name
