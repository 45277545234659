import module from '@finances/empresas/module'
import template from './template.html'
import '@finances/configuracion/services/Configuracion/Conceptos/Concepto'

export class FinancesConfiguracionConceptosEditarComponent {
  constructor(FinancesConfiguracionConceptosConceptoService) {
    this.conceptoService = FinancesConfiguracionConceptosConceptoService
    this.modal = undefined
    this.error = null
    this.procesando = false
    this.data = { descripcion: undefined, color: undefined }
    this.concepto = undefined
  }

  $onChanges(changes) {
    if (changes.concepto) {
      this.enUso = this.concepto.en_uso
      this.original = this.concepto.descripcion
      this.data.descripcion = this.concepto.descripcion
      this.data.color = this.concepto.color
    }
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.conceptoService
      .put(this.concepto.id, this.data)
      .then(() => this.modal.close(this.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cancelar() {
    this.modal.close()
  }
}

module.component('financesConfiguracionConceptosEditar', {
  controller: FinancesConfiguracionConceptosEditarComponent,
  template,
  bindings: {
    concepto: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
