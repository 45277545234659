import module from '@finances/importarmovimientos/module'
import template from './template.html'
import { isDate } from 'angular'

export class FinancesImportarmovimientosHistorialComponent {
  constructor($filter, $state) {
    this.$filter = $filter
    this.$state = $state
    this.fecha = undefined
  }

  $onChanges(changes) {
    if (changes.fecha && !isDate(this.fecha)) {
      this.fecha = new Date()
    }
  }

  buscar() {
    this.$state.go(
      this.$state.current,
      { fecha: this.$filter('date')(this.fecha, 'yyyy-MM-dd') },
      { location: 'replace' },
    )
  }

  modificarFecha(intervalo) {
    this.fecha = new Date(this.fecha.getFullYear(), this.fecha.getMonth(), this.fecha.getDate() + intervalo)

    this.buscar()
  }
}

module.component('financesImportarmovimientosHistorial', {
  controller: FinancesImportarmovimientosHistorialComponent,
  template,
  bindings: {
    fecha: '<',
  },
})
