import module from '@finances/importarpartidas/module'
import template from './template.html'

export class FinancesImportarpartidasOrigenExcelComponent {
  constructor(
    $q,
    FinancesImportarpartidasCuentasService,
    FinancesImportarpartidasConfiguracionExcelService,
    FinancesImportarpartidasConfiguracionAsociacionService,
  ) {
    this.$q = $q
    this.FinancesImportarpartidasCuentasService = FinancesImportarpartidasCuentasService
    this.FinancesImportarpartidasConfiguracionExcelService = FinancesImportarpartidasConfiguracionExcelService
    this.FinancesImportarpartidasConfiguracionAsociacionService = FinancesImportarpartidasConfiguracionAsociacionService
  }

  $onInit() {
    this.promise = this.FinancesImportarpartidasConfiguracionExcelService.get().then((response) => {
      this.encabezados = response.encabezados
      this.modelo = response.modelo
    })
  }

  cancelar() {
    this.onClose()
  }

  completarInformacion(data) {
    const numerosCuentas = data.reduce((carry, next) => {
      if (carry.indexOf(next.numero_cuenta) < 0) {
        carry.push(next.numero_cuenta)
      }

      return carry
    }, [])

    return this.obtenerCuentas(numerosCuentas)
      .then((cuentas) => data.map((item, index) => ({ ...item, ...cuentas[this.pad(item.numero_cuenta)], id: index })))
      .then((items) => {
        const data = items.map((item) => {
          return this.FinancesImportarpartidasConfiguracionAsociacionService.buscar(item.tipo_movimiento).then(
            (movimiento) => ({ ...item, id_tipo_movimiento: movimiento.id, tipo_movimiento: movimiento.descripcion }),
          )
        })

        return this.$q.all(data)
      })
  }

  importar() {
    this.onClose({ $event: { data: this.data } })
    // this.completarInformacion(this.data).then(({ data }) => this.onClose({ $event: { data: data } }))
  }

  onClose() {}

  numerosCuentas = {}

  obtenerCuentas(numerosCuentas) {
    numerosCuentas.filter((n) => !Object.hasOwn(this.numerosCuentas, n))

    if (!numerosCuentas.length) {
      return this.$q.when(this.numerosCuentas)
    }

    return this.FinancesImportarpartidasCuentasService.get(numerosCuentas).then((response) => {
      response.data.forEach((n) => (this.numerosCuentas[this.pad(n.numero_cuenta)] = n))

      return this.numerosCuentas
    })
  }

  pad(string) {
    return ('0000000000' + string).substr(-10)
  }

  importarExcel(encabezados, filas) {
    this.data = null
    this.error = null
    this.alertas = []

    if (!encabezados || !filas?.length) return

    this.validarEncabezados(encabezados)
      .then(() => this.completarInformacion(filas))
      .then((data) => this.filtrarRegistrosValidos(data))
      .then(
        (data) => {
          this.data = data

          encabezados.forEach((item, index) => {
            if (item === null) {
              this.alertas.push(`Se omitirá la columna ${index + 1} pues no se especificó un encabezado.`)
            }
          })
        },
        (error) => (this.error = error.message ?? error),
      )
  }

  validarEncabezados(encabezados) {
    try {
      const _encabezados = []

      for (const [index, item] of Object.entries(encabezados)) {
        if (item && _encabezados.includes(item)) {
          throw new Error('La columna ' + (index + 1) + ' se encuentra duplicada.')
        }

        _encabezados.push(item)
      }

      if (!encabezados.includes('numero_cuenta')) {
        throw new Error("Es necesario seleccionar una columna como 'Cuenta bancaria'")
      }

      if (!encabezados.includes('fecha')) {
        throw new Error("Es necesario seleccionar una columna como 'Fecha'")
      }

      if (!encabezados.includes('beneficiario')) {
        throw new Error("Es necesario seleccionar una columna como 'Beneficiario'")
      }

      if (!encabezados.includes('importe')) {
        throw new Error("Es necesario seleccionar una columna como 'Importe'")
      }

      return this.$q.when()
    } catch (e) {
      return this.$q.reject(e)
    }
  }

  filtrarRegistrosValidos(filas) {
    const registros = []

    for (const [i, item] of Object.entries(filas)) {
      const index = Number(i) + 1
      if (!item.numero_cuenta) {
        this.alertas.push(`Se omitirá la fila ${index} ya que no se especificó el número de cuenta.`)
      } else if (!item.id_cuenta) {
        this.alertas.push(`Se omitirá la fila ${index} ya que no se encontró una cuenta registrada con ese número.`)
      } else if (!item.fecha) {
        this.alertas.push(`Se omitirá la fila ${index} ya que no tiene una fecha válida.`)
      } else if (!item.importe) {
        this.alertas.push(`Se omitirá la fila ${index} ya que no se especificó un importe.`)
      } else if (!item.tipo_movimiento) {
        this.alertas.push(`Se omitirá la fila ${index} ya que no se especificó el tipo de movimiento.`)
      } else if (!item.id_tipo_movimiento) {
        this.alertas.push(`Se omitirá la fila ${index} ya que no se reconoce el tipo de movimiento especificado.`)
      } else if (!item.beneficiario) {
        this.alertas.push(`Se omitirá la fila ${index} ya que no se especificó el beneficiario.`)
      } else {
        registros.push(item)
      }
    }

    if (!registros.length) {
      throw new Error('Ninguna fila contiene información para importar.')
    }

    return this.$q.when(registros)
  }
}

module.component('financesImportarpartidasOrigenExcel', {
  controller: FinancesImportarpartidasOrigenExcelComponent,
  template,
  bindings: {
    onClose: '&',
  },
})
