import module from '@finances/configuracion/module'

class FinancesConfiguracionCategoriascuentasCategoriacuentaService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      id: 'number',
    }
  }

  put(id, data) {
    return this.$http.put(`api/configuracion/categorias_cuentas/${id}`, data, {
      schema: this.schema,
    })
  }

  delete(id) {
    return this.$http.delete(`api/configuracion/categorias_cuentas/${id}`)
  }
}

module.service(
  'FinancesConfiguracionCategoriascuentasCategoriacuentaService',
  FinancesConfiguracionCategoriascuentasCategoriacuentaService,
)
