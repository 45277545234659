import module from '@finances/empresas/module'
import template from './template.html'

export class FinancesEmpresasListaComponent {
  constructor() {
    this.data = undefined
    this.filtro = ''
  }

  $onChanges(changes) {
    if (changes.data) {
      this.recientes = this.data.filter((item) => item.acceso_fecha)
    }
  }
}

module.component('financesEmpresasLista', {
  controller: FinancesEmpresasListaComponent,
  template,
  bindings: {
    data: '<',
  },
})
