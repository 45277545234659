import module from '@finances/clasificarmovimientos/module'
import template from './template.html'
import './indicador/component.js'

export class FinancesClasificarmovimientosListaComponent {
  constructor(FinancesClasificarmovimientosMovimientosService) {
    this.aplicando = undefined
    this.clasificando = undefined
    this.FinancesClasificarmovimientosMovimientosService = FinancesClasificarmovimientosMovimientosService
    this.filtro = {}
    this.filtroConfig = { texto: '', pendientes: false, modificados: false }
    this.fecha = undefined
    this.idCategoria = undefined
    this.idMoneda = undefined
    this.tiposMovimiento = undefined
    this.modificados = undefined
    this.pendientes = undefined
  }

  $onChanges(changes) {
    if (changes.fecha || changes.idCategoria || changes.idMoneda) {
      this.data = null

      if (this.fecha) {
        this.modificados = undefined
        this.pendientes = undefined

        this.notificar()

        this.FinancesClasificarmovimientosMovimientosService.get({
          fecha: this.fecha,
          idCategoria: this.idCategoria,
          idMoneda: this.idMoneda,
        })
          .then((response) => {
            this.data = response.data.reduce((data, empresa) => {
              empresa.cuentas.forEach((cuenta) => {
                cuenta.movimientos.forEach((movimiento) => {
                  data.push({
                    id_empresa: empresa.id_empresa,
                    id_cuenta: cuenta.id_cuenta,
                    id_categoria: cuenta.id_categoria,
                    id_banco: cuenta.id_banco,
                    id_moneda: cuenta.id_moneda,
                    id_movimiento: movimiento.id_movimiento,
                    id_tipo_movimiento: movimiento.id_tipo_movimiento,
                    empresa: empresa.empresa,
                    cuenta: cuenta.banco + ' ' + cuenta.numero_cuenta + ' ' + cuenta.moneda,
                    numero_cuenta: cuenta.numero_cuenta,
                    banco: cuenta.banco,
                    moneda: cuenta.moneda,
                    notas: movimiento.notas,
                    naturaleza: movimiento.naturaleza,
                    importe: movimiento.importe,
                    pendiente: !movimiento.id_tipo_movimiento,
                    modificado: false,
                    procesando: false,
                    error: null,
                  })
                })
              })

              return data
            }, [])

            this.totalizar()

            this.filtroConfig.pendientes = this.pendientes > 0
            this.filtroConfig.modificados = this.modificados > 0

            this.filtrar()
          })
          .catch((response) => (this.error = response.data))
      }
    }
  }

  actualizar() {
    this.filtroConfig.modificados = this.modificados > 0
    this.filtroConfig.pendientes = !this.modificados && this.pendientes > 0

    this.filtrar()
  }

  aplicar() {
    this.aplicando.open().then((completado) => {
      if (!completado) return

      this.totalizar()
      this.actualizar()
    })
  }

  clasificar() {
    this.clasificando.open().then(() => {
      this.totalizar()
      this.actualizar()
    })
  }

  filtrar() {
    this.filtro = {}

    if (this.filtroConfig.texto) this.filtro.$ = this.filtroConfig.texto
    if (this.filtroConfig.pendientes) this.filtro.pendiente = true
    if (this.filtroConfig.modificados) this.filtro.modificado = true
  }

  notificar() {
    const data = {
      movimientos: this.data,
      pendientes: this.pendientes,
      modificados: this.modificados,
    }

    this.onChange({ $event: { data } })
  }

  onChange() {}

  totalizar() {
    this.pendientes = this.data.reduce((total, item) => total + ((item.pendiente && 1) || 0), 0)
    this.modificados = this.data.reduce((total, item) => total + ((item.modificado && 1) || 0), 0)

    this.notificar()
  }
}

module.component('financesClasificarmovimientosLista', {
  controller: FinancesClasificarmovimientosListaComponent,
  template,
  bindings: {
    fecha: '<',
    idCategoria: '<',
    idMoneda: '<',
    tiposMovimiento: '<',
    onChange: '&',
  },
})
