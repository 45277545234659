import module from '@finances/empresa/module.js'

module.config(($stateProvider) => {
  $stateProvider.state('empresa.cuenta', {
    url: '/cuenta/{id_cuenta}',
    data: { title: 'Cuenta' },
    redirectTo: 'empresa.cuenta.informacion',
    views: {
      '@': 'financesEmpresaCuenta',
    },
    resolve: {
      idCuenta: ($transition$) => $transition$.params().id_cuenta,
    },
  })
})
