import module from '@finances/importarmovimientos/module'

export class FinancesImportarmovimientosHistorialLoteService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      importe: 'number',
      fecha: 'date',
    }
  }

  get(idLote) {
    return this.$http.get(`api/importar_movimientos/historial/lote/${idLote}`, {
      schema: this.schema,
    })
  }

  delete(idLote) {
    return this.$http.delete(`api/importar_movimientos/historial/lote/${idLote}`)
  }
}

module.service('FinancesImportarmovimientosHistorialLoteService', FinancesImportarmovimientosHistorialLoteService)
