import module from '@finances/partidas/module'
import template from './template.html'

export class FinancesPartidasAgregarComponent {
  constructor($state, FinancesPartidasPredeterminadoService) {
    this.$state = $state
    this.confirmar = undefined
    this.predeterminadoService = FinancesPartidasPredeterminadoService
    this.idCuenta = undefined
    this.idEmpresa = undefined
  }

  $onInit() {
    this.promise = this.predeterminadoService.get().then((response) => {
      this.data = response.data
      this.data.id_empresa = this.idEmpresa
      this.data.id_cuenta = this.idCuenta

      this.gasto = this.data.gasto

      this.data.gasto = null
    })
  }

  agregar() {
    this.confirmar.open().then((data) => {
      this.agregando = false

      if (!data) return

      Object.assign(this.data, data)

      this.$state.go(
        'partidas.editar',
        {
          id_empresa: this.data.id_empresa,
          id_cuenta: this.data.id_cuenta,
          id_partida: this.data.id_partida,
        },
        { location: 'replace' },
      )
    })
  }

  agregarGasto() {
    this.data.gasto = this.gasto
  }
}

module.component('financesPartidasAgregar', {
  controller: FinancesPartidasAgregarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    onChange: '&',
    onClose: '&',
  },
})
