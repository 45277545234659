import module from '@finances/partida/module'
import template from './template.html'

export class FinancesPartidaGastoComponent {
  constructor() {
    this.editando = false
    this.data = undefined
  }

  actualizar(data) {
    this.editando = false

    if (data) {
      Object.assign(this.data, data)

      this.onChange({ $event: { data: this.data } })
    }
  }

  onChange() {}
}

module.component('financesPartidaGasto', {
  controller: FinancesPartidaGastoComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPartida: '<',
    data: '<',
    onChange: '&',
  },
})
