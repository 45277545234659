import module from '@finances/empresa/module'
import template from './template.html'
import orderBy from 'lodash-es/orderBy.js'

export class FinancesEmpresaCuentaInformacionRepresentanteEditarComponent {
  constructor($q, FinancesCatalogosFirmantesService, FinancesEmpresaCuentaInformacionRepresentanteService) {
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.onClose = undefined
    this.$q = $q
    this.FinancesCatalogosFirmantesService = FinancesCatalogosFirmantesService
    this.FinancesEmpresaCuentaInformacionRepresentanteService = FinancesEmpresaCuentaInformacionRepresentanteService
    this.error = null
    this.procesando = false
  }

  $onInit() {
    this.promise = this.$q.all([
      this.FinancesEmpresaCuentaInformacionRepresentanteService.get(this.idEmpresa, this.idCuenta).then(
        (response) => (this.data = response.data),
      ),
      this.FinancesCatalogosFirmantesService.get().then(
        (response) => (this.personas = orderBy(response.data, ['descripcion'])),
      ),
    ])
  }

  actualizar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.FinancesEmpresaCuentaInformacionRepresentanteService.put(this.idEmpresa, this.idCuenta, this.data)
      .then((response) => this.cerrar(response.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar($data) {
    this.onClose({ $data })
  }
}

module.component('financesEmpresaCuentaInformacionRepresentanteEditar', {
  controller: FinancesEmpresaCuentaInformacionRepresentanteEditarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    onClose: '&',
  },
})
