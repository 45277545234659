import module from '@finances/empresa/module'
import template from './template.html'
import orderBy from 'lodash-es/orderBy.js'

export class FinancesEmpresaCuentaInformacionFirmantesEditarComponent {
  constructor(
    $q,
    FinancesCatalogosFirmantesService,
    FinancesCatalogosTiposFirmanteService,
    FinancesEmpresaCuentaInformacionFirmanteService,
  ) {
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idFirmante = undefined
    this.onClose = undefined
    this.$q = $q
    this.FinancesCatalogosFirmantesService = FinancesCatalogosFirmantesService
    this.FinancesCatalogosTiposFirmanteService = FinancesCatalogosTiposFirmanteService
    this.FinancesEmpresaCuentaInformacionFirmanteService = FinancesEmpresaCuentaInformacionFirmanteService
    this.error = null
    this.procesando = false
  }

  $onInit() {
    this.promise = this.$q.all([
      this.FinancesEmpresaCuentaInformacionFirmanteService.get(this.idEmpresa, this.idCuenta, this.idFirmante).then(
        (response) => (this.data = response.data || {}),
      ),
      this.FinancesCatalogosFirmantesService.get().then(
        (response) => (this.personas = orderBy(response.data, ['descripcion'])),
      ),
      this.FinancesCatalogosTiposFirmanteService.get().then((response) => (this.tiposFirmante = response.data)),
    ])
  }

  actualizar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.FinancesEmpresaCuentaInformacionFirmanteService.put(this.idEmpresa, this.idCuenta, this.idFirmante, this.data)
      .then((response) => this.cerrar(response.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar($data) {
    this.onClose({ $data })
  }
}

module.component('financesEmpresaCuentaInformacionFirmantesEditar', {
  controller: FinancesEmpresaCuentaInformacionFirmantesEditarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idFirmante: '<',
    onClose: '&',
  },
})
