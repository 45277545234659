import module from '@finances/importarmovimientos/module'
import template from './template.html'
import { isDate } from 'angular'

export class FinancesImportarmovimientosHistorialListaComponent {
  constructor(FinancesImportarmovimientosHistorialService) {
    this.data = null
    this.historialService = FinancesImportarmovimientosHistorialService
  }

  $onChanges(changes) {
    if (changes.fecha) {
      this.data = null

      if (isDate(this.fecha)) this.cargarHistorial()
    }
  }

  cargarHistorial() {
    this.promise = this.historialService.get(this.fecha).then((response) => (this.data = response.data))
  }

  eliminar(item) {
    this.data = this.data.filter((elem) => elem !== item)
  }
}

module.component('financesImportarmovimientosHistorialLista', {
  controller: FinancesImportarmovimientosHistorialListaComponent,
  template,
  bindings: {
    fecha: '<',
  },
})
