import module from '@finances/partidas/module'
import template from './template.html'

export class FinancesPartidasAgregarInformacionComponent {
  constructor(
    FinancesCatalogosTiposMovimientoService,
    FinancesCatalogosEstatusPartidasService,
    FinancesCatalogosFormasPagoPartidasService,
    FinancesCatalogosLeyendasService,
  ) {
    FinancesCatalogosEstatusPartidasService.get().then((response) => (this.estatusPartida = response.data))

    FinancesCatalogosFormasPagoPartidasService.get().then((response) => (this.formasPago = response.data))

    FinancesCatalogosLeyendasService.get().then((response) => (this.leyendas = response.data))

    FinancesCatalogosTiposMovimientoService.get().then((response) => (this.tiposMovimiento = response.data))

    this.data = undefined
  }

  actualizarFormaPago() {
    if (this.data.id_forma_pago !== '02') {
      this.data.cheque = null
      this.data.id_leyenda = 0
    }
  }
}

module.component('financesPartidasAgregarInformacion', {
  controller: FinancesPartidasAgregarInformacionComponent,
  template,
  bindings: {
    idCuenta: '<',
    data: '<',
  },
})
