import module from './module.js'
/* Components */
import './components/component.js'
import './components/bancos/component.js'
import './components/conceptos/component.js'
import './components/conceptos/agregar/component.js'
import './components/conceptos/colores/component.js'
import './components/conceptos/deshabilitar/component.js'
import './components/conceptos/editar/component.js'
import './components/conceptos/habilitar/component.js'
import './components/categoriascuentas/component.js'
import './components/clasificacionmovimientos/component.js'
import './components/firmantes/component.js'
import './components/formaspagos/component.js'
import './components/monedas/component.js'
/* Services */
import './services/Configuracion.js'
import './services/Configuracion/Clasificacionmovimientos.js'
import './services/Configuracion/Clasificacionmovimientos/Orden.js'
import './services/Configuracion/Clasificacionmovimientos/Regla.js'
/* Routes */
import './routes/configuracion.js'
import './routes/configuracion/bancos.js'
import './routes/configuracion/categoriascuentas.js'
import './routes/configuracion/clasificacionmovimientos.js'
import './routes/configuracion/conceptos.js'
import './routes/configuracion/firmantes.js'
import './routes/configuracion/formaspagos.js'
import './routes/configuracion/monedas.js'

export default module.name
