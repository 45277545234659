import module from '@finances/importarmovimientos/module'
import template from './template.html'
import { isDate } from 'angular'

const NATURALEZAS = { 0: 'A', 1: 'C' }
const MONEDAS = { MXP: 1, USD: 2 }

export class FinancesImportarmovimientosOrigenBancomerComponent {
  constructor($q, $window, $fileSystem, FinancesImportarmovimientosCuentasService) {
    this.$q = $q
    this.$window = $window
    this.$fileSystem = $fileSystem
    this.FinancesImportarmovimientosCuentasService = FinancesImportarmovimientosCuentasService
    this.archivo = null
    this.data = null
    this.error = null
    this.procesando = false
  }

  cerrar() {
    this.onClose()
  }

  seleccionar() {
    this.$fileSystem
      .select(false, '.exp')
      .then((files) => {
        this.error = null
        this.archivo = files[0]

        return this.leer(this.archivo)
          .then((string) => this.decodificar(string))
          .then((movimientos) => this.validar(movimientos))
      })
      .then((movimientos) => {
        const data = {
          origen: 'Archivo ' + this.archivo.name,
          movimientos,
        }

        this.onClose({ $event: { data } })
      })
      .catch((error) => (this.error = error))
  }

  leer(archivo) {
    return this.$q((resolve, reject) => {
      if (!archivo.name.endsWith('.exp')) {
        reject('Tipo de archivo incorrecto')
      } else {
        const reader = new this.$window.FileReader()

        reader.onload = (e) => resolve(e.target.result)
        reader.readAsText(archivo)
      }
    })
  }

  decodificar(archivo) {
    const movimientos = archivo
      .split('\n')
      .map((fila, index) => {
        if (fila.length !== 163) return null

        const fecha = new Date(fila.substr(18, 10).replace('-', '/'))
        const moneda = MONEDAS[fila.substr(81, 3)]
        const importe = parseFloat(fila.substr(66, 15))
        const naturaleza = NATURALEZAS[fila.substr(64, 1)]
        const numeroCuenta = fila.substr(9, 9).trim()
        const folio = fila.substr(28, 6).trim()
        const referencia = fila.substr(34, 30).trim()
        const notas = fila.substr(93, 30).trim()
        const codBBVA = fila.substr(152, 3).trim()

        return {
          registro: `Registro #${index + 1} Bancomer ${numeroCuenta}, ${referencia}`,
          cuenta: 'No especificada',
          empresa: 'No especificada',
          error: null,
          fecha,
          id_tipo_movimiento: undefined,
          id_forma_pago: '01',
          id_moneda: moneda,
          importe,
          naturaleza,
          notas: `${referencia}${notas ? ' / ' + notas : ''}${folio !== '000000' ? ' / Folio ' + folio : ''}${
            codBBVA ? ' / COD BBVA ' + codBBVA : ''
          }`,
          numero_cuenta: numeroCuenta,
          referencia: '',
        }
      })
      .filter((item) => item && item.naturaleza)

    if (!movimientos.length) {
      throw new Error('No se encontraron registros en el archivo seleccionado.')
    }

    return movimientos
  }

  obtenerCuentas(movimientos) {
    const numerosCuentas = movimientos.reduce((carry, next) => {
      if (carry.indexOf(next.numero_cuenta) < 0) carry.push(next.numero_cuenta)

      return carry
    }, [])

    return this.FinancesImportarmovimientosCuentasService.get(numerosCuentas).then((response) => {
      return response.data.reduce((carry, next) => {
        carry[next.numero_cuenta.padStart(18, '0')] = next

        return carry
      }, {})
    })
  }

  validar(movimientos) {
    return this.obtenerCuentas(movimientos).then((cuentas) => {
      return movimientos.map((movimiento) => {
        const cuenta = cuentas[movimiento.numero_cuenta.padStart(18, '0')]

        if (cuenta) Object.assign(movimiento, cuenta)

        if (!cuenta) {
          movimiento.error = `No se encontró la cuenta del registro (${movimiento.numero_cuenta})`
        } else if (!isDate(movimiento.fecha)) {
          movimiento.error = 'La fecha del registro no es válida'
        } else if (movimiento.id_moneda !== cuenta.id_moneda) {
          movimiento.error = 'La moneda del registro no coincide con la de la cuenta'
        } else if (!(movimiento.importe > 0)) {
          movimiento.error = 'El importe del registro no es válido'
        }

        return movimiento
      })
    })
  }

  onClose() {}
}

module.component('financesImportarmovimientosOrigenBancomer', {
  controller: FinancesImportarmovimientosOrigenBancomerComponent,
  template,
  bindings: {
    onClose: '&',
  },
})
