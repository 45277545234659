import angular from 'angular'
import template from './template.html'

export class FinancesEmpresaCuentaDocumentoEliminarComponent {
  constructor(FinancesEmpresaCuentaDocumentoService) {
    this.documentoService = FinancesEmpresaCuentaDocumentoService
    this.eliminando = false
  }

  eliminar() {
    if (this.eliminando) {
      return
    }

    this.eliminando = true

    this.documentoService
      .delete(this.documento.id_empresa, this.documento.id_cuenta, this.documento.id)
      .then(() => this.cerrar(this.documento))
      .finally(() => (this.eliminando = false))
  }

  cerrar(data) {
    this.modal.close(data)
  }
}

angular.module('finances.empresa').component('financesEmpresaCuentaDocumentoEliminar', {
  controller: FinancesEmpresaCuentaDocumentoEliminarComponent,
  template,
  bindings: {
    documento: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
