import module from '@admin/usuario/module'

export class FinancesEmpresaCuentaPrestamoDocumentosService {
  constructor($http) {
    this.$http = $http
  }

  post(idEmpresa, idCuenta, idPrestamo, data) {
    return this.$http.post(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/prestamo/${idPrestamo}/documentos`, data)
  }
}

module.service('FinancesEmpresaCuentaPrestamoDocumentosService', FinancesEmpresaCuentaPrestamoDocumentosService)
