import module from '@finances/configuracion/module'

module.config(($stateProvider) => {
  $stateProvider.state('configuracion.firmantes', {
    url: '/firmantes',
    data: { title: 'Firmantes' },
    views: {
      '@': 'financesConfiguracionFirmantes',
    },
  })
})
