import module from '@finances/divisas/module'
import template from './template.html'

export class FinancesDivisasComponent {
  constructor($filter, $location) {
    this.$filterDate = $filter('date')
    this.$location = $location
  }

  $onChanges(changes) {
    if (changes.fecha) {
      if (!this.fecha) this.fecha = new Date()

      this.fecha = new Date(this.fecha.getFullYear(), this.fecha.getMonth(), 1)
    }
  }

  fechaAnterior() {
    this.fecha = new Date(this.fecha.getFullYear(), this.fecha.getMonth() - 1, 1)

    this.fechaActualizar()
  }

  fechaPosterior() {
    this.fecha = new Date(this.fecha.getFullYear(), this.fecha.getMonth() + 1, 1)

    this.fechaActualizar()
  }

  fechaActualizar() {
    this.$location.search({ fecha: this.$filterDate(this.fecha, 'yyyy-MM-dd') })
    this.$location.replace()
  }
}

module.component('financesDivisas', {
  template,
  controller: FinancesDivisasComponent,
  bindings: {
    fecha: '<',
  },
})
