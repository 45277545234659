import module from '@finances/configuracion/module'

export const FinancesConfiguracionClasificacionmovimientosSschema = {
  id: 'number',
  orden: 'number',
  sin_uso: 'boolean',
  tests: {
    coincide: 'boolean',
  },
}

export class FinancesConfiguracionClasificacionmovimientosService {
  constructor($http) {
    this.$http = $http
  }

  get() {
    return this.$http.get('api/configuracion/clasificacion_movimientos', {
      schema: FinancesConfiguracionClasificacionmovimientosSschema,
    })
  }

  post(data) {
    return this.$http.post('api/configuracion/clasificacion_movimientos', data, {
      schema: FinancesConfiguracionClasificacionmovimientosSschema,
    })
  }
}

module.service(
  'FinancesConfiguracionClasificacionmovimientosService',
  FinancesConfiguracionClasificacionmovimientosService,
)
