import module from '@finances/partida/module'

export const financesPartidaGastoSchema = {
  factura: 'number',
}

export class FinancesPartidaGastoService {
  constructor($http) {
    this.$http = $http
  }

  get(idEmpresa, idCuenta, idPartida) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/partida/${idPartida}/gasto`, {
      schema: financesPartidaGastoSchema,
    })
  }

  put(idEmpresa, idCuenta, idPartida, data) {
    return this.$http.put(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/partida/${idPartida}/gasto`, data, {
      schema: financesPartidaGastoSchema,
    })
  }
}

module.service('FinancesPartidaGastoService', FinancesPartidaGastoService)
