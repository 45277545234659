import module from '@finances/empresas/module'

export class FinancesPosicionbancariaResumenService {
  constructor($http) {
    this.$http = $http
    this.schema = {}
  }

  get(fechaCorte, { idCategoria = 0, incluirSaldos = true, incluirEstado = true, incluirPartidas = true }) {
    return this.$http.get(`api/posicion_bancaria/${fechaCorte.toJSON().slice(0, 10)}/resumen`, {
      params: {
        id_categoria: idCategoria,
        incluir_saldos: incluirSaldos ? 1 : 0,
        incluir_estado: incluirEstado ? 1 : 0,
        incluir_partidas: incluirPartidas ? 1 : 0,
      },
      download: true,
    })
  }
}

module.service('FinancesPosicionbancariaResumenService', FinancesPosicionbancariaResumenService)
