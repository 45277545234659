import module from '@finances/partidas/module'
import template from './template.html'

export class FinancesPartidasProteccionChequesLayoutsComponent {
  data = []

  constructor(FinancesPartidasProteccionChequesLayoutsService, FinancesPartidasProteccionChequesLayoutService) {
    this.financesPartidasProteccionChequesLayoutsService = FinancesPartidasProteccionChequesLayoutsService
    this.financesPartidasProteccionChequesLayoutService = FinancesPartidasProteccionChequesLayoutService
  }

  $onInit() {
    this.financesPartidasProteccionChequesLayoutsService.get().then(({ data }) => (this.data = data))
  }

  descargar(id) {
    this.financesPartidasProteccionChequesLayoutService.get({ id }).then(({ data }) => data.save())
  }
}

module.component('financesPartidasProteccionChequesLayouts', {
  controller: FinancesPartidasProteccionChequesLayoutsComponent,
  template,
  bindings: {
    id: '<',
  },
})
