import module from '@finances/partida/module'

export const financesPartidaEstatusSchema = {
  fecha: 'date',
}

export class FinancesPartidaEstatusService {
  constructor($http) {
    this.$http = $http
  }

  get(idEmpresa, idCuenta, idPartida) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/partida/${idPartida}/estatus`, {
      schema: financesPartidaEstatusSchema,
    })
  }

  put(idEmpresa, idCuenta, idPartida, data) {
    return this.$http.put(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/partida/${idPartida}/estatus`, data, {
      schema: financesPartidaEstatusSchema,
    })
  }
}

module.service('FinancesPartidaEstatusService', FinancesPartidaEstatusService)
