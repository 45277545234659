import module from '@finances/importarmovimientos/module'
import template from './template.html'

export class FinancesImportarmovimientosInvalidosComponent {
  constructor() {
    this.data = undefined
    this.onClose = undefined
  }

  ignorar() {
    this.onClose()
  }
}

module.component('financesImportarmovimientosInvalidos', {
  controller: FinancesImportarmovimientosInvalidosComponent,
  template,
  bindings: {
    data: '<',
    onClose: '&',
  },
})
