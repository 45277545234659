import module from '@finances/configuracion/module'
import template from './template.html'
import '@finances/configuracion/services/Configuracion/Firmantes'

export class FinancesConfiguracionFirmantesAgregarComponent {
  constructor(FinancesConfiguracionFirmantesService) {
    this.firmantesService = FinancesConfiguracionFirmantesService
    this.modal = undefined
    this.error = null
    this.procesando = false
    this.resultado = null
    this.data = {
      nombre: '',
      apellido_paterno: '',
      apellido_materno: '',
    }
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.firmantesService
      .post(this.data)
      .then((response) => (this.resultado = response.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    this.modal.close(this.resultado)
  }
}

module.component('financesConfiguracionFirmantesAgregar', {
  controller: FinancesConfiguracionFirmantesAgregarComponent,
  template,
  require: {
    modal: 'kdModal',
  },
})
