import angular from 'angular'
import template from './template.html'
import './style.scss'

export class FinancesEmpresaCuentaFinanciamientoTablaComponent {
  $onChanges(changes) {
    if (changes.data || changes.importe) {
      this.redistribuir()
    }

    if (changes.interes) {
      this.recalcularInteres()
    }
  }

  depurar() {
    const total = this.data.reduce(
      (total, next, index, array) => {
        if (index + 1 === array.length) {
          return total
        } else {
          return {
            importe: parseFloat((total.importe + next.importe).toFixed(2)),
            interes: parseFloat((total.interes + next.interes).toFixed(2)),
            capital: parseFloat((total.capital + next.capital).toFixed(2)),
          }
        }
      },
      { importe: 0, interes: 0, capital: 0 },
    )

    const index = this.data.length - 1

    this.data[index].importe = parseFloat((this.importe - total.importe).toFixed(2))
    this.data[index].interes = parseFloat((this.interes - total.interes).toFixed(2))
    this.data[index].capital = parseFloat((this.capital - total.capital).toFixed(2))

    this.totalizar()
  }

  recalcularInteres(item) {
    const items = item ? [item] : this.data
    const porcentaje = this.interes / this.importe

    items.forEach((item) => {
      item.interes = parseFloat((item.importe * porcentaje).toFixed(2))
      item.capital = parseFloat((item.importe + item.interes).toFixed(2))
    })

    this.depurar()
  }

  redistribuir() {
    const importe = parseFloat((this.importe / this.data.length).toFixed(2))
    const interes = parseFloat((this.interes / this.data.length).toFixed(2))
    const capital = parseFloat((this.capital / this.data.length).toFixed(2))

    this.data.forEach((item) => {
      item.importe = importe
      item.interes = interes
      item.capital = capital
    })

    this.depurar()
  }

  totalizar() {
    this.diferencia = 0
    this.total = {
      importe: 0,
      interes: 0,
      capital: 0,
    }

    if (!Array.isArray(this.data)) return

    this.data.forEach((item) => {
      this.total.importe = parseFloat((this.total.importe + item.importe).toFixed(2))
      this.total.interes = parseFloat((this.total.interes + item.interes).toFixed(2))
      this.total.capital = parseFloat((this.total.capital + item.capital).toFixed(2))
    })

    this.diferencia = Math.abs(this.total.capital - this.capital) !== 0
  }
}

angular.module('finances.empresa').component('financesEmpresaCuentaFinanciamientoTabla', {
  controller: FinancesEmpresaCuentaFinanciamientoTablaComponent,
  template,
  bindings: {
    data: '<',
    fecha: '<',
    importe: '<',
    interes: '<',
    capital: '<',
  },
})
