import angular from 'angular'
import template from './template.html'

class FinancesEmpresaCuentaDocumentosAgregarComponent {
  constructor($fileSystem, FinancesEmpresaCuentaDocumentosService) {
    this.agregando = false
    this.data = null
    this.$fileSystem = $fileSystem
    this.documentoService = FinancesEmpresaCuentaDocumentosService
  }

  $onInit() {
    this.documentos = [{ id_tipo_documento: 5, descripcion: 'Contrato bancario' }]
  }

  agregar() {
    if (this.agregando) {
      return
    }

    this.agregando = true

    this.documentoService
      .post(this.idEmpresa, this.idCuenta, this.data)
      .then((response) => this.onClose({ $event: { data: response.data } }))
      .finally(() => (this.agregando = false))
  }

  cerrar() {
    this.onClose()
  }

  seleccionar(idTipoDocumento) {
    this.$fileSystem
      .select(false, 'application/pdf')
      .then((files) => (this.data = { id_tipo_documento: idTipoDocumento, file: files[0] }))
      .then(() => this.agregar())
  }
}

angular.module('finances.empresa').component('financesEmpresaCuentaDocumentosAgregar', {
  controller: FinancesEmpresaCuentaDocumentosAgregarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    onClose: '&',
  },
})
