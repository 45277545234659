import module from '@finances/empresa/module.js'

module.config(($stateProvider) => {
  $stateProvider.state('empresa.cuenta.configuracion', {
    url: '/configuracion',
    data: { title: 'Configuracion' },
    views: {
      '@': 'financesEmpresaCuentaConfiguracion',
    },
  })
})
