import module from '@finances/importarmovimientos/module'
import template from './template.html'

export class FinancesImportarmovimientosHistorialDetalladoComponent {
  constructor(FinancesImportarmovimientosHistorialLoteService) {
    this.capturaUsuario = undefined
    this.capturaFecha = undefined
    this.data = undefined
    this.idLote = undefined
    this.onClose = undefined
    this.loteService = FinancesImportarmovimientosHistorialLoteService
  }

  $onInit() {
    this.promise = this.loteService.get(this.idLote).then((response) => (this.data = response.data))
  }

  cerrar() {
    this.onClose()
  }
}

module.component('financesImportarmovimientosHistorialDetallado', {
  controller: FinancesImportarmovimientosHistorialDetalladoComponent,
  template,
  bindings: {
    idLote: '<',
    titulo: '<',
    subtitulo: '<',
    capturaUsuario: '<',
    capturaFecha: '<',
    onClose: '&',
  },
})
