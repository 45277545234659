import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresaCuentaMovimientoPagoEditarComponent {
  constructor(
    $q,
    FinancesCatalogosEstatusChequeService,
    FinancesCatalogosFormasPagoService,
    FinancesEmpresaCuentaMovimientoPagoService,
  ) {
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idMovimiento = undefined
    this.onChange = undefined
    this.onClose = undefined
    this.$q = $q
    this.FinancesCatalogosEstatusChequeService = FinancesCatalogosEstatusChequeService
    this.FinancesCatalogosFormasPagoService = FinancesCatalogosFormasPagoService
    this.FinancesEmpresaCuentaMovimientoPagoService = FinancesEmpresaCuentaMovimientoPagoService
    this.error = null
    this.procesando = false
  }

  $onInit() {
    this.promise = this.$q.all([
      this.FinancesCatalogosEstatusChequeService.get().then((response) => (this.estatusCheque = response.data)),
      this.FinancesCatalogosFormasPagoService.get().then((response) => (this.formasPago = response.data)),
      this.FinancesEmpresaCuentaMovimientoPagoService.get(this.idEmpresa, this.idCuenta, this.idMovimiento).then(
        (response) => (this.data = response.data),
      ),
    ])
  }

  aceptar() {
    if (this.procesando) return

    this.procesando = true
    this.error = null

    this.FinancesEmpresaCuentaMovimientoPagoService.put(this.idEmpresa, this.idCuenta, this.idMovimiento, this.data)
      .then((response) => this.actualizar(response.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  actualizar(data) {
    this.onChange({ $event: { data } })
    this.cerrar()
  }

  cerrar() {
    this.onClose()
  }

  seleccionarFechaPago() {
    if (this.data.fecha_pago) {
      this.data.beneficiario = this.data.beneficiario || ''
      this.data.referencia = this.data.referencia || ''
    } else {
      this.data.beneficiario = null
      this.data.estatus_cheque = null
      this.data.forma_pago = null
      this.data.referencia = null
    }
  }
}

module.component('financesEmpresaCuentaMovimientoPagoEditar', {
  controller: FinancesEmpresaCuentaMovimientoPagoEditarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idMovimiento: '<',
    onChange: '&',
    onClose: '&',
  },
})
