import module from '@finances/configuracion/module'
import template from './template.html'

export class FinancesConfiguracionClasificacionmovimientosEliminarComponent {
  constructor(FinancesConfiguracionClasificacionmovimientosReglaService) {
    this.id = undefined
    this.modal = undefined
    this.FinancesConfiguracionClasificacionmovimientosReglaService =
      FinancesConfiguracionClasificacionmovimientosReglaService
    this.error = null
    this.procesando = false
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.FinancesConfiguracionClasificacionmovimientosReglaService.delete(this.id)
      .then((response) => this.modal.close(response.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cancelar() {
    this.modal.close()
  }
}

module.component('financesConfiguracionClasificacionmovimientosEliminar', {
  controller: FinancesConfiguracionClasificacionmovimientosEliminarComponent,
  template,
  bindings: {
    id: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
