import module from '@finances/empresa/module'
import template from './template.html'

export class FinancesEmpresaCuentaMovimientoInformacionEditarComponent {
  constructor($q, FinancesCatalogosTiposMovimientoService, FinancesEmpresaCuentaMovimientoInformacionService) {
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idMovimiento = undefined
    this.onChange = undefined
    this.onClose = undefined
    this.$q = $q
    this.FinancesCatalogosTiposMovimientoService = FinancesCatalogosTiposMovimientoService
    this.FinancesEmpresaCuentaMovimientoInformacionService = FinancesEmpresaCuentaMovimientoInformacionService
    this.error = null
    this.procesando = false
    this.form = undefined
    this.validarNotas = {
      noSignoIgual: (value) => value.substr(0, 1) !== '=',
    }
  }

  $onInit() {
    this.promise = this.$q.all([
      this.FinancesEmpresaCuentaMovimientoInformacionService.get(this.idEmpresa, this.idCuenta, this.idMovimiento).then(
        (response) => (this.data = response.data),
      ),
      this.FinancesCatalogosTiposMovimientoService.get().then((response) => (this.tiposMovimiento = response.data)),
    ])
  }

  aceptar() {
    if (this.procesando) return

    this.procesando = true
    this.error = null

    this.FinancesEmpresaCuentaMovimientoInformacionService.put(
      this.idEmpresa,
      this.idCuenta,
      this.idMovimiento,
      this.data,
    )
      .then((response) => this.actualizar(response.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  actualizar(data) {
    this.onChange({ $event: { data } })
    this.cerrar()
  }

  generarFinanciamiento() {
    this.data.financiamiento = this.data.financiado ? [] : null
  }

  actualizarFinanciamiento(data) {
    this.data.financiamiento = data
    this.form.$setDirty()
  }

  cerrar() {
    this.onClose()
  }

  recalcular() {
    if (this.data.importe > 0) {
      this.data.interes = parseFloat((this.data.importe * this.data.interes_porcentaje).toFixed(2))
      this.data.capital = parseFloat((this.data.importe * (this.data.interes_porcentaje + 1)).toFixed(2))
    } else {
      this.data.interes = null
      this.data.capital = null
    }
  }
}

module.component('financesEmpresaCuentaMovimientoInformacionEditar', {
  controller: FinancesEmpresaCuentaMovimientoInformacionEditarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idMovimiento: '<',
    onChange: '&',
    onClose: '&',
  },
})
