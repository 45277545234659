import module from '@finances/partidas/module'

module.config(($stateProvider) => {
  $stateProvider.state('partidas.proteccionCheques.layouts', {
    url: '/layouts',
    data: {
      title: 'Plantilla de protección de cheques',
    },
    views: {
      '@': { component: 'financesPartidasProteccionChequesLayouts' },
    },
  })
})
