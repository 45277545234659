import module from '@finances/divisas/module'

export class FinancesDivisasService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      fecha: 'date',
      tipo_cambio: 'number',
    }
  }

  get(idMoneda, fecha) {
    return this.$http.get(`api/divisas/${idMoneda}`, {
      params: {
        id_moneda: idMoneda,
        fecha_inicial: fecha.toJSON().slice(0, 10),
        fecha_final: fecha.toJSON().slice(0, 10),
      },
      schema: this.schema,
    })
  }
}

module.service('FinancesDivisasService', FinancesDivisasService)
