import module from '@finances/empresa/module'

/**
 * @ngdoc service
 * @name FinancesEmpresaCuentaMovimientosService
 */
export class FinancesEmpresaCuentaMovimientosService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      activo: 'boolean',
      fecha: 'date',
      id_tipo_movimiento: 'number',
      importe: 'number',
      indice: 'number',
      saldo: 'number',
      valor: 'number',
    }
  }

  /**
   * @memberof FinancesEmpresaCuentaMovimientosService
   */
  get(idEmpresa, idCuenta, query) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/movimientos`, {
      params: {
        limite: query && query.limite,
        indice: query && query.indice,
        // TODO: Implementar filtros
      },
      schema: this.schema,
    })
  }

  /**
   * @memberof FinancesEmpresaCuentaMovimientosService
   */
  post(idEmpresa, idCuenta, data) {
    return this.$http.post(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/movimientos`, data, {
      schema: this.schema,
    })
  }
}

module.service('FinancesEmpresaCuentaMovimientosService', FinancesEmpresaCuentaMovimientosService)
