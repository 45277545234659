import module from '@finances/empresa/module'
import template from './template.html'

export class financesEmpresaCuentasAgregarConfirmarComponent {
  constructor($state, FinancesEmpresaCuentasService) {
    this.idEmpresa = undefined
    this.data = undefined
    this.$state = $state
    this.FinancesEmpresaCuentasService = FinancesEmpresaCuentasService
    this.procesando = false
    this.idCuenta = undefined
    this.modal = undefined
  }

  aceptar() {
    this.modal.close(this.idCuenta)
  }

  agregar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.FinancesEmpresaCuentasService.post(this.idEmpresa, this.data)
      .then((response) => (this.idCuenta = response.data.id_cuenta))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    this.modal.close()
  }

  nuevo() {
    this.$state.reload()
  }
}

module.component('financesEmpresaCuentasAgregarConfirmar', {
  controller: financesEmpresaCuentasAgregarConfirmarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    data: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
