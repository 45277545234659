import module from '@finances/configuracion/module'
import template from './template.html'
import './eliminar/component.js'
import './regla/component.js'
import './style.scss'

export class FinancesConfiguracionClasificacionmovimientosComponent {
  constructor(
    $filter,
    FinancesConfiguracionClasificacionmovimientosService,
    FinancesConfiguracionClasificacionmovimientosOrdenService,
  ) {
    this.agregando = null
    this.editando = null
    this.eliminando = null
    this.$filter = $filter
    this.FinancesConfiguracionClasificacionmovimientosService = FinancesConfiguracionClasificacionmovimientosService
    this.FinancesConfiguracionClasificacionmovimientosOrdenService =
      FinancesConfiguracionClasificacionmovimientosOrdenService
    this.financesConfiguracionClasificacionmovimientosEliminar = undefined
    this.sortableConfig = {
      animation: 150,
      handle: '.finances-configuracion-clasificacionmovimientos-item__handle',
      ghostClass: 'finances-configuracion-clasificacionmovimientos-item--ghost',
      chosenClass: 'finances-configuracion-clasificacionmovimientos-item--chosen',
      onEnd: (event) => {
        this.data = event.models
        this.reordenar()
      },
    }
  }

  $onInit() {
    this.promise = this.FinancesConfiguracionClasificacionmovimientosService.get().then(
      (response) => (this.data = response.data),
    )
  }

  actualizar(item, data) {
    Object.assign(item, data)
  }

  agregar(data) {
    if (data) {
      this.data.splice(this.agregando - 1, 0, data)
      this.reordenar()
    }

    this.agregando = null
  }

  eliminar(item) {
    this.eliminando = item
    this.financesConfiguracionClasificacionmovimientosEliminar
      .open()
      .then((eliminado) => {
        if (eliminado) {
          this.data = this.data.filter((item) => item.id !== eliminado.id)
          this.reordenar()
        }
      })
      .finally(() => (this.eliminando = null))
  }

  reordenar() {
    this.data.forEach((item, indice) => (item.orden = indice + 1))

    const data = this.data.map((item) => ({ id: item.id, orden: item.orden }))

    this.FinancesConfiguracionClasificacionmovimientosOrdenService.put(data)
  }
}

module.component('financesConfiguracionClasificacionmovimientos', {
  controller: FinancesConfiguracionClasificacionmovimientosComponent,
  template,
  bindings: {},
})
