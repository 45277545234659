import module from '@finances/partidas/module'

module.config(($stateProvider) => {
  $stateProvider.state('partidas', {
    url: '/partidas?{id_empresa:int}&{id_cuenta}&{estatus}&{fecha_corte:date}',
    component: 'financesPartidas',
    reloadOnSearch: false,
    data: {
      title: 'Partidas',
    },
    resolve: {
      busqueda: ($transition$) => $transition$.params(),
    },
  })
})
