import module from '@finances/empresas/module'

export class FinancesPosicionbancariaSaldosService {
  constructor($http) {
    this.$http = $http
    this.schema = {
      cuentas: {
        id_empresa: 'number',
        id_categoria: 'number',
        id_moneda: 'number',
        saldo: 'number',
        transito: 'number',
        retenido: 'number',
        cancelado: 'number',
      },
      tipo_cambio: {
        valor: 'number',
        fecha: 'date',
      },
      totales: {
        dolares: 'number',
        pesos: 'number',
        cheques_dolares: 'number',
        cheques_pesos: 'number',
        total: 'number',
      },
    }
  }

  get(fechaCorte, { idCategoria = 0 }) {
    return this.$http.get(`api/posicion_bancaria/${fechaCorte.toJSON().slice(0, 10)}/saldos`, {
      params: {
        id_categoria: idCategoria,
      },
      schema: this.schema,
    })
  }
}

module.service('FinancesPosicionbancariaSaldosService', FinancesPosicionbancariaSaldosService)
