import module from './module.js'
/* Routes */
import './routes/importarpartidas.js'
import './routes/importarpartidas/historial.js'
/* Components */
import './components/component.js'
import './components/guardar/component.js'
import './components/historial/component.js'
import './components/historial/detallado/component.js'
import './components/historial/eliminar/component.js'
import './components/historial/lista/component.js'
import './components/historial/lista/item/component.js'
import './components/lista/component.js'
import './components/lista/item/component.js'
import './components/origen/component.js'
import './components/origen/excel/component.js'
/* Services */
import './services/Importarpartidas.js'
import './services/Importarpartidas/Cuentas.js'
import './services/Importarpartidas/Configuracion/Asociacion.js'
import './services/Importarpartidas/Configuracion/Excel.js'
import './services/Importarpartidas/Historial.js'
import './services/Importarpartidas/Historial/Lote.js'

export default module.name
