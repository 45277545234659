import module from '@finances/importarpartidas/module'

export class FinancesImportarpartidasHistorialService {
  constructor($filter, $http) {
    this.$filterDate = $filter('date')
    this.$http = $http
    this.schema = {
      captura_fecha: 'datetime',
      id_lote: 'number',
      movimientos: 'number',
    }
  }

  get(fecha) {
    return this.$http.get('api/importar_partidas/historial', {
      params: {
        fecha: this.$filterDate(fecha, 'yyyy-MM-dd'),
      },
      schema: this.schema,
    })
  }
}

module.service('FinancesImportarpartidasHistorialService', FinancesImportarpartidasHistorialService)
