import module from '@finances/partida/module'
import template from './template.html'

export class FinancesPartidaEliminarConfirmarComponent {
  constructor(FinancesPartidaService) {
    this.completado = false
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idPartida = undefined
    this.error = null
    this.modal = undefined
    this.FinancesPartidaService = FinancesPartidaService
    this.procesando = false
  }

  eliminar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.FinancesPartidaService.delete(this.idEmpresa, this.idCuenta, this.idPartida)
      .then((response) => {
        this.completado = response.data && response.data.eliminado

        if (!this.completado) this.error = 'No se pudo eliminar la partida'
      })
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    this.modal.close({ eliminado: this.completado })
  }
}

module.component('financesPartidaEliminarConfirmar', {
  controller: FinancesPartidaEliminarConfirmarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPartida: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
