import module from '@finances/posicionbancaria/module'

module.config(($stateProvider) => {
  $stateProvider.state('posicionbancaria.saldos', {
    url: '/saldos',
    reloadOnSearch: false,
    data: {
      title: 'Saldos',
    },
    component: 'financesPosicionbancariaSaldos',
  })
})
