import module from '@finances/empresa/module'

export const FinancesEmpresaCuentaMovimientoInformacionTipoMovimientoSchema = {
  id_tipo_movimiento: 'number',
}

export class FinancesEmpresaCuentaMovimientoInformacionTipoMovimientoService {
  constructor($http) {
    this.$http = $http
  }

  put(idEmpresa, idCuenta, idMovimiento, data) {
    return this.$http.put(
      `api/empresa/${idEmpresa}/cuenta/${idCuenta}/movimiento/${idMovimiento}/informacion/tipo_movimiento`,
      data,
      {
        schema: FinancesEmpresaCuentaMovimientoInformacionTipoMovimientoSchema,
      },
    )
  }
}

module.service(
  'FinancesEmpresaCuentaMovimientoInformacionTipoMovimientoService',
  FinancesEmpresaCuentaMovimientoInformacionTipoMovimientoService,
)
