import module from '@finances/partidas/module'
import template from './template.html'

export class FinancesPartidasAgregarOrigenComponent {
  constructor(FinancesCatalogosEmpresasService, FinancesCatalogosCuentasService) {
    this.data = undefined
    this.FinancesCatalogosEmpresasService = FinancesCatalogosEmpresasService
    this.FinancesCatalogosCuentasService = FinancesCatalogosCuentasService
    this.cuentas = undefined
    this.empresas = undefined
  }

  $onInit() {
    this.FinancesCatalogosEmpresasService.get().then((response) => (this.empresas = response.data))

    this.obtenerCuentas()
  }

  seleccionarEmpresa() {
    this.data.id_cuenta = undefined
    this.data.id_moneda = undefined
    this.obtenerCuentas()
  }

  obtenerCuentas() {
    this.cuentas = undefined

    if (!this.data.id_empresa) return

    this.FinancesCatalogosCuentasService.get(this.data.id_empresa).then((response) => (this.cuentas = response.data))
  }
}

module.component('financesPartidasAgregarOrigen', {
  controller: FinancesPartidasAgregarOrigenComponent,
  template,
  bindings: {
    data: '<',
    onChange: '&',
  },
})
