import module from '@finances/configuracion/module'
import template from './template.html'
import '@finances/configuracion/services/Configuracion/Firmantes/Firmante'

export class FinancesConfiguracionFirmantesEliminarComponent {
  constructor(FinancesConfiguracionFirmantesFirmanteService) {
    this.firmanteService = FinancesConfiguracionFirmantesFirmanteService
    this.modal = undefined
    this.error = null
    this.completado = false
    this.procesando = false
    this.eliminado = false
    this.firmante = undefined
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.firmanteService
      .delete(this.firmante.id)
      .then((response) => {
        this.eliminado = response.data
        this.completado = true
      })
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cerrar() {
    this.modal.close(this.eliminado)
  }
}

module.component('financesConfiguracionFirmantesEliminar', {
  controller: FinancesConfiguracionFirmantesEliminarComponent,
  template,
  bindings: {
    firmante: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
