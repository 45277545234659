import module from '@finances/clasificarmovimientos/module'
import template from './template.html'
import './style.scss'

export class FinancesClasificarmovimientosListaIndicadorComponent {
  constructor($element) {
    this.$element = $element
    this.pendientes = undefined
    this.total = undefined
  }

  $onChanges() {
    this.$element.attr('estatus', this.pendientes ? 'pendiente' : 'completado')
    this.restante = Math.max(this.pendientes ? 1 : 0, (this.pendientes / this.total) * 100)
    this.texto = this.pendientes ? `${this.pendientes}/${this.total} CLASIFICADOS` : `${this.total} CLASIFICADOS`
  }
}

module.component('financesClasificarmovimientosListaIndicador', {
  controller: FinancesClasificarmovimientosListaIndicadorComponent,
  template,
  bindings: {
    pendientes: '<',
    total: '<',
  },
})
