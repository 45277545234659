import module from '@finances/empresa/module'
import template from './template.html'

class FinancesEmpresaCuentaMovimientosComponent {
  constructor(FinancesEmpresaCuentaMovimientosService, AppTimeService) {
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.parent = undefined
    this.movimientosService = FinancesEmpresaCuentaMovimientosService
    this.appTimeService = AppTimeService
    this.cargando = false
    this.data = []
    this.iniciado = false
    this.seleccionado = null
    this.completado = false
    this.limite = 100
    this.financesEmpresaCuentaMovimientoExportar = undefined
  }

  $onInit() {
    this.idTipoCuenta = this.parent.data.tipo_cuenta.id
    this.cargarSiguientes()
  }

  actualizar(item, data) {
    item.id_tipo_movimiento = data.informacion.id_tipo_movimiento
    item.tipo_movimiento = data.informacion.tipo_movimiento
    item.notas = data.informacion.notas
    item.importe = data.informacion.importe
    item.valor = data.informacion.naturaleza === 'A' ? item.importe : -item.importe

    this.recalcularSaldos(item)
  }

  eliminar(item) {
    item.valor = 0
    item.activo = false
    this.recalcularSaldos(item)
  }

  exportar() {
    this.financesEmpresaCuentaMovimientoExportar.open()
  }

  recalcularSaldos(item) {
    const indice = this.data.indexOf(item) + 1

    let saldo = (this.data[this.data.indexOf(item) + 1] || {}).saldo || 0

    this.data
      .slice(0, indice)
      .reverse()
      .forEach((item) => {
        saldo = item.saldo = parseFloat((saldo + item.valor).toFixed(2))
      })
  }

  seleccionar(item) {
    this.seleccionado = item
  }

  cargarSiguientes() {
    if (this.cargando || this.completado) return

    const query = { limite: this.limite }

    if (this.data.length) query.indice = this.data[this.data.length - 1].indice

    this.cargando = true

    this.movimientosService
      .get(this.idEmpresa, this.idCuenta, query)
      .then((response) => {
        if (response.data.length) {
          const data = response.data.map((item) => {
            item.agrupacion = this.appTimeService.dayMonthGroup(item.fecha).toUpperCase()

            return item
          })

          this.data = this.data.concat(data)
        }

        if (this.data.length === 0 || this.data[this.data.length - 1].indice === 1) {
          this.completado = true
        }
      })
      .finally(() => {
        this.cargando = false
        this.iniciado = true
      })
  }
}

module.component('financesEmpresaCuentaMovimientos', {
  controller: FinancesEmpresaCuentaMovimientosComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
  },
  require: {
    parent: '^financesEmpresaCuenta',
  },
})
