import module from '@finances/clasificarmovimientos/module'

export class FinancesClasificarmovimientosReporteService {
  constructor($http) {
    this.$http = $http
    this.schema = {}
  }

  get({ fechaInicial = new Date(), fechaFinal = new Date(), idCategoria = 0, idMoneda = 0, agruparFecha = true }) {
    return this.$http.get(`api/clasificar_movimientos/reporte`, {
      params: {
        fecha_inicial: fechaInicial.toJSON().slice(0, 10),
        fecha_final: fechaFinal.toJSON().slice(0, 10),
        id_categoria: idCategoria,
        id_moneda: idMoneda,
        agrupar_fecha: (agruparFecha && 1) || 0,
      },
      download: true,
    })
  }
}

module.service('FinancesClasificarmovimientosReporteService', FinancesClasificarmovimientosReporteService)
