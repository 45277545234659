import angular from 'angular'
import template from './template.html'

export class FinancesEmpresaCuentaPrestamo {}

angular.module('finances.empresa').component('financesEmpresaCuentaPrestamo', {
  controller: FinancesEmpresaCuentaPrestamo,
  template,
  bindings: {
    data: '<',
  },
})
