import module from '@finances/divisas/module'

module.config(($stateProvider) => {
  $stateProvider.state('divisas', {
    url: '/divisas?{fecha:date}',
    component: 'financesDivisas',
    resolve: {
      fecha: ($transition$) => $transition$.params().fecha,
    },
    data: { title: 'Divisas' },
  })
})
