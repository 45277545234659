import module from '@finances/importarpartidas/module'
import template from './template.html'

export class FinancesImportarpartidasComponent {
  constructor($window) {
    this.$window = $window
    this.confirmarGuardar = undefined
    this.data = null
    this.idLote = null
  }

  importar(data) {
    this.data = data
  }

  reiniciar() {
    this.data = null
    this.idLote = null
  }

  guardar() {
    this.confirmarGuardar.open().then((data) => {
      if (!data) return

      this.idLote = data.id_lote
      this.data.forEach((item) => (item.id_partida = data.partidas[item.id]))
    })
  }

  uiCanExit() {
    return (
      this.idLote !== null ||
      !Array.isArray(this.data) ||
      !this.data.length ||
      this.$window.confirm('Tiene partidas no guardadas, ¿desea salir de este módulo?')
    )
  }
}

module.component('financesImportarpartidas', {
  controller: FinancesImportarpartidasComponent,
  template,
})
