import module from '@finances/divisas/module'
import template from './template.html'
import './style.scss'

export class FinancesDivisasListaItemComponent {
  constructor() {
    this.data = undefined
    this.onChange = undefined
    this.financesDivisasEditar = undefined
  }

  editar(item) {
    this.editando = item

    this.financesDivisasEditar.open().then((data) => {
      if (data) {
        Object.assign(this.data, data)
      }

      this.onChange({ $event: { data } })
    })
  }
}

module.component('financesDivisasListaItem', {
  template,
  controller: FinancesDivisasListaItemComponent,
  bindings: {
    data: '<',
    onChange: '&',
  },
})
