import module from '@finances/partida/module'
import template from './template.html'

export class FinancesPartidaGastoEditarComponent {
  constructor(
    $q,
    FinancesCatalogosAreasService,
    FinancesCatalogosCategoriasGastoService,
    FinancesCatalogosTiposGastoService,
    FinancesCatalogosSucursalesService,
    FinancesCatalogosPuestosService,
    FinancesPartidaGastoService,
  ) {
    this.$q = $q
    this.FinancesCatalogosAreasService = FinancesCatalogosAreasService
    this.FinancesCatalogosCategoriasGastoService = FinancesCatalogosCategoriasGastoService
    this.FinancesCatalogosTiposGastoService = FinancesCatalogosTiposGastoService
    this.FinancesCatalogosSucursalesService = FinancesCatalogosSucursalesService
    this.FinancesCatalogosPuestosService = FinancesCatalogosPuestosService
    this.FinancesPartidaGastoService = FinancesPartidaGastoService
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.idPartida = undefined
    this.procesando = false
  }

  $onInit() {
    this.promise = this.$q.all([
      this.FinancesCatalogosAreasService.get().then((response) => (this.areas = response.data)),
      this.FinancesCatalogosCategoriasGastoService.get().then((response) => (this.categoriasGasto = response.data)),
      this.FinancesCatalogosTiposGastoService.get().then((response) => (this.tiposGasto = response.data)),
      this.FinancesCatalogosSucursalesService.get().then((response) => (this.sucursales = response.data)),
      this.FinancesCatalogosPuestosService.get().then((response) => (this.puestos = response.data)),
      this.FinancesPartidaGastoService.get(this.idEmpresa, this.idCuenta, this.idPartida).then(
        (response) => (this.data = response.data),
      ),
    ])
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.FinancesPartidaGastoService.put(this.idEmpresa, this.idCuenta, this.idPartida, this.data)
      .then((response) => this.onClose({ $event: { data: response.data } }))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cancelar() {
    this.onClose()
  }

  onClose() {}
}

module.component('financesPartidaGastoEditar', {
  controller: FinancesPartidaGastoEditarComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    idPartida: '<',
    onChange: '&',
    onClose: '&',
  },
})
