import module from '@finances/empresas/module'
import template from './template.html'

export class FinancesPosicionbancariaSaldosEmpresaCuentaComponent {
  constructor(FinancesPosicionbancariaSaldosEmpresaCuentaService) {
    this.cuentaService = FinancesPosicionbancariaSaldosEmpresaCuentaService
    this.idEmpresa = undefined
    this.idCuenta = undefined
    this.fechaCorte = undefined
  }

  $onInit() {
    this.promise = this.cuentaService
      .get(this.fechaCorte, this.idEmpresa, this.idCuenta)
      .then((response) => (this.data = response.data))
  }

  actualizar() {
    const data = {
      transito: this.data.partidas
        .filter((item) => item.estatus.id === 't')
        .reduce((total, item) => total + item.importe, 0),
    }

    this.onChange({ $event: { data } })
  }

  actualizarPartidas(data) {
    if (data) {
      this.data.partidas = data

      this.actualizar()
    }
  }

  cerrar() {
    this.onClose()
  }

  onChange() {}

  onClose() {}
}

module.component('financesPosicionbancariaSaldosEmpresaCuenta', {
  controller: FinancesPosicionbancariaSaldosEmpresaCuentaComponent,
  template,
  bindings: {
    idEmpresa: '<',
    idCuenta: '<',
    fechaCorte: '<',
    descripcion: '<',
    onChange: '&',
    onClose: '&',
  },
})
