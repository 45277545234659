import module from '@finances/empresas/module'
import template from './template.html'

export class FinancesPosicionbancariaSaldosComponent {
  constructor($state, FinancesCatalogosCategoriasCuentaService) {
    this.$state = $state
    this.fechaCorte = undefined
    this.idCategoria = undefined
    this.filtro = ''
    this.catalogos = {}
    this.FinancesCatalogosCategoriasCuentaService = FinancesCatalogosCategoriasCuentaService
  }

  $onInit() {
    this.promise = this.FinancesCatalogosCategoriasCuentaService.get().then((response) => {
      this.catalogos.categoriasCuenta = [{ id: 0, descripcion: 'Todas' }].concat(response.data)
    })
  }

  $onChanges(changes) {
    if (changes.fechaCorte) this.fechaCorte = this.fechaCorte || new Date()
    if (changes.idCategoria) this.idCategoria = this.idCategoria || 0
  }

  buscar() {
    if (!this.fechaCorte) return

    this.data = undefined

    this.$state.go(
      this.$state.current,
      {
        fecha_corte: this.fechaCorte.toJSON().slice(0, 10),
        id_categoria: this.idCategoria,
      },
      { location: 'replace' },
    )
  }
}

module.component('financesPosicionbancariaSaldos', {
  controller: FinancesPosicionbancariaSaldosComponent,
  template,
  bindings: {
    fechaCorte: '<',
    idCategoria: '<',
  },
})
