import module from '@finances/importarmovimientos/module'
import template from './template.html'

export class FinancesImportarmovimientosOrigenComponent {
  constructor(FinancesClasificarmovimientosMatcherService, AppTimeService) {
    this.onChange = undefined
    this.onDelete = undefined
    this.FinancesClasificarmovimientosMatcherService = FinancesClasificarmovimientosMatcherService
    this.appTimeService = AppTimeService
    this.ORIGEN_BANCOMER_EXP = 1
    this.ORIGEN_EXCEL = 2
    this.ORIGEN_CONCORDT_TXT = 3
    this.data = null
    this.origen = null
  }

  borrar() {
    this.origen = null
    this.data = null
    this.onDelete()
  }

  importar(data) {
    if (!data || !Array.isArray(data.movimientos)) {
      this.origen = null

      return
    }

    this.FinancesClasificarmovimientosMatcherService.get().then((matcher) => {
      data.movimientos.forEach((movimiento, indice) => {
        const match = matcher.find(movimiento)

        movimiento.id = indice + 1
        movimiento.id_match = match.id
        movimiento.id_tipo_movimiento = match.data.id_tipo_movimiento
        movimiento.periodo = this.appTimeService.dayMonthGroup(movimiento.fecha)

        // Limpiar notas que comienzan con signo "=" (crea conflicto con Excel)
        if (movimiento.notas.substr(0, 1) === '=') {
          movimiento.notas = movimiento.notas.substr(1)
        }
      })

      this.origen = null
      this.onChange({ $event: { data } })
    })
  }
}

module.component('financesImportarmovimientosOrigen', {
  controller: FinancesImportarmovimientosOrigenComponent,
  template,
  bindings: {
    onChange: '&',
    onDelete: '&',
  },
})
