import module from '@finances/empresas/module'

module.config(($stateProvider) => {
  $stateProvider.state('empresas', {
    url: '/empresas',
    component: 'financesEmpresas',
    data: {
      title: 'Empresas',
    },
  })
})
