import module from '@finances/catalogos/module'

export class FinancesCatalogosTiposGastoService {
  constructor($http) {
    this.$http = $http
    this.schema = {}
  }

  get(params) {
    return this.$http.get('api/catalogos/tipos_gasto', {
      params,
      schema: this.schema,
      cache: true,
    })
  }
}

module.service('FinancesCatalogosTiposGastoService', FinancesCatalogosTiposGastoService)
