import module from '@finances/empresa/module'

export const financesEmpresaCuentaInformacionSchema = {
  firmantes: {
    id: 'number',
  },
}

export class FinancesEmpresaCuentaInformacionService {
  constructor($http) {
    this.$http = $http
  }

  get(idEmpresa, idCuenta) {
    return this.$http.get(`api/empresa/${idEmpresa}/cuenta/${idCuenta}/informacion`, {
      schema: financesEmpresaCuentaInformacionSchema,
    })
  }
}

module.service('FinancesEmpresaCuentaInformacionService', FinancesEmpresaCuentaInformacionService)
