import module from '@finances/configuracion/module'

module.config(($stateProvider) => {
  $stateProvider.state('configuracion.monedas', {
    url: '/monedas',
    data: { title: 'Monedas' },
    views: {
      '@': 'financesConfiguracionMonedas',
    },
  })
})
