import module from '@finances/configuracion/module'
import template from './template.html'
import '@finances/configuracion/services/Configuracion/Conceptos/Concepto'

export class FinancesConfiguracionConceptosDeshabilitarComponent {
  constructor(FinancesConfiguracionConceptosConceptoService) {
    this.conceptoService = FinancesConfiguracionConceptosConceptoService
    this.error = null
    this.procesando = false
    this.data = { activo: false }
  }

  aceptar() {
    if (this.procesando) return

    this.error = null
    this.procesando = true

    this.conceptoService
      .put(this.concepto.id, this.data)
      .then(() => this.modal.close(this.data))
      .catch((response) => (this.error = response.data))
      .finally(() => (this.procesando = false))
  }

  cancelar() {
    if (this.procesando) return

    this.modal.close()
  }
}

module.component('financesConfiguracionConceptosDeshabilitar', {
  controller: FinancesConfiguracionConceptosDeshabilitarComponent,
  template,
  bindings: {
    concepto: '<',
  },
  require: {
    modal: 'kdModal',
  },
})
